/* eslint-disable no-undef */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import Button from "../../components/buttons/button"
import { Grid } from "../../components/draggable"
import { reorder, replace } from "../../utils/ArrayUtils"
import { changeStatsOptions } from "../../../actions/Game"
import { isMobileDevice } from "../../../config/Global"
import Colors from "../../../config/Colors";
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
class ChangeStats extends React.Component {
  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  posList = {
    poslist1: "poslist1",
    poslist2: "poslist2",
    poslist3: "poslist3",
    poslist4: "poslist4",
    poslist5: "poslist5",
    poslist6: "poslist6",

    neglist1: "neglist1",
    neglist2: "neglist2",
    neglist3: "neglist3",
    neglist4: "neglist4",
    neglist5: "neglist5",
    neglist6: "neglist6",
  }

  constructor(props) {
    super(props)
    this.state = {
      data: {
        poslist1: [],
        poslist2: [],
        poslist3: [],
        poslist4: [],
        poslist5: [],
        poslist6: [],
        neglist1: [],
        neglist2: [],
        neglist3: [],
        neglist4: [],
        neglist5: [],
        neglist6: [],
      },
    }
    this.onPositiveDragEnd = this.onPositiveDragEnd.bind(this)
    this.onNegativeDragEnd = this.onNegativeDragEnd.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
    this.onClickRestore = this.onClickRestore.bind(this)
  }

  componentDidMount() {
    const { game } = this.props
    this.setState({
      data: game.gameStatsOptions
    })
  }

  componentWillUnmount() {
    if (isMobileDevice())
      this.revertScreen()
  }

  revertScreen = () => {
    var el = document.documentElement;

    // Supports most browsers and their versions.
    var requestMethod = el.exitFullscreen || el.webkitExitFullscreen || el.mozCancelFullScreen || el.msExitFullscreen;

    if (requestMethod) {

      // Native full screen.
      requestMethod.call(el);

    }

    window.screen.orientation.unlock();
  }

  onClickSave() {
    const { history, changeStatsOptions } = this.props
    const { data } = this.state
    changeStatsOptions(data)
    // this.revertScreen()
    history.goBack()
  }

  onClickRestore = () => {
    const { game } = this.props
    this.setState({
      data: game.originStatsOptions
    })
  }

  getList = (id) => {
    const { data } = this.state
    return data[this.posList[id]]
  }

  onPositiveDragEnd = (res) => {
    const { data } = this.state
    const { source, destination } = res

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      )

      if (source.droppableId === "poslist1") {
        this.setState({
          data: { ...data, poslist1: items },
        })
      } else if (source.droppableId === "poslist2") {
        this.setState({
          data: { ...data, poslist2: items },
        })
      } else if (source.droppableId === "poslist3") {
        this.setState({
          data: { ...data, poslist3: items },
        })
      } else if (source.droppableId === "poslist4") {
        this.setState({
          data: { ...data, poslist4: items },
        })
      } else if (source.droppableId === "poslist5") {
        this.setState({
          data: { ...data, poslist5: items },
        })
      } else if (source.droppableId === "poslist6") {
        this.setState({
          data: { ...data, poslist6: items },
        })
      }
    } else {
      const result = replace(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      )

      if (source.droppableId === "poslist4") {
        if (destination.droppableId === "poslist1") {
          this.setState({
            data: {
              ...data,
              poslist4: result.poslist4,
              poslist1: result.poslist1,
            },
          })
        } else if (destination.droppableId === "poslist2") {
          this.setState({
            data: {
              ...data,
              poslist4: result.poslist4,
              poslist2: result.poslist2,
            },
          })
        } else if (destination.droppableId === "poslist3") {
          this.setState({
            data: {
              ...data,
              poslist4: result.poslist4,
              poslist3: result.poslist3,
            },
          })
        }
      } else if (source.droppableId === "poslist5") {
        if (destination.droppableId === "poslist1") {
          this.setState({
            data: {
              ...data,
              poslist5: result.poslist5,
              poslist1: result.poslist1,
            },
          })
        } else if (destination.droppableId === "poslist2") {
          this.setState({
            data: {
              ...data,
              poslist5: result.poslist5,
              poslist2: result.poslist2,
            },
          })
        } else if (destination.droppableId === "poslist3") {
          this.setState({
            data: {
              ...data,
              poslist5: result.poslist5,
              poslist3: result.poslist3,
            },
          })
        }
      } else if (source.droppableId === "poslist6") {
        if (destination.droppableId === "poslist1") {
          this.setState({
            data: {
              ...data,
              poslist6: result.poslist6,
              poslist1: result.poslist1,
            },
          })
        } else if (destination.droppableId === "poslist2") {
          this.setState({
            data: {
              ...data,
              poslist6: result.poslist6,
              poslist2: result.poslist2,
            },
          })
        } else if (destination.droppableId === "poslist3") {
          this.setState({
            data: {
              ...data,
              poslist6: result.poslist6,
              poslist3: result.poslist3,
            },
          })
        }
      }
    }
  }

  onNegativeDragEnd = (res) => {
    const { data } = this.state
    const { source, destination } = res

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      )

      if (source.droppableId === "neglist1") {
        this.setState({
          data: { ...data, neglist1: items },
        })
      } else if (source.droppableId === "neglist2") {
        this.setState({
          data: { ...data, neglist2: items },
        })
      } else if (source.droppableId === "neglist3") {
        this.setState({
          data: { ...data, neglist3: items },
        })
      } else if (source.droppableId === "neglist4") {
        this.setState({
          data: { ...data, neglist4: items },
        })
      } else if (source.droppableId === "neglist5") {
        this.setState({
          data: { ...data, neglist5: items },
        })
      } else if (source.droppableId === "neglist6") {
        this.setState({
          data: { ...data, neglist6: items },
        })
      }
    } else {
      const result = replace(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      )

      if (source.droppableId === "neglist4") {
        if (destination.droppableId === "neglist1") {
          this.setState({
            data: {
              ...data,
              neglist4: result.neglist4,
              neglist1: result.neglist1,
            },
          })
        } else if (destination.droppableId === "neglist2") {
          this.setState({
            data: {
              ...data,
              neglist4: result.neglist4,
              neglist2: result.neglist2,
            },
          })
        } else if (destination.droppableId === "neglist3") {
          this.setState({
            data: {
              ...data,
              neglist4: result.neglist4,
              neglist3: result.neglist3,
            },
          })
        }
      } else if (source.droppableId === "neglist5") {
        if (destination.droppableId === "neglist1") {
          this.setState({
            data: {
              ...data,
              neglist5: result.neglist5,
              neglist1: result.neglist1,
            },
          })
        } else if (destination.droppableId === "neglist2") {
          this.setState({
            data: {
              ...data,
              neglist5: result.neglist5,
              neglist2: result.neglist2,
            },
          })
        } else if (destination.droppableId === "neglist3") {
          this.setState({
            data: {
              ...data,
              neglist5: result.neglist5,
              neglist3: result.neglist3,
            },
          })
        }
      } else if (source.droppableId === "neglist6") {
        if (destination.droppableId === "neglist1") {
          this.setState({
            data: {
              ...data,
              neglist6: result.neglist6,
              neglist1: result.neglist1,
            },
          })
        } else if (destination.droppableId === "neglist2") {
          this.setState({
            data: {
              ...data,
              neglist6: result.neglist6,
              neglist2: result.neglist2,
            },
          })
        } else if (destination.droppableId === "neglist3") {
          this.setState({
            data: {
              ...data,
              neglist6: result.neglist6,
              neglist3: result.neglist3,
            },
          })
        }
      }
    }
  }

  render() {
    const { data } = this.state
    const { onClickSave } = this.props
    
    return (
      <div className="w-full" id="container">
        <NavHeader/>
        <div className="max-w-xl mx-auto flex pt-24 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xl">Change My Stats</span>
        </div>
        <div className="max-w-xl mx-auto flex p-2 bg-green rounded-lg text-center">
          <span className="w-full italic">
            Drag and drop the below buttons to replace them above
          </span>
        </div>
        <div className="max-w-6xl mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <Grid
            state={data}
            onPositiveDragEnd={this.onPositiveDragEnd}
            onNegativeDragEnd={this.onNegativeDragEnd}
          />
        </div>

        <div className="max-w-2xl mx-auto  flex p-2 mt-4 pb-24 mb-4 bg-green rounded-lg justify-center">
          <span className="bg-purple">
          <Button
            label="Restore Stats"
            width="100%"
            backgroundColor={Colors.white}
            margin="0rem"
            onClick={this.onClickRestore}
          />
          </span>
          <span className="mx-8" />
          <span className="bg-pink">
          <Button
            label="Save & Exit"
            width="100%"
            backgroundColor={Colors.white}
            margin="0rem"
            onClick={onClickSave ? () => {
              const { changeStatsOptions } = this.props
              const { data } = this.state
              changeStatsOptions(data)
              onClickSave()
            } : this.onClickSave}
          />
          </span>
        </div>
        {/* <NavFooter/> */}
      </div>
    )
  }
}

ChangeStats.defaultProps = {
  onClickSave: null
}

ChangeStats.propTypes = {
  history: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
  game: PropTypes.instanceOf(Object).isRequired,
  changeStatsOptions: PropTypes.func.isRequired,
  onClickSave: PropTypes.func
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    changeStatsOptions
  }
)(ChangeStats)