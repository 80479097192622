const styles = {
    container: {
      width: "100%",
      paddingTop: "5vh",
      minHeight: "100vh",
      height: "auto !important",
    },
    action: {
      width: "30px",
      height: "30px",
      marginLeft: "calc(50% - 15px)",
    },
  }
  
  export default styles
  