import React, { Component } from "react"
import { Draggable } from "react-beautiful-dnd"
import PropTypes from 'prop-types'

import NaturalDragAnimation from "./animation"

const grid = 4

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,
  height: "2rem",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#edf2f7",

  // styles we need to apply on draggables
  ...draggableStyle,
})

class Item extends Component {
  render() {
    const { item, key, index, droppableId, ...props } = this.props
    return (
      <Draggable key={key} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <NaturalDragAnimation
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            snapshot={snapshot}
            {...props}
          >
            {(style) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={style}
                className="flex"
              >
                <div className={`${droppableId === "bench" ? 'w-full' : 'w-4/5'} flex justify-between items-center`}>
                  <span className="text-xs sm:text-sm md:text-base lg:text-base xl:text-base truncate">{item.name}</span>
                  <span className="text-xs sm:text-sm md:text-base lg:text-base xl:text-base">{item.jersey ? `#${item.jersey}` : ''}</span>
                </div>
                {droppableId !== "bench" &&
                  (<div className="w-1/5 justify-end items-center flex">
                    <span className="text-xs sm:text-sm md:text-base lg:text-base xl:text-base">{item.pos}</span>
                  </div>)
                }
              </div>
            )}
          </NaturalDragAnimation>
        )}
      </Draggable>
    )
  }
}

Item.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  key: PropTypes.string,
  index: PropTypes.number,
  droppableId: PropTypes.string.isRequired
}

export default Item
