import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styles from "./styles"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
import Button from "../../components/buttons/button"
import LabelInput from "../../components/labeledinput/labeledinput"
import DropDown from "../../components/dropdown/dropdown"
import MultiSelect from "../../components/multiselect/multiselect"
import Table from "../../components/tables/table"
import ConfirmDialog from "../../components/dialogs/confirmDialog"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest, changeEditingPlayers, selectTeam } from "../../../actions/User"
import { appBaseUrl, possiblePostions } from "../../../config/Constants"

const teamGenders = ["Male", "Female", "Co-Ed"]

class ViewTeam extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ageOptions: [],
      totalRows: 1,
      teamInfo: props.user.selectedTeamInfo,
      editingIndex: -1,
      modalVisible: false,
      copyModalVisible: false,
      posModalVisible: false,
      currentPosValue: [],
      currentPosName: '',
    }
    this.onClickToMain = this.onClickToMain.bind(this)
    this.requestSuccess = this.requestSuccess.bind(this)
    this.requestFail = this.requestFail.bind(this)
    this.onChangeAddAthlete = this.onChangeAddAthlete.bind(this)
    this.onClickStrike = this.onClickStrike.bind(this)
    this.onClickConfirmTeam = this.onClickConfirmTeam.bind(this)
    this.saveTeamSuccess = this.saveTeamSuccess.bind(this)

    if (window.performance) {
      if (performance.navigation.type === 1) {
        if (props.user.editingPlayers.length !== 0) {
          this.onUpdateTeam()
        }
      }
    }
  }

  componentDidMount = () => {
    this.initData()
    const ageArray = []
    
    for (let i = 0; i < 99; i++) {
      ageArray.push(`${i + 1}`);
    }
    this.setState({
      ageOptions: ageArray
    })
  }

  initData = () => {
    const { user, serviceRequest } = this.props
    this.setState({
      teamInfo: user.selectedTeamInfo && user.selectedTeamInfo
    })
    const body = JSON.stringify({
      teamId: user.selectedTeamInfo ? user.selectedTeamInfo.id : 0
    })
    serviceRequest('athlete/getAthletesByTeamId', 'POST', body, false, this.requestSuccess.bind(this), this.requestFail.bind(this));
  }

  componentWillUnmount() {
    this.onUpdateTeam()
    const { changeEditingPlayers } = this.props;
    changeEditingPlayers([])
  } 

  requestSuccess = (data) => {
    const { changeEditingPlayers } = this.props
    const players = []
    data.map((player) => {
      const positions = JSON.parse(player.preferred_positions)
      
      const prefer_pos = positions && positions.length > 0 ? positions.join(", ") : ''
      const athlete = {
        athleteName: player.last_name ? `${player.first_name} ${player.last_name}` : `${player.first_name}`,
        jerseyNumber: player.jersey_number ? player.jersey_number : '',
        position: prefer_pos ? prefer_pos : '',
        isWarning: false,
        isOwnData: player.athlete_type === 'Provided',
        isStriked: player.hidden,
        id: player.id,
        positionEditing: false
      }
      players.push(athlete);
    })


    const hiddenPlayers = players.filter(e => e.isStriked)
    const unhiddenPlayers = players.filter(e => !e.isStriked)
    unhiddenPlayers.sort((a, b) => a.jerseyNumber - b.jerseyNumber)
    hiddenPlayers.sort((a, b) => a.jerseyNumber - b.jerseyNumber)

    const allPlayers = [
      ...unhiddenPlayers,
      ...hiddenPlayers
    ]
    const athlete1 = {
      athleteName: '',
      jerseyNumber: '',
      position: '',
      isWarning: false,
      isOwnData: false,
      isStriked: false,
      id: 0
    }
    allPlayers.push(athlete1)
    changeEditingPlayers(allPlayers);

    this.setState({
      totalRows: data.length + 1
    }, () => {
      this.checkWarning()
    })
  }

  requestFail = (error) => {
    console.log('+++++++: ', error)
  }

  checkWarning() {
    const { user, changeEditingPlayers } = this.props
    const { editingPlayers } = user
    const array = editingPlayers;
    array.map((item, index) => {
      for (let subindex = 0; subindex < array.length; subindex++) {
        const subitem = array[subindex]
        if (index !== subindex) {
          if (item.athleteName && item.athleteName.toUpperCase() === subitem.athleteName.toUpperCase() || item.jerseyNumber && item.jerseyNumber === subitem.jerseyNumber) {
            item.isWarning = true
            break
          } else {
            item.isWarning = false
          }
        }
      }
    })
    changeEditingPlayers(array);
  }

  onChangeAddAthlete = (e, index) => {
    const { totalRows } = this.state
    const { user } = this.props
    const { editingPlayers } = user
    if (index < 25) {
      const { target } = e
      const { name, value, validity } = target
      if (validity.valid) {
        const editingItem = editingPlayers[index]
        editingItem[name] = value
        if (index === totalRows - 1 && totalRows < 25) {
          if (editingItem.athleteName !== '') {
            const athlete = {
              athleteName: '',
              jerseyNumber: '',
              position: '',
              isWarning: false,
              isOwnData: false,
              isStriked: false,
              id: 0
            }
            editingPlayers.push(athlete)
            this.setState({
              totalRows: totalRows + 1
            })
          }
        } else if (index === totalRows - 2 && editingItem.athleteName === '' && editingItem.jerseyNumber === '' && editingItem.position === '') {
          editingPlayers.pop()
          this.setState({
            totalRows: totalRows - 1
          })
        }
        this.checkWarning()
      }
    }
  }

  onClickStrike = (index) => {
    const { user, changeEditingPlayers } = this.props
    const { editingPlayers } = user
    const tmp = editingPlayers.slice(0, editingPlayers.length - 1)
    const editingItem = tmp[index]
    editingItem.isStriked = !editingItem.isStriked
    const hiddenPlayers = tmp.filter(e => e.isStriked)
    const unhiddenPlayers = tmp.filter(e => !e.isStriked)
    unhiddenPlayers.sort((a, b) => a.jerseyNumber - b.jerseyNumber)
    hiddenPlayers.sort((a, b) => a.jerseyNumber - b.jerseyNumber)

    const allPlayers = [
      ...unhiddenPlayers,
      ...hiddenPlayers
    ]
    const athlete1 = {
      athleteName: '',
      jerseyNumber: '',
      position: '',
      isWarning: false,
      isOwnData: false,
      isStriked: false,
      id: 0
    }
    allPlayers.push(athlete1)
    changeEditingPlayers(allPlayers)
  }

  onChangeText = (event) => {
    const { target } = event
    const { name, value } = target
    const { ageOptions, teamInfo } = this.state
    const { user, selectTeam } = this.props
    let tmpValue = value;
    if (tmpValue === '') {
      if (name === 'team_name') {
        tmpValue = user.selectedTeamInfo.team_name;
      } else if (name === 'season') {
        tmpValue = user.selectedTeamInfo.season;
      }
    }

    if (name === 'team_gender') {
      tmpValue = teamGenders[value]
    } else if (name === 'average_age') {
      tmpValue = ageOptions[value]
    }
    this.setState({
      teamInfo: {
        ...teamInfo,
        [name]: tmpValue
      },
    }, () => {
      const { teamInfo } = this.state
      selectTeam(teamInfo)
    })
  };

  onClickToMain() {
    this.props.history.push("/")
  }

  onClickConfirmTeam() {
    const { teamInfo } = this.state
    this.props.history.push({
      pathname: "team-confirmation",
      state: {
        teamInfo
      }
    })
  }

  saveTeamSuccess = (data) => {
    console.log(data);
    this.initData()
  }

  onUpdateTeam = () => {
    const { serviceRequest, user } = this.props
    const { editingPlayers } = user
    const { teamInfo } = this.state
    const sendingArr = []
    editingPlayers.map((player) => {
      if (player.athleteName !== "") {
        const fullName = player.athleteName.match(/[a-zA-Z0-9]+/g)
        const fName = fullName[0]
        const lName = fullName[1]
        const positions = player.position.match(/[a-zA-Z0-9]+/g)
        const item = {
          id: player.id,
          firstName: fName ? fName.charAt(0).toUpperCase() + fName.slice(1) : "",
          lastName: lName ? lName.charAt(0).toUpperCase() + lName.slice(1) : "",
          jersey: player.jerseyNumber,
          position: positions && positions.length > 0 ? positions.filter(e => possiblePostions.indexOf(e) !== -1) : [],
          isDeleted: player.isStriked
        }
        sendingArr.push(item)
      }
    })
    
    const body = JSON.stringify({
      teamId: teamInfo.id,
      teamName: teamInfo.team_name,
      season: teamInfo.season,
      teamGender: teamInfo.team_gender,
      avgAge: teamInfo.average_age,
      players: sendingArr
    })
    // console.log('=======: ', body)
    serviceRequest("athlete/updateTeamAthletes", "POST", body, false, this.saveTeamSuccess.bind(this))
  }

  onClickCloseModal = () => {
    this.setState({
      modalVisible: false
    })
  }

  onClickOpenMailto = () => {
    const { teamInfo } = this.state
    const { user } = this.props
    const subject = "Stats collection for our team"
    const body = `Hi all, %0D%0A%0D%0A I'll be tracking our team's stats using a new app and need your help to get your name into the app. Basically, go to ${appBaseUrl}add-athlete/${teamInfo.team_url} and fill in the athlete's name, jersey #, and some other info so that I can quickly record data for our team using my app. %0D%0A%0D%0A Any questions, please let me know. %0D%0A%0D%0A ${user.userInfo.first_name} ${user.userInfo.last_name} %0D%0A%0D%0A`
    // eslint-disable-next-line no-undef
    window.open(`mailto:?subject=${subject}&body=${body}`)
  }

  onShowDialog = (name, value, index) => {
    const {
      posModalVisible,
      editingIndex,
    } = this.state;
    if (!posModalVisible) {
      const currentPosValue = value ? value.split(', ') : [];
      this.setState({
        posModalVisible: true,
        editingIndex: index,
        currentPosValue
      });
    } else {
      if (name !== 'cancel') {
        const { user, changeEditingPlayers } = this.props
        const { editingPlayers } = user
        const tmp = editingPlayers;
        tmp[editingIndex].position = value;
        changeEditingPlayers(tmp);
      }
      this.setState({
        posModalVisible: false,
      });
    }
  };

  render() {
    const {teamInfo, modalVisible, copyModalVisible, posModalVisible, currentPosValue, ageOptions } = this.state
    const { user } = this.props
    const { editingPlayers } = user
    if (!user.regToken) {
      return <Redirect to="/login" />
    }
    if (user.loading) {
      return <Spinner show={user.loading} />
    }
    return (
      <div style={styles.container}>
        <NavHeader/>
        <div className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2 pt-8 bg-green rounded-lg">
          <LabelInput
            parent="w-1/2 px-3 mb-6 md:mb-0"
            label="Team Name"
            type="text"
            name="team_name"
            placeholder=""
            value={teamInfo ? teamInfo.team_name : ""}
            onChangeText={this.onChangeText}
          />
          <LabelInput
            parent="w-1/2 px-3"
            label="Season"
            type="text"
            name="season"
            placeholder=""
            value={teamInfo ? teamInfo.season : ""}
            onChangeText={this.onChangeText}
          />
        </div>

        <div className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex flex-col p-2 bg-green rounded-lg">
          <DropDown
            label="Team Gender"
            className="w-full"
            options={teamGenders}
            name="team_gender"
            placeholder="Team Gender"
            selectedIndex={teamInfo ? teamGenders.findIndex(item => item === teamInfo.team_gender) : 0}
            onChange={this.onChangeText}
          />
          <DropDown
            label="Average Age of Athletes"
            className="w-full pt-2"
            options={ageOptions}
            name="average_age"
            placeholder="Average Age"
            selectedIndex={teamInfo ? ageOptions.findIndex(item => item === teamInfo.average_age) : 0}
            onChange={this.onChangeText}
          />
        </div>

        
        <div
          className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2 px-5"
          style={{ marginTop: 20, paddingBottom: 50 }}
        >
          <Table
            headers={["Athlete Name", "#", "Positions", ""]}
            rowData={editingPlayers}
            onChange={this.onChangeAddAthlete}
            onClickStrike={this.onClickStrike}
            onShowDialog={this.onShowDialog}
          />
        </div>
        
        <div
          className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg  mx-auto flex p-2 px-5 justify-center "
        >
        <span className="bg-purple">
          <Button
            label="Invite Athletes"
            width="100%"
            backgroundColor="#ffffff"
            margin={"0px"}
            onClick={() => this.setState({modalVisible: true})}
          />
          </span>
          <span className="px-3" />
          <span className="bg-pink">
          <Button
            label="Add Team Managers"
            width="100%"
            backgroundColor="#ffffff"
            margin={"0px"}
            onClick={this.onClickConfirmTeam}
          />
          </span>
        </div>
        <div
          className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2 px-5 pb-24 bg-outline1"
        >
          <Button
            label="Go Home"
            width="80%"
            backgroundColor="#ffffff"
            margin={"40px"}
            onClick={this.onClickToMain}
          />
        </div>
        <ConfirmDialog show={modalVisible} width={"500px"}>
          <div className="block text-center w-full mb-3">
            <span className="font-semibold">
              Please select how you prefer to invite your team:
            </span>
          </div>
          <div className="text-center w-full flex justify-between">
            <div className="w-1/2 px-2 py-3 border border-black cursor-pointer mr-2 rounded-lg justify-center" onClick={this.onClickOpenMailto}>
              <span className="text-md font-semibold">Send a team email to your athletes/parents with an explanation and the private team access link</span>
            </div>
            <div className="w-1/2 px-2 py-3 border border-black cursor-pointer rounded-lg justify-center ml-2" onClick={() => this.setState({copyModalVisible: true, modalVisible: false})}>
              <CopyToClipboard text={teamInfo ? `${appBaseUrl}add-athlete/${teamInfo.team_url}` : ''}
                onCopy={() => this.setState({copied: true})}>
                <span className="text-md font-semibold">Copy your team link and paste into a text message or another team communication spot</span>
              </CopyToClipboard>
            </div>
          </div>
          <div className="w-full flex mt-1">
            <a className="text-xs" href={teamInfo ? `${appBaseUrl}add-athlete/${teamInfo.team_url}` : ''} rel="noopener norefferrer" target="_blank">{teamInfo ? `Team Link: ${appBaseUrl}add-athlete/${teamInfo.team_url}` : ''}
            </a>
          </div>
          <div className="flex mt-6 w-full btn-link">
            <Button
              label="Close"
              width="40%"
              backgroundColor="white"
              margin="0.25rem"
              onClick={this.onClickCloseModal}
            />
          </div>
        </ConfirmDialog>
        <ConfirmDialog show={copyModalVisible} width={"500px"}>
          <div className="block text-center w-full mb-3">
            <span className="font-semibold">
              Team URL has been copied to your clipboard. Please share the link now with your team.
            </span>
          </div>
          <div className="flex mt-6 w-full">
            <Button
              label="Close"
              width="40%"
              backgroundColor="white"
              margin="0.25rem"
              onClick={() => this.setState({copyModalVisible: false, modalVisible: true})}
            />
          </div>
        </ConfirmDialog>
        <MultiSelect
          visible={posModalVisible}
          currentPosValue={currentPosValue}
          closeDialog={this.onShowDialog}
        />
        {/* <NavFooter/> */}
      </div>
    )
  }
}

ViewTeam.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  changeEditingPlayers: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    changeEditingPlayers,
    selectTeam
  }
)(ViewTeam)