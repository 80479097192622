import React from "react"
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types"
import { ToastsStore } from 'react-toasts';
import styles from "./styles"
import { stateObj } from "./model"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest } from "../../../actions/User"
import { fieldEmpty, emailValidation } from '../../../config/Global'
import { ToastMessages } from '../../../config/ToastMessages';

// const plusIcon = require("../../../images/icons/plus.png")
// const cancelIcon = require("../../../images/icons/cancel.png")

class TeamConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      isClickedAnotherAdd: false,
      anotherManagers: [],
      managerNames: [],
      unAcceptedInvites: []
    }
    this.onClickGoHome = this.onClickGoHome.bind(this)
    this.onClickInvite = this.onClickInvite.bind(this)
    this.onClickAddOtherManagers = this.onClickAddOtherManagers.bind(this)
    this.onClickAddRow = this.onClickAddRow.bind(this)
    this.onClickRemoveRow = this.onClickRemoveRow.bind(this)
    this.onChangeText = this.onChangeText.bind(this)

    this.getTeamSuccess = this.getTeamSuccess.bind(this)
    this.sendInviteSuccess = this.sendInviteSuccess.bind(this)
  }

  componentDidMount = async() => {
    const { serviceRequest, user } = this.props
    const body = JSON.stringify({
      senderId: user.userInfo.id,
      teamId: user.selectedTeamInfo.id
    })

    this.onClickAddRow()
    this.onClickAddRow()
    
    await serviceRequest('team/getInvitesWithTeam', 'POST', body, false, this.getTeamSuccess.bind(this));
  }

  getTeamSuccess = (data) => {
    if (!data.error) {
      const managerNames = data.managers.split(", ")
  
      this.setState({
        managerNames,
        unAcceptedInvites: data.invites
      })
    }
  }

  onClickGoHome() {
    this.props.history.push("/")
  }

  sendInviteSuccess = () => {
    // const { history } = this.props
    // if (data.result) {
    //   history.push("view-team")
    // }
    ToastsStore.success(ToastMessages.INVITE_SUCCESS);
  }

  sendInviteFail = () => {
    ToastsStore.warning(ToastMessages.INVITE_FAIL);
  }


  onClickInvite = () => {
    const { serviceRequest, user } = this.props
    const { anotherManagers } = this.state
    const sendingArr = []
    anotherManagers.map((item) => {
      if (!fieldEmpty(item.newName) && !fieldEmpty(item.newEmail) && emailValidation(item.newEmail)) {
        const fullName = item.newName.match(/[a-zA-Z]+/g)
        const fName = fullName[0] ? fullName[0].charAt(0).toUpperCase() + fullName[0].slice(1) : ""
        const lName = fullName[1] ? fullName[1].charAt(0).toUpperCase() + fullName[1].slice(1) : ""
        const manager = {
          fullName: lName !== "" ? `${fName} ${lName}` : fName,
          email: item.newEmail
        }
        sendingArr.push(manager)
      }
    })
    if (sendingArr.length !== 0) {
      const body = JSON.stringify({
        senderId: user.userInfo.id,
        senderEmail: user.email,
        senderName: `${user.userInfo.first_name} ${user.userInfo.last_name}`,
        teamId: user.selectedTeamInfo.id,
        teamName: user.selectedTeamInfo.team_name,
        managers: sendingArr
      })
      
      serviceRequest("team/sendInviteToManagers", "POST", body, false, this.sendInviteSuccess.bind(this), this.sendInviteFail.bind(this))
    }
  }

  onClickAddOtherManagers() {
    const { isClickedAnotherAdd } = this.state
    this.setState({
      isClickedAnotherAdd: !isClickedAnotherAdd,
    })
  }

  onClickAddRow() {
    const { anotherManagers } = this.state
    const tempArray = anotherManagers
    const newItem = {
      newName: '',
      newEmail: '',
    }
    tempArray.push(newItem)
    this.setState({
      anotherManagers: tempArray,
    })
  }

  remove_item(arr, index) {
    if (arr.length === 0) return
    arr.splice(index, 1)
    return arr
  }

  onClickRemoveRow = (index) => {
    const { anotherManagers } = this.state
    this.setState({
      anotherManagers: this.remove_item(anotherManagers, index),
    })
  }

  onChangeText = (e, index) => {
    const { target } = e
    const { name, value } = target
    const { anotherManagers } = this.state
    anotherManagers.map((item, i) => {
      if (i === index) {
        item[`${name}`] = value
      }
    })
    this.setState({
      anotherManagers
    }, () => {
      const {anotherManagers} = this.state
      if (index === anotherManagers.length - 2 && anotherManagers[index].newName !== "" && anotherManagers[index].newEmail !== "") {
        this.onClickAddRow()
      }
    })
  }

  render() {
    const { anotherManagers, isClickedAnotherAdd, managerNames, unAcceptedInvites } = this.state
    const { user } = this.props
    if (!user.regToken) {
      return <Redirect to="/login" />
    }
    let managers = `${user.userInfo.first_name} ${user.userInfo.last_name} [You]`
    managerNames.map((name) => {
      if (name !== `${user.userInfo.first_name} ${user.userInfo.last_name}`) {
        managers = `${managers}, ${name}`
      }
    })
    const { selectedTeamInfo } = user
    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <NavHeader/>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-8 bg-green rounded-lg">
          <table className="text-left w-full border-collapse bg-white">
            <tbody>
              <tr>
                <td className="w-1/2 py-2 px-1 border border-blue-900 text-right">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">Team Name</span>
                </td>
                <td className="w-1/2 py-2 px-1 border border-blue-900">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{selectedTeamInfo.team_name}</span>
                </td>
              </tr>
              <tr>
                <td className="py-2 px-1 border border-blue-900 text-right">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">Team Gender</span>
                </td>
                <td className="py-2 px-1 border border-blue-900">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{selectedTeamInfo.team_gender}</span>
                </td>
              </tr>
              <tr>
                <td className="py-2 px-1 border border-blue-900 text-right">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">Average Age</span>
                </td>
                <td className="py-2 px-1 border border-blue-900">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{selectedTeamInfo.average_age}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2">
          <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">Managers: </span>
          <span className="px-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{`${managers}`}</span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex px-2 mb-3">
          <span className="px-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">* Most teams have 3+ managers (coaches, parents) to assist!</span>
        </div>

        {unAcceptedInvites.length !== 0 && (
          <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex flex-wrap px-2">
            <span className="w-full text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-semibold">Invited (Not Yet Accepted): </span>
            {unAcceptedInvites.map((item, index) => (
              <span key={`key-${index}`} className="w-full px-5 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{`${item.receiver_fullname}`}</span>
            ))}
          </div>
        )}

        <div
          className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg justify-center"
          style={{ marginTop: 20 }}
        >
        <span className="bg-purple">
          <Button
            label="Add Other Team Managers"
            width="100%"
            backgroundColor="#ffffff"
            margin={"20px"}
            onClick={this.onClickAddOtherManagers}
          />
          </span>
          <span className="px-3" />
          <span className="bg-pink">
          <Button
            label="Exit"
            width="100%"
            backgroundColor="#ffffff"
            margin={"20px"}
            onClick={this.onClickGoHome}
          />
          </span>
        </div>

        {isClickedAnotherAdd && (
          <div>
            <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
              <table className="text-left w-full border-collapse border border-black">
                <thead className="bg-grey-500">
                  <tr>
                    <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border-b border-blue-900 border border-r">
                      Manager Name
                    </th>
                    <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border-b border-blue-900 border border-r">
                      Manager Email
                    </th>
                    {/* <th className="py-4 px-1 bg-grey-lightest border-b border-blue-900 w-20">
                      <div className="w-full h-full flex justify-center items-center">
                        <img
                          className="cursor-pointer w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6"
                          src={plusIcon}
                          onClick={this.onClickAddRow}
                        />
                      </div>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {anotherManagers !== 0 && 
                    anotherManagers.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="border border-blue-900">
                            <input
                              name="newName"
                              type="text"
                              value={item.newName}
                              disabled={index === anotherManagers.length - 1}
                              className="w-full capitalize h-8 px-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                              onChange={(e) => this.onChangeText(e, index)}
                            />
                          </td>
                          <td className="border border-blue-900">
                            <input
                              name="newEmail"
                              type="email"
                              value={item.newEmail}
                              disabled={index === anotherManagers.length - 1}
                              className="w-full h-8 px-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                              onChange={(e) => this.onChangeText(e, index)}
                            />
                          </td>
                          {/* <td className="px-1 border border-blue-900 w-20">
                            <div className="w-full h-full flex justify-center items-center">
                              <img
                                className="cursor-pointer w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6"
                                src={cancelIcon}
                                onClick={() => this.onClickRemoveRow(index)}
                              />
                            </div>
                          </td> */}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            <div
              className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg justify-center pb-24 "
            >
              <span className="bg-purple">
              <Button
                label="Invite"
                width="100%"
                backgroundColor="#ffffff"
                onClick={this.onClickInvite}
              />
              </span>
              <span className="px-3" />
             <span className="bg-pink">
             <Button
                label="Exit"
                width="100%"
                backgroundColor="#ffffff"
                onClick={this.onClickGoHome}
              />
             </span>
            </div>
          </div>
        )}
        {/* <NavFooter/> */}
      </div>
    )
  }
}

TeamConfirmation.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps,
  {serviceRequest}
)(TeamConfirmation)