import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import { stateObj } from "./model"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest } from "../../../actions/User"
import { currentGame } from "../../../actions/Game"

class ViewReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      allGames: []
    }
    this.onClickViewGame = this.onClickViewGame.bind(this)
  }

  componentDidMount() {
    const { user, serviceRequest } = this.props
    const body = JSON.stringify({
      userId: user.userInfo.id
    })
    serviceRequest("game/getAllGamesByUserId", "POST", body, false, this.getAllGamesSuccess.bind(this))
  }

  getAllGamesSuccess = (data) => {
    if (!data.error) {
      this.setState({ allGames: data })
    }
  }

  onClickViewGame = (selectedGame) => {
    const { history, currentGame } = this.props
    currentGame({
        game: selectedGame.game,
        mainTeamName: selectedGame.mainTeam.team_name,
        oppTeamName: selectedGame.oppTeam ? selectedGame.oppTeam.team_name : 'Opponent'
    })
    history.push("/view-reports/games")
  }

  render() {
    const { user } = this.props
    const { allGames } = this.state
    if (!user.regToken) {
      return <Redirect to="/login" />
    }
    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            Select the Game or Scrimmage to View
          </span>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <table className="text-left w-full border-collapse border border-black">
            <thead>
              <tr className="text-center">
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border-b border-blue-900">
                  Team
                </th>
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border-b border-blue-900">
                  Date
                </th>
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border-b border-blue-900">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {allGames.map((item, index) => {
                return (
                  <tr key={`row-${index}`}>
                    <td className="py-2 px-1 border border-blue-900">
                      <span className="font-bold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{item.mainTeam ? item.mainTeam.team_name : ""}</span>
                    </td>
                    <td className="py-2 px-1 border border-blue-900 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                      <span>{item.game && item.game.updated_at ? item.game.updated_at.split(' ')[0] : ""}</span>
                    </td>
                    <td className="py-2 px-1 border border-blue-900">
                      <Button
                        label={item.game.is_scrimming ? "View Scrimmage" : "View Game"}
                        width="100%"
                        backgroundColor="#fff"
                        noBorder
                        onClick={() => this.onClickViewGame(item)}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

ViewReports.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  currentGame: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    currentGame
  }
)(ViewReports)
