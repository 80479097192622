/* eslint-disable react/no-unknown-property */
import React from "react"
// import { connect } from "react-redux"
import { Route } from "react-router-dom"
// import PropTypes from "prop-types"
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import MainApp from "../routes/app"
import LoginPage from "../routes/login"
import SubmitDetailsPage from "../routes/submitDetails"
import { CreateTeamPage, AutoSetupTeamPage } from "../routes/createTeam"
import TeamConfirmationPage from "../routes/TeamConfirmation"
import { ViewTeamPage, ViewAllTeamsPage } from "../routes/ViewTeam"
import {
  ViewReportsPage,
  ViewGamesPage,
  ViewAthletePage,
  SendKudosPage,
  ViewKudosPage
} from "../routes/ViewReports"
import { RatePage, FullRatedPage, UnFullRatedPage } from "../routes/RateApp"
import {
  GameStatsPage,
  RecordGamePage,
  RecordScrimmagePage,
  RecordReadyPage,
  RecordActivityPage
} from "../routes/GameStats"
import DelegateSubsPage from "../routes/DelegateSubs"
import ChangeStatsPage from "../routes/ChangeStats"
import AddOtherTeamPage from "../routes/AddTeam"
import AddAthleteInfoPage from "../routes/AddAthleteInfo"
import {PrivacyPage, TermsPage} from "../routes/TermsAndPrivacy"

export default class Root extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <Route exact path="/" component={MainApp} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/submit-details" component={SubmitDetailsPage} />
        <Route exact path="/create-team" component={CreateTeamPage} />
        <Route exact path="/autosetup-team" component={AutoSetupTeamPage} />
        <Route exact path="/team-confirmation" component={TeamConfirmationPage} />
        <Route exact path="/view-team" component={ViewTeamPage} />
        <Route exact path="/view-allteam" component={ViewAllTeamsPage} />
        <Route exact path="/view-reports" component={ViewReportsPage} />
        <Route exact path="/view-reports/games" component={ViewGamesPage} />
        <Route exact path="/view-reports/athlete" component={ViewAthletePage} />
        <Route exact path="/view-reports/send-kudos" component={SendKudosPage} />
        <Route exact path="/rate-app" component={RatePage} />
        <Route exact path="/rate-app/full-rated" component={FullRatedPage} />
        <Route exact path="/rate-app/unfull-rated" component={UnFullRatedPage} />
        <Route exact path="/game-stats" component={GameStatsPage} />
        <Route exact path="/game-stats/record" component={RecordGamePage} />
        <Route
          exact
          path="/game-stats/record-scrimmage"
          component={RecordScrimmagePage}
        />
        <Route exact path="/game-stats/record-ready" component={RecordReadyPage} />
        <Route
          exact
          path="/game-stats/record-activity"
          component={RecordActivityPage}
        />
        <Route exact path="/delegate-subs" component={DelegateSubsPage} />
        <Route exact path="/change-stats" component={ChangeStatsPage} />
        <Route exact path="/add-team" component={AddOtherTeamPage} />
        <Route path="/add-athlete/:id" component={AddAthleteInfoPage} />
        <Route path="/view-kudos/:kudos" component={ViewKudosPage} />
        <Route exact path="/linkedin" component={LinkedInPopUp} />
        <Route exact path="/privacy-policy" component={PrivacyPage} />
        <Route exact path="/terms-of-use" component={TermsPage} />
      </div>
    )
  }
}
