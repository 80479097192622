const formObj = {
  fname: "",
  lname: "",
  phone: "",
  day: "",
  month: "",
  year: "",
  gender: ""
}

export const stateObj = {
  formControls: formObj,
  formErrors: formObj,
  formInvalid: false,
}
