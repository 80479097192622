/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from "prop-types"
import styles from "./styles"
import { stateObj } from "./model"
import Button from "../../components/buttons/button"
import ConfirmDialog from "../../components/dialogs/confirmDialog"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest } from "../../../actions/User"
import { appBaseUrl } from "../../../config/Constants"

class SendKudos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      kudosData: [],
      modalVisible: false,
      gameUrl: ''
    }
    this.goHome = this.goHome.bind(this)
    this.onClickShowModal = this.onClickShowModal.bind(this)
    this.onClickOpenMailto = this.onClickOpenMailto.bind(this)
  }

  componentDidMount() {
    const { game } = this.props
    const { reportGameKudos } = game
    console.log('-----------: ', reportGameKudos)
    if (reportGameKudos) {
      this.getHighlightEvent(reportGameKudos.kudosReports)
      this.setState({
        gameUrl: reportGameKudos.kudosUrl
      })
    }
  }

  getHighlightEvent = (data) => {
    const kudosData = []
    data.map(kudos => {
      const posKudos = kudos.posKudos
      
      const keysSorted = Object.keys(posKudos)
      if (keysSorted.indexOf('Goal') > -1 || keysSorted.indexOf('PK Goal') > -1) {
        const rowData = {
          name: kudos.athleteName,
          event: 'Goal',
          highlights: kudos.posKudos['Goal'] ? kudos.posKudos['Goal'] : 0  + kudos.posKudos['PK Goal'] ? kudos.posKudos['PK Goal'] : 0,
        }
        kudosData.push(rowData)
      } else if (keysSorted.indexOf('Assist') > -1) {
        const rowData = {
          name: kudos.athleteName,
          event: 'Assist',
          highlights: kudos.posKudos['Assist']
        }
        kudosData.push(rowData)
      } else {
        const arrKudos = Object.keys(posKudos).map(function(key) {
          return {
            name: key,
            value: posKudos[key]
          }
        });
        
        arrKudos.sort((a, b) => b.value - a.value)
        const rowData = {
          name: kudos.athleteName,
          event: arrKudos[0].name,
          highlights: arrKudos[0].value
        }
        kudosData.push(rowData)
      }
    })
    this.setState({
      kudosData
    })
    console.log('********: ', kudosData)
  }

  goHome = () => {
    this.props.history.push("/")
  }
  
  onClickShowModal = () => {
    const { modalVisible } = this.state
    this.setState({
      modalVisible: !modalVisible
    })
  }

  onClickCloseModal = () => {
    const { modalVisible } = this.state
    this.setState({
      modalVisible: !modalVisible
    })
  }

  onClickOpenMailto = () => {
    const { gameUrl } = this.setState
    const subject = "Game kudos for our team"
    const body = `Hi all, %0D%0A%0D%0A This is played game kudos. Basically, go to ${appBaseUrl}view-kudos/${gameUrl} and fill some info so that you can access to our game 'kudos' report.`
    // eslint-disable-next-line no-undef
    window.location.href = `mailto:?subject=${subject}&body=${body}`
    this.setState({
      modalVisible: false
    })
  }

  render() {
    const { user } = this.props

    if (!user) {
      return <Redirect to="/login" />
    }

    const { kudosData, modalVisible, gameUrl } = this.state

    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            Congratulate Your Team - They Played Hard!
          </span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-4 bg-green rounded-lg">
          <span className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
            The below 'good stuff only' report is available for your families to see
            how they played in the game. We encourage you to share this link{" "}
            <a className="text-blue-500 cursor-pointer" onClick={this.onClickShowModal}>
              {`${appBaseUrl}view-kudos/${gameUrl}`}
            </a>{" "}
            so that families can get excited about how they positively contributed to
            the team!
          </span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-4 bg-green rounded-lg">
          <table className="text-left w-full border-collapse bg-white">
            <tbody>
              {kudosData.map((item, index) => (
                <tr className="text-center hover:bg-blue-300" key={`index-${index}`}>
                  <td className="py-2 px-2 text-left border border-blue-900">
                    <span className="font-semibold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">{item.name}</span>
                  </td>
                  <td className="py-2 border border-blue-900">
                    <span className="font-semibold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">{`${item.highlights} ${item.event}`}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 py-6 bg-green rounded-lg">
          <Button
            label="Go Home!"
            width="80%"
            backgroundColor="#dee5ea"
            onClick={this.goHome}
          />
        </div>

        <ConfirmDialog show={modalVisible} width={"500px"}>
          <div className="block text-center w-full mb-3">
            <span className="font-semibold">
              Please select how you prefer to invite your team:
            </span>
          </div>
          <div className="text-center w-full flex justify-between">
            <div className="w-1/2 px-2 py-3 border border-black cursor-pointer mr-2 rounded-lg justify-center" onClick={this.onClickOpenMailto}>
              <span className="text-md font-semibold">Send a team email to your athletes/parents with an explanation and the private team access link</span>
            </div>
            <div className="w-1/2 px-2 py-3 border border-black cursor-pointer rounded-lg justify-center ml-2" onClick={this.onClickCloseModal}>
              <CopyToClipboard text={`${appBaseUrl}view-kudos/${gameUrl}`}
                onCopy={() => this.setState({copied: true})}>
                <span className="text-md font-semibold">Copy your team link and paste into a text message or another team communication spot</span>
              </CopyToClipboard>
            </div>
          </div>
          <div className="w-full flex justify-center mt-1">
            <a className="text-xs" href={`${appBaseUrl}view-kudos/${gameUrl}`} rel="noopener norefferrer" target="_blank">
              {`Team Link: ${appBaseUrl}view-kudos/${gameUrl}`}
            </a>
          </div>
          <div className="flex mt-6 w-full">
            <Button
              label="Close"
              width="40%"
              backgroundColor="green"
              noBorder
              margin="0.25rem"
              onClick={this.onClickCloseModal}
            />
          </div>
        </ConfirmDialog>
      </div>
    )
  }
}

SendKudos.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  game: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest
  }
)(SendKudos)