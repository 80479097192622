import React from "react"
// import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import { stateObj } from "./model"
import Button from "../../components/buttons/button"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
const plusIcon = require("../../../images/icons/plus.png")
const cancelIcon = require("../../../images/icons/cancel.png")

export default class GameStats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      managers: ["johndoe@gmail.com", "smith@gmail.com"],
      anotherManagers: [],
    }
    this.onclickInvite = this.onClickInvite.bind(this)
    this.onClickContinue = this.onClickContinue.bind(this)
    this.onClickAddRow = this.onClickAddRow.bind(this)
    this.onClickRemoveRow = this.onClickRemoveRow.bind(this)
  }

  onClickInvite = () => {
    this.props.history.push("/game-stats/record")
  }

  onClickContinue = () => {
    this.props.history.push("/game-stats/record")
  }

  componentDidMount() {
    const { anotherManagers, stateObj } = this.state
    const tempArray = anotherManagers
    const newItem = {
      newName: stateObj.formControls.managerName,
      newEmail: stateObj.formControls.managerEmail,
    }
    tempArray.push(newItem)
    this.setState({
      anotherManagers: tempArray,
    })
  }

  onClickAddRow() {
    const { anotherManagers, stateObj } = this.state
    const tempArray = anotherManagers
    const newItem = {
      newName: stateObj.formControls.managerName,
      newEmail: stateObj.formControls.managerEmail,
    }
    tempArray.push(newItem)
    this.setState({
      anotherManagers: tempArray,
    })
  }

  remove_item(arr, index) {
    if (arr.length === 0) return
    for (let i = 0; i < arr.length; i++) {
      if (i === index) {
        arr.splice(i, 1)
        break
      }
    }
    return arr
  }

  onClickRemoveRow = (index) => {
    const { anotherManagers } = this.state
    this.setState({
      anotherManagers: this.remove_item(anotherManagers, index),
    })
  }

  render() {
    const { managers, anotherManagers } = this.state
    return (
      <div style={styles.container}>
        <NavHeader/>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 pt-8 mb-4 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            Game for Triville A team
          </span>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            <span className="font-bold">Existing Sub Managers: </span>
            {`${managers[0]}, ${managers[1]}`}
          </span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex px-2 mb-6 bg-green rounded-lg">
          <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            These users need to only open the app to begin managing team subs!
          </span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex px-2 bg-green rounded-lg">
          <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            Any additional coach, parent, admin or other you&apos;d like to help manage
            subs?
          </span>
        </div>

        <div>
          <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
            <table className="text-left w-full border-collapse bg-white">
              <thead className="bg-grey-500">
                <tr>
                  <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900">
                    Substitution Manager Name
                  </th>
                  <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900">
                    Email
                  </th>
                  <th className="py-4 px-6 bg-grey-lightest border border-blue-900 w-20">
                    <div className="w-full h-full flex justify-center items-center">
                      <img
                        className="cursor-pointer w-6 h-6 sm:w-6 sm:h-6 xl:w-8 xl:h-8 md:w-8 md:h-8 lg:w-8 lg:h-8"
                        src={plusIcon}
                        onClick={this.onClickAddRow}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {anotherManagers.length !== 0 &&
                  anotherManagers.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="border border-blue-900">
                          <input
                            name={`${item.newName}${index}`}
                            className="w-full h-8 px-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                          />
                        </td>
                        <td className="border border-blue-900">
                          <input
                            name={`${item.newEmail}${index}`}
                            className="w-full h-8 px-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                          />
                        </td>
                        <td className="px-2 border border-blue-900 w-20">
                          <div className="w-full h-full flex justify-center items-center">
                            {index !== 0 && (
                              <img
                                className="cursor-pointer w-6 h-6 sm:w-6 sm:h-6 xl:w-8 xl:h-8 md:w-8 md:h-8 lg:w-8 lg:h-8"
                                src={cancelIcon}
                                onClick={() => this.onClickRemoveRow(index)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
          <div
            className="max-w-2xl mx-auto  flex p-2 mt-4 pb-24 mb-4 bg-green rounded-lg justify-center"
            style={{ marginTop: 40, paddingBottom: 40 }}
          >
          <span className="bg-purple w-full">
            <Button
              label="Invite"
              width="100%"
              backgroundColor="#ffffff"
              margin={"20px"}
              onClick={this.onClickInvite}
            />
            </span>
            <span style={{ width: "10%" }}></span>
            <span className="bg-pink w-full">
            <Button
              label="Continue"
              width="100%"
              backgroundColor="#ffffff"
              margin={"20px"}
              onClick={this.onClickContinue}
            />
            </span>
          </div>
        </div>
        {/* <NavFooter/> */}
      </div>
    )
  }
}

GameStats.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired
}