/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { ToastsStore } from "react-toasts"
import firebase from 'firebase'
import PropTypes from "prop-types"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
import Button from "../../components/buttons/button"
import { List } from "../../components/draggable"
import { reorder, move, replace } from "../../utils/ArrayUtils"
import { saveGame, updateGame } from "../../utils/FirestoreUtils"
import { getCurrentUTCSeconds, calculatePlayedTimeForAthlete } from "../../utils/TimerUtils"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest, selectTeam } from "../../../actions/User"
import { currentGame } from "../../../actions/Game"
import { isMobileDevice } from "../../../config/Global"
import GameInSubs from "./GameInSubs"
import { ToastMessages } from "../../../config/ToastMessages"

const gameDb = firebase.firestore()

class RecordStatsReady extends React.Component {
  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  posList = {
    bench: "bench",
    posGK: "posGK",
    posLB: "posLB",
    posCB: "posCB",
    posRB: "posRB",
    posLM: "posLM",
    posRM: "posRM",
    posCM: "posCM",
    posLF: "posLF",
    posRF: "posRF",
    posCF: "posCF"
  }

  constructor(props) {
    super(props)
    this.state = {
      data: {
        bench: [],
        posGK: [],
        posLB: [],
        posRB: [],
        posCB: [],
        posLM: [],
        posCM: [],
        posRM: [],
        posLF: [],
        posCF: [],
        posRF: []
      },
      oppAthletes: [],
      mainAthletes: [],
      isScrimming: false,
      gameInSubsVisible: false,
      gameTime: 0,
      gameData: undefined,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onClickAddNewPlayer = this.onClickAddNewPlayer.bind(this)

    this.requestMainSuccess = this.requestMainSuccess.bind(this)
    this.requestOppSuccess = this.requestOppSuccess.bind(this)
  }

  componentDidMount = async() => {
    const { game, serviceRequest, playingGame } = this.props
    const { gameTeams } = game

    if (playingGame) {
      const { selectedGame } = game
      this.gameSubscribe(selectedGame.game.id)
    } else {
      let body = JSON.stringify({
        teamId: gameTeams ? gameTeams.mainTeam.id : 0
      })
      
      await serviceRequest('athlete/getAthletesByTeamId', 'POST', body, false, this.requestMainSuccess.bind(this));
  
      if (gameTeams.oppTeam) {
        body = JSON.stringify({
          teamId: gameTeams ? gameTeams.oppTeam.id : 0
        })
        
        await serviceRequest('athlete/getAthletesByTeamId', 'POST', body, false, this.requestOppSuccess.bind(this));
      }
    }
  }

  getGameTime = () => {
    const { gameData } = this.state
    const gameTime = getCurrentUTCSeconds() - gameData.startTime
    return gameTime <= gameData.gameDuration ? gameTime : gameData.gameDuration
  }

  gameSubscribe = (gameId) => {
    this.unsubscribe = gameDb.collection("games").doc(`game-${gameId}`)
      .onSnapshot(doc => {
        if (doc.exists) {
          const gameData = doc.data()
          this.setState({
            data: gameData.mainAthletes,
            gameData,
          })
        }
      }, error => {
        console.log(error)
      })
  }

  requestMainSuccess = (result) => {
    const { data } = this.state
    if (!result.error) {
      const athletes = []
      result.map((player, index) => {
        if (!player.hidden) {
          const positions = player.preferred_positions ? JSON.parse(player.preferred_positions) : []
          const prefer_pos = positions ? positions.join(", ") : ''
          const athlete = {
            athleteId: player.id, 
            name: player.last_name ? `${player.first_name} ${player.last_name}` : player.first_name,
            jersey: player.jersey_number ? player.jersey_number : '',
            pos: prefer_pos,
            id: `item-${index}`,
            joinTime: -1,
            outTime: -1,
            ready: true
          }
          athletes.push(athlete)
        }
      })

      athletes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      
      this.setState({
        data: {
          ...data,
          bench: athletes
        },
        mainAthletes: athletes
      })
    }
  }

  requestOppSuccess = (result) => {
    if (!result.error) {
      const athletes = []
      result.map((player, index) => {
        if (!player.hidden) {
          const positions = player.preferred_positions ? JSON.parse(player.preferred_positions) : []
          const prefer_pos = positions ? positions.join(", ") : ''
          const athlete = {
            athleteId: player.id, 
            name: player.last_name ? `${player.first_name} ${player.last_name}` : player.first_name,
            jersey: player.jersey_number ? player.jersey_number : '',
            pos: prefer_pos,
            id: `item-${index}`,
            joinTime: -1,
            outTime: -1,
            ready: true
          }
          athletes.push(athlete)
        }
      })
      athletes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      this.setState({
        oppAthletes: athletes
      })
    }
  }

  createGameSuccess = async (createdGame) => {
    if (!createdGame.error) {
      const { oppAthletes, data, isScrimming, isSkip, mainAthletes } = this.state
      const { user, history, currentGame } = this.props

      let storingMainAthletes = null
      if (isSkip) {
        storingMainAthletes = {
          bench: mainAthletes,
          posGK: [],
          posLB: [],
          posRB: [],
          posCB: [],
          posLM: [],
          posCM: [],
          posRM: [],
          posLF: [],
          posCF: [],
          posRF: []
        }
      }
      
      const storingGame = {
        gameDuration: 3600,
        startTime: 0,
        pauseTime: 0,
        oppAthletes: oppAthletes,
        mainAthletes: isSkip ? storingMainAthletes : data,
        mainAthleteStats: [],
        oppAthleteStats: [],
        controlUserId: user.userInfo.id,
        isScrimming,
        gameId: createdGame.id,
        isPlaying: false,
        mainGoals: 0,
        oppGoals: 0,
        subsManagers: []
      }
      saveGame(`game-${createdGame.id}`, storingGame)
      currentGame({
        game: createdGame
      })
      if (isMobileDevice())
        this.goFull()
      history.push("record-activity")
    }
    else {
      console.log('Create Game Error: ', createdGame)
    }
  }

  createGameFail = (error) => {
    console.log('Create Game--------: ', error)
    ToastsStore.error(ToastMessages.CREATE_GAME_FAIL)
  }

  goFull = () => {
    var el = document.documentElement;

    // Supports most browsers and their versions.
    var requestMethod = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen;

    if (requestMethod) {

      // Native full screen.
      requestMethod.call(el);

    }

    window.screen.orientation.lock('landscape');
  }

  onClickManageSubs = () => {
    const { gameInSubsVisible } = this.state
    this.setState({
      gameInSubsVisible: !gameInSubsVisible
    })
  }

  onClickSubmit = (is_skip) => {
    this.setState({
      isSkip: is_skip,
    })

    const { game, serviceRequest } = this.props
    const { gameTeams } = game
    
    const body = JSON.stringify({
      mainTeamId: gameTeams.mainTeam.id,
      oppTeamId: gameTeams.oppTeam ? gameTeams.oppTeam.id : '',
      isScrimming: false,
    })

    serviceRequest("game/create", "POST", body, false, this.createGameSuccess.bind(this), this.createGameFail.bind(this))
  }

  onClickAddNewPlayer = () => {
    const { selectTeam, game, history } = this.props
    selectTeam(game.gameTeams.mainTeam)
    history.push("/view-team")
  }

  getList = (id) => {
    const { data } = this.state
    return data[this.posList[id]]
  }

  getPositionedPlayers = () => {
    const { data } = this.state
    return (
      data.posGK.length +
      data.posLB.length +
      data.posCB.length +
      data.posRB.length +
      data.posLM.length +
      data.posCM.length +
      data.posRM.length +
      data.posLF.length +
      data.posCF.length +
      data.posRF.length
    )
  }

  onDragEnd = (res) => {
    const { data, gameData } = this.state
    const { playingGame } = this.props
    const { source, destination } = res

    const gameTime = playingGame ? this.getGameTime() : 0

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      )

      this.setState({
        data: {
          ...data,
          [source.droppableId]: items
        }
      })
    } else {
      let result = null
      const positionedPlayers = this.getPositionedPlayers()

      let outedAthlete = null
      let joinedAthlete = null

      if (source.droppableId === "bench") {
        if (destination.droppableId === "posGK" && data[destination.droppableId].length === 0 && positionedPlayers < 11 ||
          (destination.droppableId === "posLB" ||
            destination.droppableId === "posRB" ||
            destination.droppableId === "posLM" ||
            destination.droppableId === "posRM" ||
            destination.droppableId === "posLF" ||
            destination.droppableId === "posRF"
          ) && data[destination.droppableId].length < 2 && positionedPlayers < 11 ||
          (
            destination.droppableId === "posCB" ||
            destination.droppableId === "posCM" ||
            destination.droppableId === "posCF"
          ) && data[destination.droppableId].length < 3 && positionedPlayers < 11
        ) {
          result = move(
            this.getList(source.droppableId),
            this.getList(destination.droppableId),
            source,
            destination
          )
          joinedAthlete = result[destination.droppableId][destination.index]
          if (!joinedAthlete) {
            joinedAthlete = result[destination.droppableId][destination.index - 1]
          }
          if (joinedAthlete) {
            joinedAthlete.joinTime = gameTime  - calculatePlayedTimeForAthlete(joinedAthlete, gameTime)
            joinedAthlete.outTime = -1
            const joinedPos = destination.droppableId.substring(3, destination.droppableId.length)
            joinedAthlete.pos = joinedPos
          }
        } else {
          if (data[destination.droppableId].length === 0) {
            if (positionedPlayers === 11) {
              return
            }
            result = move(
              this.getList(source.droppableId),
              this.getList(destination.droppableId),
              source,
              destination
            )
            joinedAthlete = result[destination.droppableId][destination.index]
            if (!joinedAthlete) {
              joinedAthlete = result[destination.droppableId][destination.index - 1]
            }
            if (joinedAthlete) {
              joinedAthlete.joinTime = gameTime - calculatePlayedTimeForAthlete(joinedAthlete, gameTime)
              joinedAthlete.outTime = -1
              const joinedPos = destination.droppableId.substring(3, destination.droppableId.length)
              joinedAthlete.pos = joinedPos
            }
          } else {
            result = replace(
              this.getList(source.droppableId),
              this.getList(destination.droppableId),
              source,
              destination
            )
            if (gameTime > 0 && gameData) {
              outedAthlete = result[source.droppableId][source.index]
              if (outedAthlete)
                outedAthlete.outTime = gameTime
            }
            else {
              outedAthlete = result[source.droppableId][source.index]
              if (outedAthlete)
                outedAthlete.joinTime = -1
            }
            joinedAthlete = result[destination.droppableId][destination.index]
            
            if (!joinedAthlete) {
              joinedAthlete = result[destination.droppableId][destination.index - 1]
            }
            if (joinedAthlete) {
              joinedAthlete.joinTime = gameTime - calculatePlayedTimeForAthlete(joinedAthlete, gameTime)
              joinedAthlete.outTime = -1
              const joinedPos = destination.droppableId.substring(3, destination.droppableId.length)
              joinedAthlete.pos = joinedPos
            }
          }
        }
        // dstAthlete = result[destination.droppableId][destination.index]
        // if (!dstAthlete) {
        //   dstAthlete = result[destination.droppableId][destination.index - 1]
        // }
        // dstAthlete.joinTime = 0
        // const joinedPos = destination.droppableId.substring(3, destination.droppableId.length)
        // dstAthlete.pos = joinedPos
      } else {
        if (destination.droppableId === "bench") {
          result = move(
            this.getList(source.droppableId),
            this.getList(destination.droppableId),
            source,
            destination
          )
          if (gameTime > 0 && gameData) {
            outedAthlete = result[destination.droppableId][destination.index]
            if (outedAthlete) {
              outedAthlete.outTime = gameTime
            }
          } else {
            outedAthlete = result[destination.droppableId][destination.index]
            if (outedAthlete) {
              outedAthlete.outTime = -1
              outedAthlete.joinTime = -1
            }
          }
        } else {
          let srcAthlete = null
          let dstAthlete = null
          if (
            destination.droppableId === "posGK" && data.posGK.length === 0 ||
            (
              destination.droppableId === "posLB" ||
              destination.droppableId === "posRB" ||
              destination.droppableId === "posLM" ||
              destination.droppableId === "posRM" ||
              destination.droppableId === "posLF" ||
              destination.droppableId === "posRF"
            ) && data[destination.droppableId].length < 2 ||
            (
              destination.droppableId === "posCB" ||
              destination.droppableId === "posCM" ||
              destination.droppableId === "posCF"
            ) && data[destination.droppableId].length < 3
          ) {
            result = move(
              this.getList(source.droppableId),
              this.getList(destination.droppableId),
              source,
              destination
            )
          } else {
            result = replace(
              this.getList(source.droppableId),
              this.getList(destination.droppableId),
              source,
              destination
            )
            srcAthlete = result[source.droppableId][source.index]
            srcAthlete.pos = source.droppableId.substring(3, source.droppableId.length)
          }
          dstAthlete = result[destination.droppableId][destination.index]
          if (!dstAthlete) {
            dstAthlete = result[destination.droppableId][destination.index - 1]
          }
          dstAthlete.pos = destination.droppableId.substring(3, destination.droppableId.length)
        }
      }

      this.setState({
        data: {
          ...data,
          [source.droppableId]: result[source.droppableId],
          [destination.droppableId]: result[destination.droppableId]
        }
      }, () => {
        const { game } = this.props
        const { data } = this.state
        console.log('+++++++++++++: ', data, gameTime)
        if (playingGame) {
          const { selectedGame } = game
          updateGame(`game-${selectedGame.game.id}`, {mainAthletes: data})
        }
      })
    }
  }

  render() {
    const { data, gameInSubsVisible } = this.state
    const { user, game, playingGame } = this.props
    const { gameTeams } = game

    if (!user.regToken) {
      return <Redirect to="/login" />
    }

    return (
      <div>
        <NavHeader/>
        {gameInSubsVisible ? (
          <GameInSubs
            teamId={gameTeams.mainTeam.id}
            onClickBack={this.onClickManageSubs}
          />
        ) : (
          <div>
            <Spinner show={user.loading} />
            <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mb-2 bg-green rounded-lg">
              <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">Set the Lineup</span>
            </div>
            <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-2 bg-green rounded-lg text-center">
              <span className="w-full italic text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                Note: If you don’t need minutes played, you can skip this screen.
              </span>
            </div>
            
            <div className="max-w-md xl:max-w-3xl lg:max-w-2xl md:max-w-xl sm:max-w-md mx-auto flex p-2 mb-4 bg-green rounded-lg">
              <List state={data} onDragEnd={this.onDragEnd} onClickAddNewPlayer={this.onClickAddNewPlayer}/>
            </div>

            {!playingGame && (
              <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-6 bg-green rounded-lg">
              <span className="bg-purple">
                <Button
                  label="Skip (Stats Only, No Minutes Played)"
                  width="100%"
                  backgroundColor="#fff"
                  margin="0rem"
                  onClick={() => this.onClickSubmit(true)}
                />
                </span>
                <span className="mx-4" />
                <span className="bg-pink">
                <Button
                  label="Submit"
                  width="100%"
                  backgroundColor="#fff"
                  margin="0rem"
                  onClick={() => this.onClickSubmit(false)}
                />
                </span>
              </div>
            )}
            <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex justify-center p-2 mt-2 pb-24 bg-green rounded-lg">
            <span className="bg-purple">
              <Button
                label="Get Help Managing Subs"
                width="100%"
                backgroundColor="#fff"
                margin="1rem"
                onClick={this.onClickManageSubs}
              />
              </span>
              {playingGame && <span className="mx-4" />}
              {playingGame && (
                <span className="bg-pink">
                <Button
                  label="Return to Game"
                  width="100%"
                  backgroundColor="#fff"
                  margin="1rem"
                  onClick={this.props.onGoBack}
                />
                </span>
              )}
            </div>
          </div>
        )}
        {/* <NavFooter/> */}
      </div>
    )
  }
}

RecordStatsReady.defaultProps = {
  playingGame: false,
  onGoBack: null,
}

RecordStatsReady.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  playingGame: PropTypes.bool,
  user: PropTypes.instanceOf(Object).isRequired,
  game: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  currentGame: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired,
  onGoBack: PropTypes.func
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    selectTeam,
    currentGame
  }
)(RecordStatsReady)
