// export const apiBaseUrl = "http://192.168.101.38:8000/api"
export const apiBaseUrl = "https://admin.riserunsports.com/api"
// export const appBaseUrl = "http://localhost:3000/"
export const appBaseUrl = "https://soccerstatstracker.riserunsports.com/"
export const possiblePostions = ["CB", "LB", "RB", "LM", "CM", "RM", "LF", "CF", "RF", "ST", "GK"]

const googleClientId = "405892288966-4sre3m57n2ofjg1ftn80qb5nmce5js3r.apps.googleusercontent.com"
const facebookClientId = "569250840555216"


const LINKEDIN_APP_CLIENT_ID = '86iwezjntavayu'
const LINKEDIN_APP_CLIENT_SECRET = 'qABetM3ENIWWOBZh'
const LINKEDIN_APP_REDIRECT_URI = 'http://localhost:3000/linkedin'

export const ClientIDs = {
    googleClientId,
    facebookClientId,
    LINKEDIN_APP_CLIENT_ID,
	LINKEDIN_APP_REDIRECT_URI,
	LINKEDIN_APP_CLIENT_SECRET
}

export const positiveEvents = [
	"Goal",
	"Assist",
	"PK Goal",
	"Pass Completed",
	"Short Pass",
	"Medium Pass",
	"Long Pass",
	"Side Pass",
	"Aerial Won",
	"Tackle",
	"Clearance",
	"Blocks",
	"Goal Kick",
	"GK Punt",
	"GK Throw",
	"Free Kick",
	"Pass Taken (Interceped)",
	"Corner Kick",
	"GK Save",
	"Throw In",
	"Cross",
	"Dribble"
]

export const negativeEvents = [
	"Shots on Goal Missed",
	"Shots off Goal Missed",
	"PK Missed",
	"Off Sides",
	"Backward Pass",
	"Pass Missed (Intercepted)",
	"Pass Missed (Out of Bounds)",
	"Injury",
	"Red Card",
	"Yellow Card",
	"Own Goal",
	"Breakaway Allowed",
	"Touch"
]

export const posOptions = [
  {id: 0, name: "Left Def", abbr: "LB"},
  {id: 1, name: "Center Def", abbr: "CB"}, 
  {id: 2, name: "Right Def", abbr: "RB"}, 
  {id: 3, name: "Left Mid", abbr: "LM"}, 
  {id: 4, name: "Center Mid", abbr: "CM"}, 
  {id: 5, name: "Right Mid", abbr: "RM"}, 
  {id: 6, name: "Left Fwd", abbr: "LF"}, 
  {id: 7, name: "Center Fwd", abbr: "CF"}, 
  {id: 8, name: "Right Fwd", abbr: "RF"}, 
  // {id: 9, name: "Striker", abbr: "ST"}, 
  {id: 9, name: "Goal Keeper", abbr: "GK"}
]

export const homeStates = [
  {name: 'Alabama', abbr: 'AL'},
  {name: 'Alaska', abbr: 'AK'},
  {name: 'Arizona', abbr: 'AZ'},
  {name: 'Arkansas', abbr: 'AR'},
  {name: 'California', abbr: 'CA'},
  {name: 'Colorado', abbr: 'Co'},
  {name: 'Connecticut', abbr: 'CT'},
  {name: 'Delaware', abbr: 'DE'},
  {name: 'Florida', abbr: 'FL'},
  {name: 'Georgia', abbr: 'GA'},
  {name: 'Hawaii', abbr: 'HI'},
  {name: 'Idaho', abbr: 'ID'},
  {name: 'Illinois', abbr: 'IL'},
  {name: 'Indiana', abbr: 'IN'},
  {name: 'Iowa', abbr: 'IA'},
  {name: 'Kansas', abbr: 'KS'},
  {name: 'Kentucky', abbr: 'KY'},
  {name: 'Louisiana', abbr: 'LA'},
  {name: 'Maine', abbr: 'ME'},
  {name: 'Maryland', abbr: 'MD'},
  {name: 'Massachusetts', abbr: 'MA'},
  {name: 'Michigan', abbr: 'MI'},
  {name: 'Minnesota', abbr: 'MN'},
  {name: 'Mississippi', abbr: 'MS'},
  {name: 'Missouri', abbr: 'MO'},
  {name: 'Montana', abbr: 'MT'},
  {name: 'Nebraska', abbr: 'NE'},
  {name: 'Nevada', abbr: 'NV'},
  {name: 'New Hampshire', abbr: 'NH'},
  {name: 'New Jersey', abbr: 'NJ'},
  {name: 'New Mexico', abbr: 'NM'},
  {name: 'New York', abbr: 'NY'},
  {name: 'North Carolina', abbr: 'NC'},
  {name: 'North Dakota', abbr: 'ND'},
  {name: 'Ohio', abbr: 'OH'},
  {name: 'Oklahoma', abbr: 'OK'},
  {name: 'Oregon', abbr: 'OR'},
  {name: 'Pennsylvania', abbr: 'PA'},
  {name: 'Rhode Island', abbr: 'RI'},
  {name: 'South Carolina', abbr: 'SC'},
  {name: 'South Dakota', abbr: 'SD'},
  {name: 'Tennessee', abbr: 'TN'},
  {name: 'Texas', abbr: 'TX'},
  {name: 'Utah', abbr: 'UT'},
  {name: 'Vermont', abbr: 'VT'},
  {name: 'Virginia', abbr: 'VA'},
  {name: 'Washington', abbr: 'WA'},
  {name: 'West Virginia', abbr: 'WV'},
  {name: 'Wisconsin', abbr: 'WI'},
  {name: 'Wyoming', abbr: 'WY'},
  {name: 'District of Columbia', abbr: 'DC'},
  {name: 'Other', abbr: 'Other'},
];

const API_KEY='AIzaSyAZaVMFfJ-vAfjKb6yxH8I--vxcLH5wJdc'
const AUTH_DOMAIN='riserunstats.firebaseapp.com'
const DATABASE_URL='https://riserunstats.firebaseio.com'
const PROJECT_ID='riserunstats'
const STORAGING_BUCKET='riserunstats.appspot.com'
const MESSAGE_SENDER_ID='451431207347'
const APP_ID='1:451431207347:web:73fd3711e2a4d522b97bc6'
const MEASUREMENT_ID='G-1J2J064Y5J'

export const firebaseConfig = {
	apiKey: API_KEY,
	authDomain: AUTH_DOMAIN,
	databaseURL: DATABASE_URL,
	projectId: PROJECT_ID,
	storageBucket: STORAGING_BUCKET,
	messagingSenderId: MESSAGE_SENDER_ID,
	appId: APP_ID,
	measurementId: MEASUREMENT_ID,
}

export const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=com.riserunsports.soccerstats';
export const appleStoreUrl =
  'https://apps.apple.com/us/app/soccer-statistics-tracker/id1499560124';
