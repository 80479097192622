import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import { stateObj } from "./model"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest } from "../../../actions/User"
import { positiveEvents, negativeEvents } from "../../../config/Constants"
import { formatTime } from "../../utils/TimerUtils"

class ViewAthlete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      teamName: '',
      gameDate: '',
      athlete: null,
      allEvents: [
        ...positiveEvents,
        ...negativeEvents
      ],
      currentStats: [],
      allGameStats: [],
      totalEvents: {
        posEvents: 0,
        negEvents: 0
      },
      allTime: 0,
      viewableEvents: []
    }
    this.goHome = this.goHome.bind(this)
    this.onClickReorderCurrent = this.onClickReorderCurrent.bind(this)
    this.onClickReorderAll = this.onClickReorderAll.bind(this)
  }

  componentDidMount() {
    const { game, serviceRequest } = this.props;
    const { reportAthlete } = game;
    if (reportAthlete) {
      const { athlete, teamName, gameDate, gameId } = reportAthlete

      this.setState({
        teamName,
        gameDate,
        athlete
      })
      const body = JSON.stringify({
        athleteId: athlete.id,
        gameId
      })

      serviceRequest("game/getGameAthleteStatsByAthleteId", "POST", body, false, this.getGameAthleteStatsSuccess.bind(this))
    }
  }

  manageServiceData = (data) => {
    const result = data.reduce(function(hash) {
      return function (r, o) {
        if (!hash[o.action_name]) {
          hash[o.action_name] = []
          r.push(hash[o.action_name])
        }
        hash[o.action_name].push(o)
        return r
      }
    }(Object.create(null)), [])

    return result
  }

  getGameAthleteStatsSuccess = (data) => {
    this.setState({
      currentStats: this.manageServiceData(data.gameStats),
      allGameStats: this.manageServiceData(data.allGameStats),
      allTime: data.allGameTime
    }, () => {
      this.getAllEvents()
    })
  }

  goHome = () => {
    this.props.history.push("/")
  }

  getCurrentCount = (action) => {
    const { currentStats } = this.state
    let counts = 0

    currentStats.map(subArr => {
      if (subArr[0].action_name === action) {
        counts = subArr.length
      }
    })

    return counts
  }

  getAllCount = (action) => {
    const { allGameStats } = this.state
    let counts = 0

    allGameStats.map(subArr => {
      if (subArr[0].action_name === action) {
        counts = subArr.length
      }
    })

    return counts
  }

  getAllEvents = () => {
    const { allGameStats, allEvents, currentStats } = this.state
    const viewableEvents = []
    allEvents.map((item) => {
      const current = this.getCurrentCount(item)
      const all = this.getAllCount(item)
      const isPositive = positiveEvents.indexOf(item) > -1
      if (all !== 0) {
        viewableEvents.push({
          event: item,
          current,
          all,
          isPositive
        })
      }
    })

    let posEvents = 0
    let negEvents = 0

    allGameStats.map(subArr => {
      if (positiveEvents.indexOf(subArr[0].action_name) > -1) {
        posEvents += subArr.length
      } else {
        negEvents += subArr.length
      }
    })

    this.setState({
      totalEvents: {
        posEvents,
        negEvents
      },
      viewableEvents
    })
  }

  onClickReorderCurrent = () => {
    const { viewableEvents } = this.state
    viewableEvents.sort((a, b) => Math.abs(a.current - b.current) * -1)
    this.setState({
      viewableEvents
    })
  }

  onClickReorderAll = () => {
    const { viewableEvents } = this.state
    viewableEvents.sort((a, b) => Math.abs(a.all - b.all) * -1)
    this.setState({
      viewableEvents
    })
  }

  render() {
    const { user } = this.props;
    
    if (!user) {
      return <Redirect to="/login" />
    }

    const { athlete, teamName, gameDate, viewableEvents, totalEvents, allTime } = this.state

    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            {`Report for ${athlete ? athlete.name : ''} (#${athlete ? athlete.jersey : ''}) in ${teamName} team on ${gameDate}`}
          </span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <table className="text-left w-full border-collapse bg-white">
            <thead>
              <tr className="text-center bg-blue-400">
                <th className="py-4 font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900 "></th>
                <th className="py-4 font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900 cursor-pointer" onClick={this.onClickReorderCurrent}>
                  Game Data
                </th>
                <th className="py-4 font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900 cursor-pointer" onClick={this.onClickReorderAll}>
                  All Games
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center bg-blue-400">
                <td className="py-2 px-2 text-left border border-blue-900">
                  <span className="font-bold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">Minutes</span>
                </td>
                <td className="py-2 border border-blue-900 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                  <span>{athlete && athlete.gameTime !== 0 ? formatTime(athlete.gameTime) : 'N/A'}</span>
                </td>
                <td className="py-2 border border-blue-900 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                  <span>{allTime !== 0 ? formatTime(allTime) : 'N/A'}</span>
                </td>
              </tr>
              {viewableEvents.map((item, index) => {
                return (
                  <tr className={`text-center hover:bg-blue-300 ${!item.isPositive && 'text-blue-600'}`} key={`index-${index}`}>
                    <td className="py-2 px-2 text-left border border-blue-900">
                      <span className="font-bold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">{item.event}</span>
                    </td>
                    <td className="py-2 border border-blue-900 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                      <span>{item.current === 0 ? '-' : item.current}</span>
                    </td>
                    <td className="py-2 border border-blue-900 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                      <span>{item.all}</span>
                    </td>
                  </tr>
                )
              })}
              <tr className="text-center hover:bg-blue-300">
                <td className="py-2 px-2 text-left border border-blue-900">
                  <span className="font-bold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">Total Positive Events</span>
                </td>
                <td className="py-2 border border-blue-900 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                  <span>{athlete ? athlete.posEvents : ''}</span>
                </td>
                <td className="py-2 border border-blue-900 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                  <span>{totalEvents.posEvents}</span>
                </td>
              </tr>
              <tr className={`text-center hover:bg-blue-300 text-blue-600`}>
                <td className="py-2 px-2 text-left border border-blue-900">
                  <span className="font-bold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">Total Neut/Neg Events</span>
                </td>
                <td className="py-2 border border-blue-900 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                  <span>{athlete ? athlete.negEvents : ''}</span>
                </td>
                <td className="py-2 border border-blue-900 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                  <span>{totalEvents.negEvents}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 py-6 bg-green rounded-lg">
          <Button
            label="Go Home!"
            width="80%"
            backgroundColor="#dee5ea"
            onClick={this.goHome}
          />
        </div>
      </div>
    )
  }
}

ViewAthlete.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest
  }
)(ViewAthlete)