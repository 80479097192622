/* eslint-disable no-undef */
import React from "react"
import { connect } from "react-redux"
import './login.css'
import Logo from './img/logo.png'
import PropTypes from "prop-types"
import firebase from 'firebase'
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { ToastsStore } from 'react-toasts';
import { stateObj } from "./model"
import styles from "./styles"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { emailValidation, passwordValidation } from '../../../config/Global'
import { userEmail, serviceRequest, registerToken, currentUser } from '../../../actions/User'
import { ClientIDs } from "../../../config/Constants"
import { firebaseConfig, appBaseUrl } from "../../../config/Constants"
import { ToastMessages } from "../../../config/ToastMessages";

let firebaseSetting = firebase.initializeApp(firebaseConfig)

const googleLogo = require("../../../images/social/google.png")
const facebookLogo = require("../../../images/social/facebook.png")
const twitterLogo = require("../../../images/social/twitter.png")
const linkedinLogo = require("../../../images/social/linkedin.png")

const twitterProvider = new firebase.auth.TwitterAuthProvider()
const googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.addScope('email')
const facebookProvider = new firebase.auth.FacebookAuthProvider()
facebookProvider.addScope('email')

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj
    }
    this.onChangeText = this.onChangeText.bind(this)
    this.onClickSignIn = this.onClickSignIn.bind(this)
    this.signUpSuccess = this.signUpSuccess.bind(this)
    this.signUpFail = this.signUpFail.bind(this)

    this.socialLoginSuccess = this.socialLoginSuccess.bind(this)
    this.socialLoginFail = this.socialLoginFail.bind(this)

  }

  socialLoginSuccess = async (data) => {
    const { registerToken, userEmail, history, currentUser } = this.props
    userEmail(data.user.email)
    if (data.regToken) {
      registerToken(data.regToken)
      currentUser(data.user);
      history.push("submit-details")
    } else if (data.auth_token) {
      registerToken(data.auth_token)
      currentUser(data.user);
      if (data.user.teams_type) {
        ToastsStore.success(ToastMessages.LOGIN_SUCCESS)
        history.push("/")
      } else {
        history.push("submit-details");
      }
    }
  }

  socialLoginFail = (error) => {
    ToastsStore.error(ToastMessages.LOGIN_FAIL)
    console.log("++++++++++ social error: ", error)
  }

  onChangeText = (event) => {
    const { target } = event
    const { name } = target
    this.setState((prevState) => ({
      stateObj: {
        formControls: {
          ...prevState.stateObj.formControls,
          [name]: target.value,
        },
        formErrors: {
          ...prevState.stateObj.formErrors,
          [name]: "",
        },
      }
    }))
  }

  signUpSuccess = (data) => {
    const { history, userEmail, registerToken, currentUser } = this.props
    const { stateObj } = this.state
    const { formControls } = stateObj
    userEmail(formControls.email);
    if (data.auth_token) {
      registerToken(data.auth_token)
      currentUser(data.user)
      if (data.user.teams_type) {
        history.push("/")
      } else {
        history.push("submit-details");
      }
    } else if (data.regToken) {
      registerToken(data.regToken)
      currentUser(data.user);
      history.push("submit-details")
    }
  }

  signUpFail = () => {
    ToastsStore.error(ToastMessages.LOGIN_FAIL)
  }

  onClickSignIn() {
    const { stateObj } = this.state
    const { formControls } = stateObj
    const formValid = emailValidation(formControls.email)
      && passwordValidation(formControls.password)
      && formControls.email
      && formControls.password
    if (formValid) {
      const { serviceRequest } = this.props;
      const body = JSON.stringify({
        email: formControls.email,
        password: formControls.password,
        loginType: 'Normal',
        avatar: ""
      })
      serviceRequest('user/login', 'POST', body, false, this.signUpSuccess.bind(this), this.signUpFail.bind(this));
    }
  }

  onClickSocialSignIn = (index) => {
    const { serviceRequest } = this.props;
    if (index === 1) {
      firebaseSetting.auth().signInWithPopup(googleProvider)
        .then((result) => {
          const { additionalUserInfo, user } = result
          const { profile } = additionalUserInfo
          const { providerData } = user
          const email = providerData[0].email
          const { family_name, given_name, picture } = profile
          const body = JSON.stringify({
            email,
            password: email,
            loginType: 'Google',
            avatar: picture,
            firstName: given_name,
            lastName: family_name
          })
          console.log('--------Google: ', result)
          serviceRequest('user/socialLogin', 'POST', body, false, this.socialLoginSuccess.bind(this), this.socialLoginFail.bind(this));
        }).catch(error => {
          console.log(error)
        })
    } else if (index === 2) {
      firebaseSetting.auth().signInWithPopup(facebookProvider)
        .then((result) => {
          const { additionalUserInfo, user } = result
          const { profile } = additionalUserInfo
          const { first_name, last_name, picture } = profile
          const { providerData } = user
          const email = providerData[0].email
          const { data } = picture
          const { url } = data
          const body = JSON.stringify({
            email,
            password: email,
            loginType: 'Facebook',
            avatar: url,
            firstName: first_name,
            lastName: last_name
          })
          console.log('+++++++++++: ', result)
          serviceRequest('user/socialLogin', 'POST', body, false, this.socialLoginSuccess.bind(this), this.socialLoginFail.bind(this));
        }).catch(error => {
          console.log(error)
          const { code, email } = error
          if (code === 'auth/account-exists-with-different-credential') {
            const body = JSON.stringify({
              email,
              password: email,
              loginType: 'Facebook',
              avatar: '',
            })
            serviceRequest('user/login', 'POST', body, false, this.socialLoginSuccess.bind(this), this.signUpFail.bind(this));
          }
        })
    } else if (index === 3) {
      firebaseSetting.auth().signInWithPopup(twitterProvider)
        .then((result) => {
          const { additionalUserInfo, user } = result;
          const { name, profile_image_url } = additionalUserInfo.profile
          const { providerData } = user
          const email = providerData[0].email
          const names = name.split(' ')
          const body = JSON.stringify({
            email,
            password: email,
            loginType: 'Twitter',
            avatar: profile_image_url,
            firstName: names[0],
            lastName: names[1]
          })

          serviceRequest('user/socialLogin', 'POST', body, false, this.socialLoginSuccess.bind(this), this.socialLoginFail.bind(this));
        }).catch(error => {
          const { code, email } = error
          if (code === 'auth/account-exists-with-different-credential') {
            console.log(code, email)
            const body = JSON.stringify({
              email,
              password: email,
              loginType: 'Twitter',
              avatar: '',
            })
            serviceRequest('user/login', 'POST', body, false, this.socialLoginSuccess.bind(this), this.signUpFail.bind(this));
          }
        })
    }
  }

  handleLinkedInSuccess = (data) => {
    const { serviceRequest } = this.props;
    const body = JSON.stringify({
      code: data.code,
      redirectUri: `${appBaseUrl}linkedin`,
      clientId: ClientIDs.LINKEDIN_APP_CLIENT_ID,
      clientSecret: ClientIDs.LINKEDIN_APP_CLIENT_SECRET
    })

    serviceRequest('user/linkedInProfile', 'POST', body, false, this.socialLoginSuccess.bind(this));
  }

  render() {
    const { stateObj } = this.state
    const { formControls, formErrors } = stateObj
    const { user } = this.props;

    // if (user.regToken) {
    //   return <Redirect to="/" />
    // }

    if (!emailValidation(formControls.email)) {
      emailError = "Please input valid email"
    }

    if (!passwordValidation(formControls.password)) {
      pwError = "Please input more than 8 characters including at least one number"
    } else if (formErrors.password) {
      pwError = "Please input correct password"
    }
    return (
      <div className="loginContainer">

        <Spinner show={user.loading} />
        {/* Logo part */}
        <div style={styles.bgPrimary} className="login-header h-12 flex content-center flex-wrap">

          <img className="object-contain h-10 w-full" src={Logo} alt="Logo" />

        </div>

        <div className="max-w-xs xl:max-w-2xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto p-2 text-start mt-5">
         
          <ul className="my-2 flex -mx-2 social-list list-none inline-block w-full  flex-col">

            <li className="w-full"> <Button
              backgroundColor="#ffffff"
              label="Login With Facebook"
              buttonIcon={facebookLogo}
              onClick={() => this.onClickSocialSignIn(2)}
            /></li>
            <li className="w-full"><LinkedIn
              clientId={ClientIDs.LINKEDIN_APP_CLIENT_ID}
              onFailure={this.handleLinkedInFailure}
              onSuccess={this.handleLinkedInSuccess}
              scope="r_emailaddress,r_liteprofile"
              redirectUri={`${appBaseUrl}linkedin`}
              renderElement={({ onClick }) => (
                <Button
                  label="Login With LinkedIn "
                   backgroundColor="#ffffff"
                  margin={"0px"}
                  buttonIcon={linkedinLogo}
                  onClick={onClick}
                />
              )}
            /></li>
            <li className="w-full"><Button
             label="Login With Google"
              backgroundColor="#ffffff"
              buttonIcon={googleLogo}
              onClick={() => this.onClickSocialSignIn(1)}
            /></li>
            <li className="w-full"> <Button
              label="Login With Twitter"
               backgroundColor="#ffffff"
              margin={"0px"}
              buttonIcon={twitterLogo}
              onClick={() => this.onClickSocialSignIn(3)}
            /></li>

          </ul>

        </div>
        {/* <div className="max-w-xs xl:max-w-2xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto p-2 text-start">
          <LabelInput
            parent="w-full mb-2"
            label="Email *"
            type="email"
            name="email"
            value={formControls.email}
            placeholder="Email"
            error={emailError}
            onChangeText={this.onChangeText}
          />
        </div>
        <div className="max-w-xs xl:max-w-2xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto p-2 mb-6 text-start">
          <LabelInput
            parent="w-full mb-2"
            label="Password *"
            type="password"
            name="password"
            value={formControls.password}
            placeholder="Password"
            error={pwError}
            onChangeText={this.onChangeText}
          />
        </div>
        <div className="max-w-xs xl:max-w-2xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto p-2 text-start">
          <Button
            label="SignIn"
            width="100%"
            backgroundColor="#ffffff"
            margin={"20px"}
            onClick={this.onClickSignIn}
          />
        </div> */}


        <div className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl my-2">
          <span>{"You need to agree our "}</span>
          <span>
            <a className="placeholder-blue-100 underline" href="/terms-of-use">
              Terms
            </a>
          </span>
          <span>{" of use and "}</span>
          <span>
            <a className="underline" href="/privacy-policy">Privacy Policies.</a>
          </span>
        </div>
      </div>
    )
  }
}

LoginPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  userEmail: PropTypes.func.isRequired,
  serviceRequest: PropTypes.func.isRequired,
  registerToken: PropTypes.func.isRequired,
  currentUser: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    userEmail,
    serviceRequest,
    registerToken,
    currentUser
  }
)(LoginPage)