import * as ActionTypes from '../config/ActionTypes';

const initialState = {
    email: '',
    regToken: '',
    loading: false,
    userInfo: null,
    selectedTeamInfo: null,
    allTeams: [],
    addedTeams: [],
    unVisibleTeams: [],
    feedback: null,
    editingPlayers: [],
};

const UserReducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.USER_EMAIL:
            return {
                ...state,
                email: action.payload
            };
        case ActionTypes.REGISTER_TOKEN:
            return {
                ...state,
                regToken: action.payload
            };
        case ActionTypes.CURRENT_USER:
            return {
                ...state,
                userInfo: action.payload
            };
        case ActionTypes.SERVICE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.SERVICE_REQUEST_SUCCESS:
        case ActionTypes.SERVICE_REQUEST_FAIL:
            return {
                ...state,
                loading: false
            }
        case ActionTypes.SELECT_TEAM:
            return {
                ...state,
                selectedTeamInfo: action.payload
            }
        case ActionTypes.ALL_TEAMS:
            return {
                ...state,
                allTeams: action.payload
            }
        case ActionTypes.OTHER_TEAMS:
            return {
                ...state,
                addedTeams: action.payload
            }
        case ActionTypes.UNVISIBLE_TEAMS:
            return {
                ...state,
                unVisibleTeams: action.payload
            }
        case ActionTypes.USER_FEEDBACK:
            return {
                ...state,
                feedback: action.payload
            }
        case ActionTypes.CHNAGE_EDITING_PLAYERS:
            return {
                ...state,
                editingPlayers: action.payload
            }
        default: 
            return state
    }
};

export default UserReducer;