import React from "react"
// import { connect } from "react-redux"
import PropTypes from "prop-types"

const warningIcon = require("../../../images/icons/warning.png")
const voltageIcon = require("../../../images/icons/voltage.png")
const cancelIcon = require("../../../images/icons/cancel.png")
const eyeIcon = require("../../../images/icons/eye.png")

const headerClass = "py-4 px-1 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl color-1 ";


export default class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { headers, onChange, rowData, onClickStrike, onShowDialog } = this.props
    return (
      <div className="w-full">
        <table className="text-left w-full border-collapse">
          <thead className="head-table">
            <tr className="text-center">
              {headers.map((item, index) => {
                if (index === 1) {
                  return <th className={`${headerClass} w-1/6`} key={`index-${index}`}>
                    {item}
                  </th>
                } else if (index === 3) {
                  return <th className={`${headerClass} w-40`} key={`index-${index}`}>
                    {item}
                  </th>
                } else {
                  return <th className={`${headerClass} w-1/3`} key={`index-${index}`}>
                    {item}
                  </th>
                }
              })}
            </tr>
          </thead>
          <tbody className="body-table">
            {rowData.length !== 0 &&
              rowData.map((item, index) => {
                const isFilled = item.athleteName !== "" && item.jerseyNumber !== "" && item.position !== ""
                return (
                  <tr className="hover:bg-blue-300 h-10 border border-gray-400" key={index}>
                    <td className=" h-full">
                      <input
                        className={`px-2 w-full h-full capitalize text-xs sm:text-sm md:text-base lg:text-md xl:text-lg ${item.isStriked && "line-through text-red-500"}`}
                        name="athleteName"
                        value={item.athleteName}
                        onChange={(e) => onChange(e, index)}
                      />
                    </td>
                    <td>
                      <input
                        className={`w-full h-full text-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl ${item.isStriked && "line-through text-red-500"}`}
                        pattern="\d*"
                        maxLength="2"
                        name="jerseyNumber"
                        value={item.jerseyNumber}
                        direction="center"
                        onChange={(e) => onChange(e, index)}
                      />
                    </td>
                    <td className=" text-center cursor-pointer" onClick={() => onShowDialog("position", item.position, index)}>
                      <span className={`w-full h-full text-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl ${item.isStriked && "line-through text-red-500"}`}>{item.position}</span>
                    </td>
                    <td>
                      <div className="flex w-full h-full justify-between items-center">
                        {item.isWarning ? (
                          <img
                            className="cursor-pointer border-0 w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 mr-2 md:h-6 lg:w-6 lg:h-6"
                            src={warningIcon}
                          />
                        ) : (
                          <span className="border-0 w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 mr-2 lg:w-6 lg:h-6" />
                        )}
                        {item.isOwnData ? (
                          <img
                            className="cursor-pointer border-0 w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 mr-2 md:h-6 lg:w-6 lg:h-6"
                            src={voltageIcon}
                          />
                        ) : (
                          <span className="border-0 w-6 h-6 sm:w-6 sm:h-6 xl:w-8 xl:h-8 md:w-8 md:h-8 lg:w-8 lg:h-8" />
                        )}
                        {isFilled ? (
                          <img
                            className="cursor-pointer mr-2 border-0 w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6"
                            src={item.isStriked ? eyeIcon : cancelIcon}
                            onClick={() => onClickStrike(index)}
                          />
                        ) : (
                          <span className="border-0 w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6" />
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        <div className="w-full pt-4 flex">
          <div className="w-1/3 flex px-1">
            <span className="text-xs inline text-center">
            <img
              className="block cursor-pointer border-0 w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 mx-auto md:h-6 lg:w-6 lg:h-6"
              src={warningIcon}
            /> Indicates you have possibly entered duplicated athletes</span>
          </div>
          <div className="w-1/3 flex px-1">
            <span className="text-xs inline text-center">
            <img
              className="block cursor-pointer border-0 w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 mx-auto lg:w-6 lg:h-6"
              src={voltageIcon}
            /> Indicates athlete provided own roster data</span>
          </div>
          <div className="w-1/3 flex px-1">
            <span className="text-xs inline text-center">
           <span className="block">
           <img
              className="inline cursor-pointer border-0 w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 mx-auto lg:w-6 lg:h-6 pr-1"
              src={eyeIcon}
            />
            <img
              className="inline cursor-pointer border-0 w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 mx-auto lg:w-6 lg:h-6"
              src={cancelIcon}
            />
           </span>
            Show/Hide athletes from stats-keeping functions</span>
          </div>
        </div>
      </div>
    )
  }
}

Table.defaultProps = {
  showAddButton: false,
  currentIndex: -1
}

Table.propTypes = {
  headers: PropTypes.instanceOf(Array),
  rowData: PropTypes.instanceOf(Array),
  currentIndex: PropTypes.number,
  onChange: PropTypes.func,
  onClickStrike: PropTypes.func,
  onShowDialog: PropTypes.func
}