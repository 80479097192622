const rowObj = {
  managerName: "",
  managerEmail: "",
  userType: "",
  email: "",
  athleteAge: "",
  state: "",
  athleteGender: ""
}

export const stateObj = {
  formControls: rowObj,
  formErrors: rowObj,
  formInvalid: false,
}
