const formObj = {
  teamName: "",
}

const rowObj = {
  subsName: "",
  subsEmail: "",
}

export const stateObj = {
  formControls: formObj,
  rowControls: rowObj,
  formErrors: formObj,
  rowErrors: rowObj,
  formInvalid: false,
}
