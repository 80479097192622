import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import {
  serviceRequest,
  selectTeam,
  changeEditingPlayers
} from "../../../actions/User"

class ViewAllTeams extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allTeams: []
    }
    this.onClickSelectTeam = this.onClickSelectTeam.bind(this)
    this.onClickReturn = this.onClickReturn.bind(this)
    this.requestSuccess = this.requestSuccess.bind(this)
  }

  componentDidMount = async () => {
    const { serviceRequest, user } = this.props;
    
    const body = JSON.stringify({
      userId: user.userInfo.id,
      getSubs: false
    })
    await serviceRequest('athlete/getAllTeamsAndAthletesByUserId', 'POST', body, false, this.requestSuccess.bind(this));
  }

  requestSuccess = (data) => {
    // console.log("---------: ", data)
    if (!data.error) {
      data.teams.sort((a, b) => {
        return new Date(b.team.updated_at) - new Date(a.team.updated_at)
      })
      
      this.setState({
        allTeams: data.teams
      })
    }
  }

  onClickSelectTeam = (item) => {
    const { selectTeam, changeEditingPlayers } = this.props
    changeEditingPlayers([])
    selectTeam(item)
    this.props.history.push("/view-team")
  }

  onClickReturn() {
    this.props.history.go(-1)
  }

  render() {
    const { allTeams } = this.state
    const { user } = this.props;
    if (!user.regToken) {
      return <Redirect to="/login" />
    }
    if (user.loading) {
      return <Spinner show={user.loading} />
    }
    return (
      <div style={styles.container}>
        <NavHeader/>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 pt-8 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-base sm:text-sm md:text-md lg:text-lg xl:text-xl">
            Select Team
          </span>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto p-2 bg-green rounded-lg flex flex-wrap justify-between">
          {allTeams && allTeams.map((item, index) => (
            <Button
              label={`${item.team.team_name} (${item.memberCount})`}
              width={"45%"}
              key={`index-${index}`}
              margin="2rem"
              activeColor="#5a67d8"
              noRounded
              onClick={() => this.onClickSelectTeam(item.team)}
            />
          ))}
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto p-2 pb-24 bg-outline1 bg-green rounded-lg">
          <Button
            label={"Return to Home"}
            width={"50%"}
            margin="1rem"
            activeColor="#5a67d8"
            onClick={this.onClickReturn}
          />
        </div>
        {/* <NavFooter/> */}
      </div>
    )
  }
}

ViewAllTeams.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired,
  changeEditingPlayers: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    selectTeam,
    changeEditingPlayers
  }
)(ViewAllTeams)