import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ToastsStore } from "react-toasts"
import { stateObj } from "./model"
import styles from "./styles"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
import Button from "../../components/buttons/button"
import LabelInput from "../../components/labeledinput/labeledinput"
import DropDown from "../../components/dropdown/dropdown"
import Table from "../../components/tables/table"
import MultiSelect from "../../components/multiselect/multiselect"
import ConfirmDialog from "../../components/dialogs/confirmDialog"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest, selectTeam, changeEditingPlayers } from '../../../actions/User'
import { fieldEmpty } from '../../../config/Global'
import { appBaseUrl, possiblePostions } from "../../../config/Constants"
import { ToastMessages } from "../../../config/ToastMessages"

class CreateTeam extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      teamId: -1,
      playersData: [],
      isClickManual: false,
      ageOptions: [],
      totalRows: 1,
      createdTeam: {},
      modalVisible: false,
      copyModalVisible: false,
      teamUrl: '',
      editingIndex: -1,
      posModalVisible: false,
      currentPosValue: [],
      currentPosName: '',
    }
    this.onClickAutoSetup = this.onClickAutoSetup.bind(this)
    this.onClickCloseModal = this.onClickCloseModal.bind(this)
    this.onClickManualSetup = this.onClickManualSetup.bind(this)
    this.onClickSaveAndReview = this.onClickSaveAndReview.bind(this)
    this.onChangeAddAthlete = this.onChangeAddAthlete.bind(this)
    this.onChangeText = this.onChangeText.bind(this)
    this.onClickStrike = this.onClickStrike.bind(this)

    this.createTeam = this.createTeam.bind(this)
    this.createTeamSuccess = this.createTeamSuccess.bind(this)
    this.createTeamFail = this.createTeamFail.bind(this)

    this.saveTeamSuccess = this.saveTeamSuccess.bind(this)
    this.saveTeamFail = this.saveTeamFail.bind(this)

    this.onClickOpenMailto = this.onClickOpenMailto.bind(this)
  }

  componentDidMount() {
    const { playersData } = this.state;
    const { location } = this.props;
    const { state } = location;
    this.setState({
      teamId: state !== undefined ? state.teamId : 0
    }, () => {
      const { teamId } = this.state;
      if (teamId !== 0) {
        console.log("++++++++: ", teamId)
      }
    })
    const ageArray = []
    
    for (let i = 0; i < 99; i++) {
      ageArray.push(`${i + 1}`);
    }
    const athlete1 = {
      athleteName: '',
      jerseyNumber: '',
      position: '',
      isWarning: false,
      isOwnData: false,
      isStriked: false,
      id: 0
    }
    playersData.push(athlete1)
    this.setState({
      ageOptions: ageArray
    })
  }

  createTeam = () => {
    const { stateObj, teamId } = this.state
    const { formControls } = stateObj
    const { serviceRequest, user } = this.props
    if (teamId === 0) {
      const body = JSON.stringify({
        managerId: user.userInfo ? user.userInfo.id : "",
        teamName: formControls.teamName,
        season: formControls.season,
        teamGender: parseInt(formControls.teamGender) === 0 ? "Male" : parseInt(formControls.teamGender) === 1 ? "Female" : "Co-Ed",
        avgAge: parseInt(formControls.avgAge) + 1
      })
      serviceRequest('team/create', 'POST', body, false, this.createTeamSuccess.bind(this), this.createTeamFail.bind(this));
    } else {
      const body = JSON.stringify({
        teamId,
        teamName: formControls.teamName,
        season: formControls.season,
        teamGender: parseInt(formControls.teamGender) === 0 ? "Male" : parseInt(formControls.teamGender) === 1 ? "Female" : "Co-Ed",
        avgAge: parseInt(formControls.avgAge) + 1
      })
      serviceRequest('team/update', 'POST', body, false);
    }
  }

  createTeamSuccess = (data) => {
    if (!data.error) {
      ToastsStore.success(ToastMessages.CREATE_TEAM_SUCCESS)
      this.setState({
        teamId: data.id,
        createdTeam: data,
        teamUrl: data.team_url
      })
    }
  }

  createTeamFail = (data) => {
    if (data.error === "Team Already Exists") {
      ToastsStore.warning("Team Already Exists")
      this.setState({
        teamId: data.team.id,
        teamUrl: data.team_url
      })
    } else {
      ToastsStore.error(ToastMessages.CREATE_TEAM_FAIL)
    }
  }


  formValidate = () => {
    const { stateObj } = this.state
    const { formControls } = stateObj
    
    return !fieldEmpty(formControls.teamName) && 
      !fieldEmpty(formControls.season) &&
      !fieldEmpty(formControls.teamGender) &&
      !fieldEmpty(formControls.avgAge)
  }

  onClickAutoSetup = async() => {
    await this.createTeam()
    this.setState({
      modalVisible: true
    })
  }

  onClickCloseModal() {
    this.setState({
      modalVisible: false
    })
    const { createdTeam } = this.state
    const { history, selectTeam, changeEditingPlayers } = this.props
    changeEditingPlayers([])
    selectTeam(createdTeam)
    history.push("view-team")
  }

  onClickOpenMailto = () => {
    const { teamUrl } = this.state
    const { user } = this.props
    const subject = "Stats collection for our team"
    const body = `Hi all, %0D%0A%0D%0A I'll be tracking our team's stats using a new app and need your help to get your name into the app. Basically, go to ${appBaseUrl}add-athlete/${teamUrl} and fill in the athlete's name, jersey #, and some other info so that I can quickly record data for our team using my app. %0D%0A%0D%0A Any questions, please let me know. %0D%0A%0D%0A ${user.userInfo.first_name} ${user.userInfo.last_name} %0D%0A%0D%0A`
    // eslint-disable-next-line no-undef
    window.open(`mailto:?subject=${subject}&body=${body}`)
  }

  saveTeamSuccess = (data) => {
    const { createdTeam } = this.state
    const { history, selectTeam, changeEditingPlayers } = this.props
    if (!data.error) {
      changeEditingPlayers([])
      selectTeam(createdTeam)
      history.push("view-team")
    }
  }

  saveTeamFail = (error) => {
    if (error) {
      console.log(error)
    }
  }

  onClickSaveAndReview() {
    const { serviceRequest, selectTeam, history } = this.props
    const { playersData, teamId, createdTeam } = this.state
    const sendingArr = []
    playersData.map((player) => {
      if (player.athleteName !== "") {
        const fullName = player.athleteName.match(/[a-zA-Z0-9]+/g)
        const fName = fullName[0]
        const lName = fullName[1]
        const positions = player.position.match(/[a-zA-Z0-9]+/g)
        const item = {
          firstName: fName ? fName.charAt(0).toUpperCase() + fName.slice(1) : "",
          lastName: lName ? lName.charAt(0).toUpperCase() + lName.slice(1) : "",
          jersey: player.jerseyNumber,
          position: positions && positions.length > 0 ? positions.filter(e => possiblePostions.indexOf(e) !== -1) : [],
          isDeleted: player.isStriked
        }
        sendingArr.push(item)
      }
    })
    
    if (sendingArr.length === 0) {
      selectTeam(createdTeam)
      history.push("view-team")
    } else {
      const body = JSON.stringify({ teamId: teamId,
        players: sendingArr})

      serviceRequest("athlete/addMultiAthletes", "POST", body, false, this.saveTeamSuccess.bind(this), this.saveTeamFail.bind(this))
    }
  }

  onClickManualSetup() {
    const { isClickManual } = this.state
    if (!isClickManual) {
      this.createTeam()
    }
    this.setState({
      isClickManual: !isClickManual,
    })
  }

  checkWarning() {
    const { playersData } = this.state;
    playersData.map((item, index) => {
      for (let subindex = 0; subindex < playersData.length; subindex++) {
        const subitem = playersData[subindex]
        if (index !== subindex) {
          if (item.athleteName && item.athleteName.toUpperCase() === subitem.athleteName.toUpperCase() || item.jerseyNumber && item.jerseyNumber === subitem.jerseyNumber) {
            item.isWarning = true
            break
          } else {
            item.isWarning = false
          }
        }
      }
    })
    this.setState({
      playersData
    })
  }

  onChangeText = (event) => {
    const { target } = event
    const { name, value } = target
    this.setState((prevState) => ({
      stateObj: {
        formControls: {
          ...prevState.stateObj.formControls,
          [name]: value
        },
        formErrors: {
          ...prevState.stateObj.formErrors,
          [name]: ""
        }
      },
    }))
  }

  checkRowFilled = (rowItem) => {
    const values = Object.values(rowItem)
    return values[0] !== "" && values[1] !== "" && values[2] !== ""    
  }

  onChangeAddAthlete = (e, index) => {
    const { playersData, totalRows } = this.state

    if (index < 25) {
      const { target } = e
      const { name, value, validity } = target
      if (validity.valid) {
        const editingItem = playersData[index]
        editingItem[name] = value
        if (index === totalRows - 1 && totalRows < 25) {
          if (editingItem.athleteName !== '') {
            const athlete = {
              athleteName: '',
              jerseyNumber: '',
              position: '',
              isWarning: false,
              isOwnData: false,
              isStriked: false,
              id: 0
            }
            playersData.push(athlete)
            this.setState({
              playersData,
              totalRows: totalRows + 1
            }, () => {
              this.checkWarning()
            })
          }
        } else if (index === totalRows - 2 && editingItem.athleteName === '' && editingItem.jerseyNumber === '' && editingItem.position === '') {
          playersData.pop()
          this.setState({
            playersData,
            totalRows: totalRows - 1
          }, () => {
            this.checkWarning()
          })
        }
        this.setState({
          playersData
        }, () => {
          this.checkWarning()
        })
      }
    }
  }

  onClickStrike = (index) => {
    const { playersData } = this.state
    const tmp = playersData.slice(0, playersData.length - 1)
    const editingItem = tmp[index]
    editingItem.isStriked = !editingItem.isStriked
    const hiddenPlayers = tmp.filter(e => e.isStriked)
    const unhiddenPlayers = tmp.filter(e => !e.isStriked)
    unhiddenPlayers.sort((a, b) => a.jerseyNumber - b.jerseyNumber)
    hiddenPlayers.sort((a, b) => a.jerseyNumber - b.jerseyNumber)

    const allPlayers = [
      ...unhiddenPlayers,
      ...hiddenPlayers
    ]
    const athlete1 = {
      athleteName: '',
      jerseyNumber: '',
      position: '',
      isWarning: false,
      isOwnData: false,
      isStriked: false,
      id: 0
    }
    allPlayers.push(athlete1)
    this.setState({
      playersData: allPlayers
    })
  }

  isSetupTeam = () => {
    const { stateObj } = this.state;
    const { formControls } = stateObj;
    return !fieldEmpty(formControls.teamName) &&
      !fieldEmpty(formControls.season) &&
      !fieldEmpty(formControls.teamGender) &&
      !fieldEmpty(formControls.avgAge)
  }

  onShowDialog = (name, value, index) => {
    const {
      posModalVisible,
      editingIndex,
      playersData,
    } = this.state;
    if (!posModalVisible) {
      const currentPosValue = value ? value.split(', ') : [];
      this.setState({
        posModalVisible: true,
        editingIndex: index,
        currentPosValue,
      });
    } else {
      if (name !== 'cancel') {
        playersData[editingIndex].position = value;
      }
      this.setState({
        posModalVisible: false,
        playersData,
      });
    }
  };

  render() {
    const { playersData, isClickManual, ageOptions, stateObj, modalVisible, copyModalVisible, teamUrl, posModalVisible, currentPosValue } = this.state
    const { user } = this.props;
    
    if (!user.regToken) {
      return <Redirect to="/login" />
    }
    return (
      <div style={styles.container}>
        <NavHeader/>
        <Spinner show={user.loading} />
        <div className="flex-col xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2 pt-8 bg-green rounded-lg">
          <LabelInput
            parent="w-full"
            label="Team Name"
            type="text"
            name="teamName"
            placeholder="Team Name"
            value={stateObj.formControls.teamName}
            error={stateObj.formErrors.teamName}
            onChangeText={this.onChangeText}
            onBlur={this.onBlurTeamInfo}
          />
          <LabelInput
            parent="w-full"
            label="Season"
            type="text"
            name="season"
            placeholder="Example: Fall 2019"
            value={stateObj.formControls.season}
            error={stateObj.formErrors.season}
            onChangeText={this.onChangeText}
            onBlur={this.onBlurTeamInfo}
          />
        </div>

        <div
          className="flex-col xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2 bg-green rounded-lg"
          style={{ marginTop: 10 }}
        >
          <DropDown
            label="Team Gender"
            className="w-full"
            options={["Male", "Female", "Co-Ed"]}
            name="teamGender"
            placeholder="Team Gender"
            selectedIndex={stateObj.formControls.teamGender}
            error={stateObj.formErrors.teamGender}
            onChange={this.onChangeText}
            onBlur={this.onBlurTeamInfo}
          />
          <DropDown
            label="Average Age of Athletes"
            className="w-full  pt-2"
            options={ageOptions}
            name="avgAge"
            placeholder="Average Age"
            selectedIndex={stateObj.formControls.avgAge}
            error={stateObj.formErrors.avgAge}
            onChange={this.onChangeText}
            onBlur={this.onBlurTeamInfo}
          />
        </div>

        {!isClickManual && this.isSetupTeam() && (
          <div className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2 mt-4 bg-green rounded-lg text-left">
            <span className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl italic">
              Easily setup your full team by sharing the setup link to your team: Your
              team will set up their name & pictures for easy stats collection by
              clicking here
            </span>
          </div>
        )}

        {!isClickManual && this.isSetupTeam() && (
          <div
            className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2  bg-green rounded-lg bg-outline1"
          >
            <Button
              label="Auto Setup Team"
              width="90%"
              backgroundColor="#ffffff"
              onClick={this.onClickAutoSetup}
            />
          </div>
        )}

        {this.isSetupTeam() && (
          <div className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2  pb-24 bg-green rounded-lg">
            <span
              className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl underline hover:text-blue-900 cursor-pointer"
              onClick={this.onClickManualSetup}
            >
              Or, type in team data manually
            </span>
          </div>
        )}

        {isClickManual && (
          <div>
            <div
              className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2 bg-green rounded-lg"
              style={{ marginTop: 20, paddingBottom: 50 }}
            >
              <Table
                headers={["Athlete Name", "#", "Positions", ""]}
                rowData={playersData}
                onChange={this.onChangeAddAthlete}
                onClickStrike={this.onClickStrike}
                onShowDialog={this.onShowDialog}
              />
            </div>
            <div
              className="max-w-sm xl:max-w-4xl lg:max-w-2xl md:max-w-lg sm:max-w-md mx-auto flex p-2 pb-24 bg-outline1 bg-green rounded-lg"
            >
              <Button
                label="Save and Review"
                width="80%"
                backgroundColor="#ffffff"
                onClick={this.onClickSaveAndReview}
              />
            </div>
          </div>
        )}
        <ConfirmDialog show={modalVisible} width={"500px"}>
          <div className="block text-center w-full mb-3">
            <span className="font-semibold">
              Please select how you prefer to invite your team:
            </span>
          </div>
          <div className="text-center w-full flex justify-between">
            <div className="w-1/2 px-2 py-3 border border-black cursor-pointer mr-2 rounded-lg justify-center" onClick={this.onClickOpenMailto}>
              <span className="text-sm color-1 font-semibold">Send a team email to your athletes/parents with an explanation and the private team access link</span>
            </div>
            <div className="w-1/2 px-2 py-3 border border-black cursor-pointer rounded-lg justify-center ml-2" onClick={() => this.setState({copyModalVisible: true, modalVisible: false})}>
              <CopyToClipboard text={`${appBaseUrl}add-athlete/${teamUrl}`}
                onCopy={() => this.setState({copied: true})}>
                <span className="text-sm color-1 font-semibold">Copy your team link and paste into a text message or another team communication spot</span>
              </CopyToClipboard>
            </div>
          </div>
          <div className="w-full flex mt-1">
            <a className="text-xs" href={`${appBaseUrl}add-athlete/${teamUrl}`} rel="noopener norefferrer" target="_blank">{`Team Link: ${appBaseUrl}add-athlete/${teamUrl}`}
            </a>
          </div>
          <div className="flex mt-6 w-full">
            <Button
              label="Close"
              width="40%"
              backgroundColor="white"
              margin="0.25rem"
              onClick={this.onClickCloseModal}
            />
          </div>
        </ConfirmDialog>
        <ConfirmDialog show={copyModalVisible} width={"500px"}>
          <div className="block text-center w-full mb-3">
            <span className="font-semibold">
              Team URL has been copied to your clipboard. Please share the link now with your team.
            </span>
          </div>
          <div className="flex mt-6 w-full">
            <Button
              label="Close"
              width="40%"
              backgroundColor="white"
              margin="0.25rem"
              onClick={() => this.setState({copyModalVisible: false, modalVisible: true})}
            />
          </div>
        </ConfirmDialog>
        <MultiSelect
          visible={posModalVisible}
          currentPosValue={currentPosValue}
          closeDialog={this.onShowDialog}
        />
        {/* <NavFooter/> */}
      </div>
    )
  }
}

CreateTeam.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired,
  changeEditingPlayers: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    selectTeam,
    changeEditingPlayers
  }
)(CreateTeam)