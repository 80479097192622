import { createStore, combineReducers, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import UserReducer from './app/redux/UserReducer'
import GameReducer from './app/redux/GameReducer'

const rootReducer = combineReducers({
  user: UserReducer,
  game: GameReducer
});

const persistConfig = {
  key: 'root-stats',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const origStore = createStore(persistedReducer, {}, applyMiddleware(ReduxThunk))
const origPersistor = persistStore(origStore)

export const store = origStore
export const persistor = origPersistor

