import React, { Component } from 'react'
import PropTypes from 'prop-types'
const iconDashboard = require("../../../images/icons/icon-dashboard.png")
const iconProfile = require("../../../images/icons/icon-profile.png")
const iconlogOut = require("../../../images/icons/icon-logout.png")
export default class navFooter extends Component {

    render() {
        return (
           <footer className='w-full fixed flex items-center justify-between flex-wrap bg-white py-2 px-4 z-10 bottom-0'>
           <ul className="flex  justify-between w-full">
  <li className="block tracking-wide text-grey-darker text-xs font-semibold">
    <a className="text-gray-400 hover:text-blue-800" href="#">
    <img src={iconDashboard} className="fooIcon" alt=""/>
      Dashboard
      </a>
  </li>
  <li className="block tracking-wide text-grey-darker text-xs font-semibold">
    <a className="text-gray-400 hover:text-blue-800" href="#">
    <img src={iconProfile} className="fooIcon" alt=""/>
      Profile</a>
  </li>
  <li className="block tracking-wide text-grey-darker text-xs font-semibold">
    <a className="text-gray-400 hover:text-blue-800" href="#">
    <img src={iconlogOut} className="fooIcon" alt=""/>
      LogOut</a>
  </li>
  
</ul>
        </footer>
        )
    }
}
