import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import { stateObj } from "./model"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest } from "../../../actions/User"
import { reportAthlete, reportGameKudos } from "../../../actions/Game"
import { positiveEvents } from "../../../config/Constants"
import { formatTime } from "../../utils/TimerUtils"

class ViewGames extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      isMain: true,
      mainTeamData: [],
      oppTeamData: [],
      mainTotal: {
        pos: 0,
        neg: 0
      },
      oppTotal: {
        pos: 0,
        neg: 0
      },
      mainTeamName: '',
      oppTeamName: '',
      gameDate: '',
      gameId: '',
      mainGoals: 0,
      oppGoals: 0,
      kudosUrl: '',
      gameTime: 0,
      isSubed: false,
      numSubd: 0
    }
    this.onClickSelectMain = this.onClickSelectMain.bind(this)
    this.onClickSelectOpponent = this.onClickSelectOpponent.bind(this)
    this.onClickViewAthlete = this.onClickViewAthlete.bind(this)
    this.onClickSendKudos = this.onClickSendKudos.bind(this)
  }

  componentDidMount() {
    const { game, serviceRequest } = this.props;
    const { selectedGame } = game
    if (selectedGame) {
      const { game, mainTeamName, oppTeamName } = selectedGame
      const subdManagers = JSON.parse(game.substitution_managers)
      this.setState({
        gameId: game.id,
        mainTeamName,
        oppTeamName,
        gameDate: game && game.updated_at ? game.updated_at.split(' ')[0] : "",
        kudosUrl: game.kudos_report,
        mainGoals: game.main_team_goals,
        oppGoals: game.opp_team_goals,
        gameTime: game.game_duration,
        numSubd: subdManagers.length
      })
      const body = JSON.stringify({
        gameId: game.id,
        mainTeamId: game.main_team_id,
        oppTeamId: game.opponent_team_id
      })
      
      serviceRequest("game/getGameAthleteStats", "POST", body, false, this.getGameAthleteStatsSuccess.bind(this))
    }
  }

  getDataForKudos = (data) => {
    const result = data.reduce(function(hash) {
      return function (r, o) {
        if (!hash[o.action_info.athlete_id]) {
          hash[o.action_info.athlete_id] = []
          r.push(hash[o.action_info.athlete_id])
        }
        hash[o.action_info.athlete_id].push(o)
        return r
      }
    }(Object.create(null)), [])

    const kudosReports = []
    result.map(subarray => {
      let posKudos = {}
      subarray.map(info => {
        if (positiveEvents.indexOf(info.action_info.action_name) > -1) {
          posKudos = {
            ...posKudos,
            [info.action_info.action_name]: posKudos[info.action_info.action_name] ? posKudos[info.action_info.action_name] + 1 : 1
          }
        }
      })

      const kudosKeys = Object.keys(posKudos)
      if (kudosKeys.length !== 0) {
        const item = subarray[0]
        kudosReports.push({
          athleteName: item.athlete.last_name ? `${item.athlete.first_name} ${item.athlete.last_name}` : item.athlete.first_name,
          posKudos,
        })
      }
      
    })
    console.log('+++++++++++: ', kudosReports)
    this.setState({
      kudosReports
    })
  }

  reorderArrayWithSameAthlete = (data, isMain) => {
    const { gameTime } = this.state
    const result = data.reduce(function(hash) {
      return function (r, o) {
        if (!hash[o.action_info.athlete_id]) {
          hash[o.action_info.athlete_id] = []
          r.push(hash[o.action_info.athlete_id])
        }
        hash[o.action_info.athlete_id].push(o)
        return r
      }
    }(Object.create(null)), [])

    const ret = []
    let totalPosEvents = 0
    let totalNegEvents = 0
    result.map(subarray => {
      let posEvents = 0
      let negEvents = 0
      subarray.map(info => {
        if (positiveEvents.indexOf(info.action_info.action_name) > -1) {
          posEvents += 1
        } else {
          negEvents += 1
        }
      })
      
      const item = subarray[0]
      if (item.athlete) {
        const athlete = {
          id: item.action_info.athlete_id,
          name: item.athlete.last_name ? `${item.athlete.first_name} ${item.athlete.last_name}` : item.athlete.first_name,
          jersey: item.athlete.jersey_number ? item.athlete.jersey_number : '',
          gameTime: isMain ? parseInt(item.played_time) : gameTime,
          posEvents,
          negEvents
        }
        ret.push(athlete)
      }
      totalPosEvents += posEvents
      totalNegEvents += negEvents
    })
    const teamData = {
      ret,
      totalPosEvents,
      totalNegEvents
    }
    return teamData
  }

  getGameAthleteStatsSuccess = (data) => {
    this.getDataForKudos(data.mainTeamStats)
    const mainTeamData = this.reorderArrayWithSameAthlete(data.mainTeamStats, true)
    const oppTeamData = this.reorderArrayWithSameAthlete(data.oppTeamStats, false)
    const { numSubd } = this.state
    this.setState({
      mainTeamData: mainTeamData.ret,
      oppTeamData: oppTeamData.ret,
      mainTotal: {
        pos: mainTeamData.totalPosEvents,
        neg: mainTeamData.totalNegEvents
      },
      oppTotal: {
        pos: oppTeamData.totalPosEvents,
        neg: oppTeamData.totalNegEvents
      },
      isSubed: this.checkSubedStatus(mainTeamData.ret) || numSubd !== 0
    })
  }

  onClickSelectMain = () => {
    this.setState({ isMain: true })
  }

  onClickSelectOpponent = () => {
    this.setState({ isMain: false })
  }

  onClickViewAthlete = (athlete) => {
    const { mainTeamName, oppTeamName, isMain, gameDate, gameId } = this.state
    const { history, reportAthlete } = this.props
    reportAthlete({
        gameId,
        athlete,
        teamName: isMain ? mainTeamName : oppTeamName,
        gameDate
    })
    history.push("/view-reports/athlete")
  }

  onClickSendKudos = () => {
    const { kudosReports, kudosUrl } = this.state
    const { reportGameKudos } = this.props
    const kudos = {
      kudosReports,
      kudosUrl
    }
    reportGameKudos(kudos)
    this.props.history.push("send-kudos")
  }

  checkSubedStatus = (array) => {
    const ret = array.filter(item => item.gameTime !== 0)
    return ret.length !== 0
  }

  render() {
    const { user, game } = this.props
    const { selectedGame } = game
    
    if (!user) {
      return <Redirect to="/login" />
    }

    const {
      isMain,
      mainTeamData,
      oppTeamData,
      mainTotal,
      oppTotal,
      mainTeamName,
      oppTeamName,
      gameDate,
      mainGoals,
      oppGoals,
      isSubed
    } = this.state
    const renderData = isMain ? mainTeamData : oppTeamData

    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            {`Report for ${isMain ? mainTeamName : oppTeamName} team on ${gameDate}`}
          </span>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <Button
            label={selectedGame && selectedGame.game.is_scrimming === 0 ? `${mainTeamName} (${mainGoals})` : mainTeamName}
            width="100%"
            backgroundColor={selectedGame && selectedGame.game.is_scrimming === 0 ? ( mainGoals >= oppGoals ? "#0f0" : "#f00") : "#fff"}
            onClick={this.onClickSelectMain}
          />
          <span className="px-4" />
          <Button
            label={selectedGame && selectedGame.game.is_scrimming === 0 ? `Opponent (${oppGoals})` : 'Opponent'}
            width="100%"
            backgroundColor={!isMain ? "#0fd" : "#fff"}
            onClick={this.onClickSelectOpponent}
          />
        </div>

        {isMain && (
          <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
            <Button
              label="Send Kudos to the Team!"
              width="80%"
              backgroundColor="#dee5ea"
              onClick={this.onClickSendKudos}
            />
          </div>
        )}

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <table className="text-left w-full border-collapse bg-white">
            <thead>
              <tr className="text-center">
                <th className="py-4 px-2 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900 text-left">
                  <span>Athlete Name</span>
                </th>
                {isSubed && (
                  <th className="py-4 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900">
                    Time In Game
                  </th>
                )}
                <th className="py-4 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900">
                  #Positive Events
                </th>
                <th className="py-4 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900">
                  #Negative Events
                </th>
              </tr>
            </thead>
            <tbody>
              {renderData.map((item, index) => (
                <tr className="text-center w-full border border-blue-900" key={`index-${index}`}>
                  <td
                    className="w-32 xs:w-32 sm:w-32 md:w-48 lg:w-56 xl:w-64 flex py-2 px-2 text-left hover:bg-blue-500 cursor-pointer text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                    onClick={() => this.onClickViewAthlete(item)}
                  >
                    <span className="truncate">{item.name}</span>
                    <span>{item.jersey ? `(${item.jersey})` : ''}</span>
                  </td>
                  {isSubed && (
                    <td className="py-2 border-l border-blue-900 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                      <span>{item.gameTime === 0 ? 'N/A' : formatTime(item.gameTime)}</span>
                    </td>
                  )}
                  <td className="py-2 border-l border-blue-900 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                    <span>{item.posEvents}</span>
                  </td>
                  <td className="py-2 border-l border-blue-900 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                    <span>{item.negEvents}</span>
                  </td>
                </tr>
              ))}
              <tr className="text-center">
                <td className="py-2 px-2 text-left border border-blue-900">
                  <span className="font-bold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">TOTAL</span>
                </td>
                {isSubed && (<td className="py-2 border border-blue-900" />)}
                <td className="py-2 border border-blue-900">
                  <span className="font-bold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{mainTotal.pos}</span>
                </td>
                <td className="py-2 border border-blue-900">
                  <span className="font-bold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{mainTotal.neg}</span>
                </td>
              </tr>
              <tr className="text-center">
                <td className="py-2 px-2 text-left border border-blue-900">
                  <span className="font-bold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">OPPONENT</span>
                </td>
                {isSubed && (<td className="py-2 border border-blue-900" />)}
                <td className="py-2 border border-blue-900">
                  <span className="font-bold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{oppTotal.pos}</span>
                </td>
                <td className="py-2 border border-blue-900">
                  <span className="font-bold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{oppTotal.neg}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

ViewGames.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  game: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  reportAthlete: PropTypes.func.isRequired,
  reportGameKudos: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    reportAthlete,
    reportGameKudos
  }
)(ViewGames)