/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import { stateObj } from "./model"
import Button from "../../components/buttons/button"
import LabelInput from "../../components/labeledinput/labeledinput"
import DropDown from "../../components/dropdown/dropdown"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest } from "../../../actions/User"
import { positiveEvents } from "../../../config/Constants"
import { fieldEmpty, emailValidation } from '../../../config/Global'
import { homeStates } from "../../../config/Constants"

class ViewKudos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      kudosData: [],
      loading: false,
      submitted: false,
      teamName: '',
      ageOptions: [],
      gameDate: '',
      teamId: '',
      homeStatesOptions: []
    }
    this.onClickViewGameReport = this.onClickViewGameReport.bind(this)
    this.onChangeText = this.onChangeText.bind(this)
  }

  componentDidMount() {
    const { match, location } = this.props
    const { params } = match
    const { kudos } = params
    const { search } = location
    if (search) {
      this.setState({
        submitted: true,
      })
    }

    const homeStatesOptions = []
    homeStates.map(item => {
      homeStatesOptions.push(item.name)
    })
    
    this.setState({
      loading: true,
      ageOptions: this.makeAgeOptions(5, 99),
      homeStatesOptions
    })

    const { serviceRequest } = this.props;
    const body = JSON.stringify({
      kudosUrl: kudos
    })
    
    serviceRequest('game/getGameReportsByKudos', 'POST', body, true, this.getGameSuccess.bind(this), this.getGameFail.bind(this));
  }

  makeAgeOptions = (start, end) => {
    const ret = []
    for (let i = start; i <= end; i++) {
      ret.push(i)
    }
    return ret
  }

  getGameSuccess = (data) => {
    this.setState({
      loading: false,
      teamName: data.teamName,
      gameDate: data.date,
      teamId: data.teamId
    })
    this.getDataForKudos(data.stats)
  }

  getGameFail = () => {
    this.setState({
      loading: false
    })
  }

  getDataForKudos = (data) => {
    const result = data.reduce(function(hash) {
      return function (r, o) {
        if (!hash[o.action_info.athlete_id]) {
          hash[o.action_info.athlete_id] = []
          r.push(hash[o.action_info.athlete_id])
        }
        hash[o.action_info.athlete_id].push(o)
        return r
      }
    }(Object.create(null)), [])

    const kudosReports = []
    result.map(subarray => {
      let posKudos = {}
      subarray.map(info => {
        if (positiveEvents.indexOf(info.action_info.action_name) > -1) {
          posKudos = {
            ...posKudos,
            [info.action_info.action_name]: posKudos[info.action_info.action_name] ? posKudos[info.action_info.action_name] + 1 : 1
          }
        }
      })

      const kudosKeys = Object.keys(posKudos)
      if (kudosKeys.length !== 0) {
        const item = subarray[0]
        kudosReports.push({
          athleteName: item.athlete.last_name ? `${item.athlete.first_name} ${item.athlete.last_name}` : item.athlete.first_name,
          posKudos,
        })
      }
      
    })
    this.getHighlightEvent(kudosReports)
  }

  getHighlightEvent = (data) => {
    const kudosData = []
    data.map(kudos => {
      const posKudos = kudos.posKudos
      
      const keysSorted = Object.keys(posKudos)
      if (keysSorted.indexOf('Goal') > -1 || keysSorted.indexOf('PK Goal') > -1) {
        const rowData = {
          name: kudos.athleteName,
          event: 'Goal',
          highlights: kudos.posKudos['Goal'] ? kudos.posKudos['Goal'] : 0  + kudos.posKudos['PK Goal'] ? kudos.posKudos['PK Goal'] : 0,
        }
        kudosData.push(rowData)
      } else if (keysSorted.indexOf('Assist') > -1) {
        const rowData = {
          name: kudos.athleteName,
          event: 'Assist',
          highlights: kudos.posKudos['Assist']
        }
        kudosData.push(rowData)
      } else {
        const arrKudos = Object.keys(posKudos).map(function(key) {
          return {
            name: key,
            value: posKudos[key]
          }
        });
        
        arrKudos.sort((a, b) => b.value - a.value)
        const rowData = {
          name: kudos.athleteName,
          event: arrKudos[0].name,
          highlights: arrKudos[0].value
        }
        kudosData.push(rowData)
      }
    })
    this.setState({
      kudosData
    })
  }

  submitSuccess = () => {
    this.setState({
      loading: false,
      submitted: true
    })
  }

  submitFail = (error) => {
    this.setState({
      loading: false
    })
  }

  onClickViewGameReport = () => {
    if (this.formValidate()) {
      const { stateObj, teamId, ageOptions } = this.state
      const { formControls } = stateObj
      const { serviceRequest, match } = this.props
      const homeState = homeStates[formControls.state]
      const body = JSON.stringify({
        userType: formControls.userType == 0 ? "Coach" : "Parent/Athlete",
        email: formControls.email,
        athleteAge: ageOptions[formControls.athleteAge],
        state: homeState.name,
        athleteGender: formControls.athleteGender == 0 ? "Male" : "Female",
        kudosLink: `${match.params.kudos}`,
        teamId
      })

      this.setState({
        loading: true
      })
      serviceRequest('kudos/create', 'POST', body, true, this.submitSuccess.bind(this), this.submitFail.bind(this))
    }
  }

  onChangeText = (e) => {
    const { target } = e
    const { name, value } = target
    
    const { stateObj } = this.state
    this.setState({
      stateObj: {
        ...stateObj,
        formControls: {
          ...stateObj.formControls,
          [name]: value
        },
        formErrors: {
          ...stateObj.formErrors,
          [name]: ""
        }
      }
    })
  }

  formValidate = () => {
    const { stateObj } = this.state
    const { formControls } = stateObj
    this.setState({
      stateObj: {
        ...stateObj,
        formErrors: {
          ...stateObj.formErrors,
          userType: fieldEmpty(formControls.userType) ? "This field required" : "",
          athleteAge: fieldEmpty(formControls.athleteAge) ? "This field required" : "",
          athleteGender: fieldEmpty(formControls.athleteGender) ? "This field required" : "",
          email: !emailValidation(formControls.email) || fieldEmpty(formControls.email) ? "Please input correct email" : "",
          state: fieldEmpty(formControls.state) ? "This field required" : "",
        }
      }
    })
    
    return !fieldEmpty(formControls.userType) && !fieldEmpty(formControls.athleteAge) && !fieldEmpty(formControls.athleteAge) && !fieldEmpty(formControls.athleteGender) && emailValidation(formControls.email) && !fieldEmpty(formControls.state)
  }

  render() {
    const { kudosData, loading, submitted, teamName, ageOptions, stateObj, gameDate, homeStatesOptions } = this.state
    if (loading) {
      return <Spinner show={loading} />
    }
    return (
      <div style={styles.container}>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mb-4 flex-wrap">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            {submitted ? `Great Game, ${teamName}`: `Viewing Game Data is Restricted to ${teamName} Only`}
          </span>
          {submitted && (
            <span className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg w-full">
              {`Here are some accomplishments our team had in the game on ${gameDate?.substring(0, 10)} :`}
            </span>
          )}
        </div>

        {!submitted && (
          <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-4 bg-green rounded-lg">
            <span className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
              Please provide your information to get access to our game "kudos" report.
            </span>
          </div>
        )}

        {submitted ? (
          <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-4 bg-green rounded-lg">
            <table className="text-left w-full border-collapse bg-white">
              <tbody>
                {kudosData.map((item, index) => (
                  <tr className="text-center hover:bg-blue-300" key={`index-${index}`}>
                    <td className="py-2 px-2 text-left border border-blue-900">
                      <span className="font-semibold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">{item.name}</span>
                    </td>
                    <td className="py-2 border border-blue-900">
                      <span className="font-semibold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">{`${item.highlights} ${item.event}`}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto p-2 border border-black">
            <div className="w-full mb-3">
              <DropDown
                label="User Type"
                className="w-full px-3"
                options={['Coach', 'Athlete/Parent']}
                name="userType"
                placeholder="Select User Type"
                selectedIndex={stateObj.formControls.userType}
                error={stateObj.formErrors.userType} 
                onChange={this.onChangeText}
              />
            </div>

            <div className="w-full mb-3">
              <LabelInput
                parent="w-full px-3 mb-0 md:mb-0"
                label="Email"
                type="text"
                name="email"
                value={stateObj.formControls.email}
                placeholder="john@example.com"
                error={stateObj.formErrors.email}
                onChangeText={this.onChangeText}
              />
            </div>

            <div className="w-full flex mb-3">
              <DropDown
                label="Age of Athlete"
                className="w-1/3 px-3"
                options={ageOptions}
                name="athleteAge"
                placeholder="Select Age"
                selectedIndex={stateObj.formControls.athleteAge}
                error={stateObj.formErrors.athleteAge} 
                onChange={this.onChangeText}
              />
              <DropDown
                label="State"
                className="w-1/3 px-3"
                options={homeStatesOptions}
                name="state"
                placeholder="Select State"
                selectedIndex={stateObj.formControls.state}
                error={stateObj.formErrors.state} 
                onChange={this.onChangeText}
              />
              <DropDown
                label="Athlete Gender"
                className="w-1/3 px-3"
                options={['Male', 'Female']}
                name="athleteGender"
                placeholder="Select Gender"
                selectedIndex={stateObj.formControls.athleteGender}
                error={stateObj.formErrors.athleteGender} 
                onChange={this.onChangeText}
              />
            </div>
          </div>
        )}
        {!submitted && (
          <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 py-6 bg-green rounded-lg">
            <Button
              label="View Game Report"
              width="80%"
              backgroundColor="#dee5ea"
              onClick={this.onClickViewGameReport}
            />
          </div>
        )}
      </div>
    )
  }
}

ViewKudos.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest
  }
)(ViewKudos)