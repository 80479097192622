import React from "react"
import PropTypes from "prop-types"

export default class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { show, children, width, position } = this.props
    if (!show) {
      return null
    }
    return (
      <div className={`fixed top-10 left-0 right-0 bottom-0 modal-bg-color p-2 ${position === 'mid-center' ? 'flex' : ''}`}>
        <div className="max-w-sm bg-white rounded-lg m-auto p-4" style={{ width }}>
          {children}
        </div>
      </div>
    )
  }
}

ConfirmDialog.defaultProps = {
  position: 'mid-center'
}

ConfirmDialog.propTypes = {
  width: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
}
