/* eslint-disable no-undef */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import firebase from 'firebase'
import {
  serviceRequest,
  currentUser,
  selectTeam,
  getAllTeams,
  changeEditingPlayers
} from '../../actions/User'
import { currentGame } from "../../actions/Game"
import { isMobileDevice } from "../../config/Global"
import { getCurrentUTCSeconds, formatTime } from "../utils/TimerUtils"
import styles from "./styles"

import NavHeader from "../components/navheader/navheader"
import NavFooter from "../components/navfooter/navFooter"
import Spinner from "../components/spinner/spinner"

const itemClass =
  "bg-gray-500 bg-mix w-full h-16 sm:h-18 md:h-30 lg:h-30 rounded overflow-hidden flex cursor-pointer hover:bg-green-500"
const itemFont = "text-xs sm:text-md md:text-lg  font-bold"

class MainApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: true,
      fbLoading: false,
      teams: [],
      finishedGames: [],
      progressGames: [],
      mostRemainTeamName: '',
      mostGame: null,
      mostTeam: null
    }
    this.onClickCreateTeam = this.onClickCreateTeam.bind(this)
    this.onClickViewTeam = this.onClickViewTeam.bind(this)
    this.onClickViewTeams = this.onClickViewTeams.bind(this)
    this.onClickViewReports = this.onClickViewReports.bind(this)
    this.onClickRateApp = this.onClickRateApp.bind(this)
    this.onClickGameStats = this.onClickGameStats.bind(this)
    this.onClickRecordStats = this.onClickRecordStats.bind(this)
    this.onClickRecordScrimmage = this.onClickRecordScrimmage.bind(this)
    this.onClickDelegateSubs = this.onClickDelegateSubs.bind(this)

    this.requestSuccess = this.requestSuccess.bind(this)
    this.requestFail = this.requestFail.bind(this)
  }

  componentDidMount() {
    const { serviceRequest, user } = this.props;
    const body = JSON.stringify({
      email: user.email
    })
    serviceRequest('user/currentUser', 'POST', body, false, this.requestSuccess.bind(this), this.requestFail.bind(this));
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  startTimer = () => {  
    this.timer = setInterval(() => {
      const { mostGame } = this.state
      const gameDb = firebase.firestore()
        gameDb.collection("games").doc(`game-${mostGame.id}`).get()
          .then(doc => {
            if (doc.exists) {
              const firData = doc.data()
              const gameTime = firData.isPlaying
                ? getCurrentUTCSeconds() - firData.startTime
                : firData.pauseTime - firData.startTime
              const remainTime = firData.gameDuration - gameTime
              this.setState({
                remainTime: formatTime(remainTime)
              })
            } else {
              if (this.timer) {
                clearInterval(this.timer)
                this.setState({
                  mostRemainTeamName: '',
                  remainTime: ''
                });
              }
            }
          })
          .catch(error => {
            console.log('---Firebase doc get error: ', error)
          })
    }, 1000);
  }

  requestSuccess = async (data) => {
    if (!data.error) {
      const { currentUser, getAllTeams } = this.props;
      currentUser(data.user);
      if (data.teams) {
        data.teams.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at)
        })
      }
      getAllTeams(data.teams)
    
      this.setState({
        teams: data.teams,
        finishedGames: data.finished_games,
      })
      if (data.progress_games.length > 0) {
        const fbGames = []
        const fArray = data.progress_games.map(async (game) => {
          const gameDb = firebase.firestore()
          await gameDb.collection("games").doc(`game-${game.id}`).get()
            .then(doc => {
              if (doc.exists) {
                const firData = doc.data()
                const gameTime = getCurrentUTCSeconds() - firData.startTime
                const status = {
                  gameId: firData.gameId,
                  remainTime: firData.gameDuration - gameTime
                }
                fbGames.push(status)
              }
            })
            .catch(error => {
              console.log('---Firebase doc get error: ', error)
            })
        })
        this.setState({
          fbLoading: true
        })
        
        await Promise.all(fArray)
        this.setState({
          fbLoading: false
        }, () => {
          fbGames.sort((a, b) => b.remainTime - a.remainTime)
          if (fbGames.length > 0) {
            const findGameId = fbGames[0].gameId
            const mostGame = data.progress_games.find(item => item.id === findGameId)
            if (mostGame) {
              const mostTeam = data.teams.find(team => team.id === parseInt(mostGame.main_team_id))
              if (mostTeam) {
                this.setState({
                  mostRemainTeamName: mostTeam.team_name,
                  mostGame,
                  mostTeam
                }, () => {
                  this.startTimer()
                })
              }
            }
          }
        })
      } else {
        if (this.timer) {
          clearInterval(this.timer)
        }
        this.setState({
          mostRemainTeamName: '',
          remainTime: ''
        })
      }
    }
  }

  requestFail = (error) => {
    console.log('--------: ', error)
  }

  onClickCreateTeam() {
    this.props.history.push({
      pathname: "create-team",
      state: {
        teamId: 0
      }
    })
  }

  onClickViewTeam = (teamInfo) => {
    const { selectTeam, changeEditingPlayers } = this.props
    changeEditingPlayers([])
    selectTeam(teamInfo)
    this.props.history.push("view-team")
  }

  onClickViewTeams() {
    this.props.history.push("view-allteam")
  }

  onClickViewReports() {
    this.props.history.push("view-reports")
  }

  onClickRateApp() {
    this.props.history.push("rate-app")
  }

  onClickGameStats = () => {
    const { mostGame } = this.state
    const { currentGame } = this.props
    currentGame({game: mostGame})
    this.props.history.push("game-stats/record-activity")
  }

  onClickRecordStats() {
    this.props.history.push("game-stats/record")
  }

  onClickRecordScrimmage() {
    this.props.history.push("game-stats/record-scrimmage")
  }

  onClickDelegateSubs() {
    this.props.history.push("delegate-subs")
  }

  goFull = () => {
    var el = document.documentElement;

    // Supports most browsers and their versions.
    var requestMethod = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen;

    if (requestMethod) {

      // Native full screen.
      requestMethod.call(el);

    }

    window.screen.orientation.lock('landscape');
  }

  onClickChangeStats = () => {
    if (isMobileDevice())
      this.goFull()
    this.props.history.push("change-stats")
  }

  render() {
    const { user } = this.props
    const { loading } = user
    
    const { teams, finishedGames, fbLoading, mostRemainTeamName, remainTime } = this.state
    

    if (loading || fbLoading) {
      return <Spinner show={loading || fbLoading} />
    }

    if (!user.regToken || user.regToken && user.userInfo && !user.userInfo.teams_type) {
      return <Redirect to="/login" />
    }

    return (
      <div className="full-image-bg relative">
        <NavHeader />
        <div className="w-full flex justify-center flex-col px-2 absolute" style={styles.container}>
        <div className="w-full flex flex-col mx-2  mx-auto max-w-lg xl:max-w-4xl lg:max-w-3xl md:max-w-2xl sm:max-w-lg welcome-intro ">
          <h4>Welcome</h4>
          <h2>Soccer Stats</h2>
          </div>
          <div className="w-full flex flex-wrap mx-2 mx-auto max-w-lg xl:max-w-4xl lg:max-w-3xl md:max-w-2xl sm:max-w-lg">
            <div className="w-1/2 px-2 mb-4 cols">
              <div
                className={itemClass}
                style={styles.item}
                onClick={this.onClickCreateTeam}
              >
                <span className={itemFont}>Create New Team</span>
              </div>
            </div>
            {teams.length > 0 && (
              <div className="w-1/2 px-2 mb-4 cols">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={() => this.onClickViewTeam(teams[0])}
                >
                  <span className={itemFont}>{`View ${teams[0].team_name}`}</span>
                </div>
              </div>
            )}
            {teams.length > 1 && (
              <div className="w-1/2 px-2 mb-4  cols">
                {teams.length === 2 ? (
                  <div
                    className={itemClass}
                    style={styles.item}
                    onClick={() => this.onClickViewTeam(teams[1])}
                  >
                    <span className={itemFont}>{`View ${teams[1].team_name}`}</span>
                  </div>
                ) : (
                  <div
                    className={itemClass}
                    style={styles.item}
                    onClick={this.onClickViewTeams}
                  >
                    <span className={itemFont}>View Other Teams</span>
                  </div>
                )}
              </div>
            )}
            {mostRemainTeamName !== '' && (
              <div className="w-1/2 px-2 mb-4  cols">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickGameStats}
                >
                  <span className={itemFont}>{`Game Stats For ${mostRemainTeamName}`}</span>
                  {remainTime && <span className={`${itemFont} text-center`}>{`${remainTime}`}</span>}
                </div>
              </div>
            )}
            {finishedGames.length > 0 && (
              <div className="w-1/2 px-2 mb-4  cols">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickViewReports}
                >
                  <span className={itemFont}>View Reports</span>
                </div>
              </div>
            )}

            {teams.length > 0 && (
              <div className="w-1/2 px-2 mb-4  cols">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickRecordStats}
                >
                  <span className={itemFont}>Record Game Stats</span>
                </div>
              </div>
            )}
            {teams.length > 0 && (
              <div className="w-1/2 px-2 mb-4  cols">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickRecordScrimmage}
                >
                  <span className={itemFont}>Record Scrimmage Data</span>
                </div>
              </div>
            )}
            <div className="w-1/2 px-2 mb-4  cols">
              <div
                className={itemClass}
                style={styles.item}
                onClick={() => this.onClickChangeStats()}
              >
                <span className={itemFont}>Change My Stats (Advanced)</span>
              </div>
            </div>
            {teams.length > 0 && (
              <div className="w-1/2 px-2 mb-4  cols">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickDelegateSubs}
                >
                  <span className={itemFont}>Delegate Subs Management</span>
                </div>
              </div>
            )}
            {finishedGames.length > 0 && (
              <div className="w-1/2 px-2 mb-4  cols">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickRateApp}
                >
                  <span className={itemFont}>Rate the Stats App</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <NavFooter/> */}
      </div>
    )
  }
}

MainApp.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  currentUser: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  currentGame: PropTypes.func.isRequired,
  changeEditingPlayers: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};


export default connect(
  mapStateToProps,
  {
    serviceRequest,
    currentUser,
    selectTeam,
    getAllTeams,
    currentGame,
    changeEditingPlayers
  }
)(MainApp)
