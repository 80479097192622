const formObj = {
    fname: "",
    lname: "",
    month: "",
    day: "",
    year: "",
    graduationYear: "",
    jerseyNumber: "",
    gender: "",
    parentNames: "",
    email: "",
    cellphone: "",
    parentEmail1: "",
    parentEmail2: "",
    address: "",
    city: "",
    state: "",
    zipcode: ""
  }
  
export const stateObj = {
    formControls: formObj,
    formErrors: formObj,
    formInvalid: false,
 }
  