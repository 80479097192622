import React from "react"
// import { connect } from "react-redux"
import PropTypes from "prop-types"

const normalStyle = "max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex bg-red-100 hover:bg-red-400 active:bg-red-600 focus:outline-none py-2 px-4 icon-new"
const disableStyle = "max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex focus:outline-none text-grey-300 py-2 px-4 icon-new "

export default class Button extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      label,
      width,
      height,
      backgroundColor,
      activeColor,
      margin,
      noBorder,
      noRounded,
      selected,
      buttonIcon,
      onClick,
      disabled
    } = this.props
    let style = null
    if (selected && activeColor) {
      style = {
        width,
        height: !!height && height,
        backgroundColor: activeColor,
        justifyContent: "center",
        alignItems: "center",
        borderWidth: !noBorder ? 1 : 0,
        marginBottom: margin ? margin : 0,
        borderColor: "#000",
        cursor: "pointer",
        borderRadius: noRounded ? "0rem" : "0.5rem",
      }
    } else {
      style = {
        width,
        height: !!height && height,
        justifyContent: "center",
        alignItems: "center",
        borderWidth: !noBorder ? 1 : 0,
        marginBottom: margin ? margin : 0,
        borderColor: "#000",
        cursor: "pointer",
        borderRadius: noRounded ? "0rem" : "0.5rem",
      }
    }

    if (backgroundColor) {
      style = {
        width,
        height: !!height && height,
        backgroundColor: backgroundColor,
        justifyContent: "center",
        alignItems: "center",
        borderWidth: !noBorder ? 1 : 0,
        marginBottom: margin ? margin : 0,
        borderColor: "#000",
        cursor: "pointer",
        borderRadius: noRounded ? "0rem" : "0.5rem",
      }
    }
    return (
      <button
        className={disabled ? disableStyle : normalStyle}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
        <span className={`font-bold text-base sm:text-sm md:text-md lg:text-lg xl:text-lg ${disabled ? 'text-gray-500' : ''}`}>{label}</span>
        {buttonIcon && (
          <img
            src={buttonIcon}
            style={{ width: "24px", height: "24px", marginLeft: 10 }}
          />
        )}
      </button>
    )
  }
}

Button.defaultProps = {
  disabled: false
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  activeColor: PropTypes.string,
  margin: PropTypes.string,
  noBorder: PropTypes.bool,
  noRounded: PropTypes.bool,
  selected: PropTypes.bool,
  buttonIcon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}
