/* eslint-disable no-console */
import firebase from 'firebase'

const gameDb = firebase.firestore()
const collectionName = "games"

export const saveGame = (path, value) => {
    gameDb.collection(collectionName).doc(path).set(value)
        .then(function(result) {
          
        })
        .catch(error => {
          
        })
}

export const updateGame = (path, value) => {
    gameDb.collection(collectionName).doc(path).update(value)
        .then(result => {
            
        })
        .catch(error => {
            console.log('Firebase Error+++++++++: ', error)
        })
}

export const deleteGame = (path) => {
    gameDb.collection(collectionName).doc(path).delete()
        .then(result => {
            console.log('Successfully deleted', result)
        })
        .catch(error => {
            console.log(error)
        })
}
