/* eslint-disable no-undef */
export const emailValidation = (email) => {
    const exp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let valid = true
    if (email) {
      valid = exp.test(email)
    }

    return valid
}

export const passwordValidation = (password) => {
  const exp = /(?=.*\d)(?=.*[a-z]).{8,}$/;

  let valid = true
  if (password) {
    valid = exp.test(password.toLowerCase())
  }
  return valid
}

export const confirmPwValidation = ( pw, confirmPw ) => {
  let valid = true;
  if (confirmPw) {
    valid = pw === confirmPw
  }
  return valid
}

export const fieldEmpty = (field) => {
  return field === ""
}

export const isMobileDevice = () => {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};