import * as ActionTypes from '../config/ActionTypes';

const options = {
    poslist1: [
        {id: "item-00", name: "Goal & Assist"},
        {id: "item-01", name: "PK Goal"},
        {id: "item-02", name: "Pass Completed"}
    ],
    poslist2: [
        {id: "item-10", name: "Tackle"},
        {id: "item-11", name: "Clearance"},
        {id: "item-12", name: "Blocks"}
    ],
    poslist3: [
        {id: "item-20", name: "Pass Taken (Intercepted)"},
        {id: "item-21", name: "Corner Kick"},
        {id: "item-22", name: "GK Save"}
    ],
    poslist4: [
        {id: "item-03", name: "Short Pass"},
        {id: "item-04", name: "Medium Pass"},
        {id: "item-05", name: "Long Pass"},
        {id: "item-06", name: "Side Pass"}
    ],
    poslist5: [
        {id: "item-13", name: "Goal Kick"},
        {id: "item-14", name: "GK Punt"},
        {id: "item-15", name: "GK Throw"},
        {id: "item-16", name: "Free Kick"}
    ],
    poslist6: [
        {id: "item-23", name: "Throw In"},
        {id: "item-24", name: "Cross"},
        {id: "item-25", name: "Dribble"},
        {id: "item-26", name: "Aerial Won"}
    ],
    neglist1: [
        {id: "item-00", name: "Shots on Goal Missed"},
        {id: "item-01", name: "Shots off Goal Missed"},
        {id: "item-02", name: "PK Missed"}
    ],
    neglist2: [
        {id: "item-10", name: "Backward Pass"},
        {id: "item-11", name: "Pass Missed (Intercepted)"},
        {id: "item-12", name: "Pass Missed (Out of Bounds)"}
    ],
    neglist3: [
        {id: "item-20", name: "Red Card"},
        {id: "item-21", name: "Yellow Card"},
        {id: "item-22", name: "Own Goal"}
    ],
    neglist4: [
        {id: "item-03", name: "Off Sides"},
        {id: "item-04", name: "Touch"}
    ],
    neglist5: [
        {id: "item-13", name: "Injury"}
    ],
    neglist6: [
        {id: "item-23", name: "Breakaway Allowed"}
    ]
}

const initialState = {
    athleteStats: [],
    gameTeams: null,
    gameStatsOptions: options,
    originStatsOptions: options,
    selectedGame: null,
    reportAthlete: null,
    reportGameKudos: null,
    playgroundAthletes: null
};

const GameReducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.GAME_TEAMS:
            return {
                ...state,
                gameTeams: action.payload
            }
        case ActionTypes.GAME_ATHLETES:
            return {
                ...state,
                gameAthletes: action.payload
            }
        case ActionTypes.GAME_STATS:
            return {
                ...state,
                gameStatsOptions: action.payload
            }
        case ActionTypes.GAME_TIME:
            return {
                ...state,
                gameTime: action.payload
            }
        case ActionTypes.REPORT_GAME:
            return {
                ...state,
                selectedGame: action.payload
            }
        case ActionTypes.REPORT_ATHLETE:
            return {
                ...state,
                reportAthlete: action.payload
            }
        case ActionTypes.REPORT_GAME_KUDOS:
            return {
                ...state,
                reportGameKudos: action.payload
            }
        default: 
            return state;
    }
};

export default GameReducer;