/* eslint-disable no-undef */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import firebase from 'firebase'
import styles from "./styles"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
import ConfirmDialog from "../../components/dialogs/confirmDialog"
import { formatTime, getCurrentUTCSeconds } from "../../utils/TimerUtils"
import { updateGame, deleteGame } from "../../utils/FirestoreUtils"
import { serviceRequest } from "../../../actions/User"
import { addAthleteStats } from "../../../actions/Game"
import ChangeStatsPage from "../../ChangeStats"
import RecordStatsReady from "./RecordReady"
import { isMobileDevice } from "../../../config/Global"
import Colors from "../../../config/Colors"

const gameDb = firebase.firestore()

const stopIcon = require("../../../images/icons/stop.png")
const playIcon = require("../../../images/icons/play.png")
const pauseIcon = require("../../../images/icons/pause.png")

const itemClass = "w-full xs:w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 ml-auto flex h-12 xs:h-12 sm:h-24 md:h-24 lg:h-24 bg-fulled-one items-center justify-center border cursor-pointer";

class RecordActivity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActivityScreen: true,
      modalVisible: false,
      selectedAction: "",
      selectedAthlete: {
        name: "",
        jersey: ""
      },
      isOtherTeam: false,
      modalTiming: 3,
      actionTime: "",
      goalAssistRegister: 0,
      gameId: "",
      gameTimeSet: false,
      defaultGameTime: 60,
      gameData: null,
      gameTeams: null,
      stopModalVisible: false,
      statChangeVisible: false,
      alertModalVisible: false,
      subsVisible: false,
      gameStarted: false,
      gameTimeFocused: false,
      gameTime: 0
    }
    this.onClickGridItem = this.onClickGridItem.bind(this)
    this.onClickChangeStats = this.onClickChangeStats.bind(this)

    this.onClickCancel = this.onClickCancel.bind(this)
    this.onClickShowModal = this.onClickShowModal.bind(this)
    this.onClickModalContinue = this.onClickModalContinue.bind(this)
    this.onClickModalCancel = this.onClickModalCancel.bind(this)

    this.onClickStartAndPause = this.onClickStartAndPause.bind(this)
    this.onClickStopGame = this.onClickStopGame.bind(this)
    this.onClickStopModalCancel = this.onClickStopModalCancel.bind(this)
    this.onClickSaveAndExit = this.onClickSaveAndExit.bind(this)

    this.onClickSubstitution = this.onClickSubstitution.bind(this)
  }

  onBackButtonEvent = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { history } = this.props
    history.push('/')
  }

  componentDidMount() {
    const { game, serviceRequest } = this.props
    const { selectedGame } = game
    window.onpopstate = this.onBackButtonEvent;
    this.setState({ gameId: selectedGame.game.id })
    const body = JSON.stringify({
      gameId: selectedGame.game.id
    })
    serviceRequest('game/getGameTeamsById', 'POST', body, false, this.getGameTeamsSuccess.bind(this));
  }

  componentWillUnmount() {
    if (isMobileDevice())
      this.revertScreen()
    if (this.unsubscribe) {
      this.unsubscribe()
    }
    
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  revertScreen = () => {
    var el = document.documentElement;

    // Supports most browsers and their versions.
    var requestMethod = el.exitFullscreen || el.webkitExitFullscreen || el.mozCancelFullScreen || el.msExitFullscreen;

    if (requestMethod) {

      // Native full screen.
      requestMethod.call(el);

    }

    window.screen.orientation.unlock();
    window.screen.orientation.lock('portrait');
  }

  getGameTeamsSuccess = (data) => {
    if (!data.error) {
      this.setState({
        gameTeams: data
      }, () => {
        const { gameId } = this.state
        this.gameSubscribe(gameId)
      })
    }
  }

  startConfirmTimer = () => {
    this.confirmTimer = setInterval(() => {
      const { modalTiming } = this.state
      if (modalTiming === 1) {
        this.onClickModalContinue()
      } else {
        this.setState({
          modalTiming: modalTiming - 1
        })
      }
    }, 1000)
  }

  getGameTime = () => {
    const { gameData } = this.state
    const gameTime = gameData.isPlaying ? getCurrentUTCSeconds() - gameData.startTime : gameData.pauseTime - gameData.startTime
    return gameTime <= gameData.gameDuration ? gameTime : gameData.gameDuration
  }

  gameSubscribe = (gameId) => {
    this.unsubscribe = gameDb.collection("games").doc(`game-${gameId}`)
      .onSnapshot(doc => {
        if (doc.exists) {
          const gameData = doc.data()
          const { gameTimeSet } = this.state
          console.log('Subscribe: ', gameTimeSet)
          this.setState({ gameData, gameStarted: gameData.startTime !== 0 }, () => {
            this.setState({
              gameTime: gameData.startTime === 0 ? 0 : this.getGameTime()
            })
            if (gameData.isPlaying) {
              if (this.timer) {
                clearInterval(this.timer)
              }
              this.startTimer()
            }
          })
          if (!gameTimeSet) {
            this.setState({
              gameTimeSet: gameData.startTime !== 0
            }, () => {
              const {gameTimeSet} = this.state
              if (!gameTimeSet) {
                this.setState({
                  alertModalVisible: true
                })
              }
            })
          }
        } else {
          this.setState({
            gameData: null
          }, () => {
            const { gameStarted } = this.state
            if (gameStarted) {
              this.props.history.push("/")
            }
          })
        }
      }, error => {
        console.log(error)
      })
  }

  updateFinishedGameSuccess = (data) => {
    const { history } = this.props
    const { gameData } = this.state
    if (!data.error) {
      if (this.timer) {
        clearInterval(this.timer)
      }
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      deleteGame(`game-${gameData.gameId}`)
      history.push("/")
    }
  }

  updateFinishedGameFail = (error) => {
    console.log(error);
  }

  onClickStopModalCancel = () => {
    const { gameData } = this.state
    this.setState({
      stopModalVisible: false
    }, () => {
      if (gameData && !gameData.isPlaying) {
        const status = {
          isPlaying: true,
          startTime:
            gameData.startTime +
            (getCurrentUTCSeconds() - gameData.pauseTime),
        }
        updateGame(`game-${gameData.gameId}`, status)
        this.startTimer()
      }
    })
  }

  onClickStopGame = () => {
    const { gameData } = this.state
    const { user } = this.props
    if (gameData) {
      const status = {
        isPlaying: false,
        controlUserId: user.userInfo.id
      }
      if (this.timer) {
        clearInterval(this.timer)
        updateGame(`game-${gameData.gameId}`, {
          pauseTime: getCurrentUTCSeconds(),
        })
      }
      updateGame(`game-${gameData.gameId}`, status)
    }
    this.setState({
      stopModalVisible: true
    })
  }

  calculatePlayedTimeForAthlete = (athlete) => {
    const { gameData, gameTime } = this.state
    if (athlete) {
      if (gameData.isScrimming) {
        return gameTime
      } else {
        if (athlete.joinTime === -1) {
          return 0
        } else {
          if (athlete.joinTime === 0) {
            if (athlete.outTime === -1) {
              return gameTime
            } else {
              return athlete.outTime
            }
          } else {
            if (athlete.outTime === -1) {
              return gameTime - athlete.joinTime
            } else {
              return athlete.outTime - athlete.joinTime
            }
          }
        }
      }
    } else {
      return 0
    }
  }

  onClickSaveAndExit = () => {
    const { serviceRequest } = this.props
    const { gameData, gameTime } = this.state
    
    // Calculate real stats athlete count
    const result = gameData.mainAthleteStats.reduce(function(hash) {
      return function (r, o) {
        if (!hash[o.athleteId]) {
          hash[o.athleteId] = []
          r.push(hash[o.athleteId])
        }
        hash[o.athleteId].push(o)
        return r
      }
    }(Object.create(null)), [])

    const allAthletes = [
      ...gameData.mainAthletes.posGK,
      ...gameData.mainAthletes.posLB,
      ...gameData.mainAthletes.posCB,
      ...gameData.mainAthletes.posRB,
      ...gameData.mainAthletes.posLM,
      ...gameData.mainAthletes.posCM,
      ...gameData.mainAthletes.posRM,
      ...gameData.mainAthletes.posLF,
      ...gameData.mainAthletes.posCF,
      ...gameData.mainAthletes.posRF,
      ...gameData.mainAthletes.bench
    ]

    const playedAthletes = []

    allAthletes.map(athlete => {
      const playedTime = gameData.isScrimming ? gameTime : this.calculatePlayedTimeForAthlete(athlete)
      if (playedTime !== 0) {
        const temp = {
          athleteId: athlete.athleteId,
          playedPos: athlete.pos,
          playedTime
        }
        playedAthletes.push(temp)
      }
    })


    const body = JSON.stringify({
      gameId: gameData.gameId,
      gameDuration: gameTime,
      mainGoals: gameData.mainGoals,
      oppGoals: gameData.oppGoals,
      mainStatsInfo: gameData.mainAthleteStats,
      oppStatsInfo: gameData.oppAthleteStats,
      statsAthleteCount: result.length,
      subsManagers: gameData.subsManagers,
      playedAthletes
    })
    serviceRequest("game/updateFinishedStatsGame", "POST", body, false, this.updateFinishedGameSuccess.bind(this))
  }

  onClickDeleteAndExit = () => {
    const { serviceRequest } = this.props
    const { gameData } = this.state
    
    const body = JSON.stringify({
      gameId: gameData.gameId
    })

    serviceRequest("game/deleteGameById", "POST", body, false, this.updateFinishedGameSuccess.bind(this))
  }

  startTimer = () => {
    this.timer = setInterval(() => {
      const { gameData } = this.state
      if (gameData && gameData.isPlaying) {
        this.setState({
          gameTime: this.getGameTime()
        })
      }
    }, 1000);
  }

  onClickStartAndPause = async () => {
    const { defaultGameTime, gameTimeSet } = this.state
    if (gameTimeSet) {
      const { gameData } = this.state
      const { user } = this.props
      if (gameData) {
        const status = {
          isPlaying: !gameData.isPlaying
        }
        
        updateGame(`game-${gameData.gameId}`, status)
        if (gameData.isPlaying) {
          clearInterval(this.timer)
          updateGame(`game-${gameData.gameId}`, { pauseTime: getCurrentUTCSeconds() })
        } else {
          updateGame(`game-${gameData.gameId}`, { startTime: gameData.startTime + (getCurrentUTCSeconds() - gameData.pauseTime) })
          this.startTimer()
          updateGame(`game-${gameData.gameId}`, { controlUserId: user.userInfo.id })
        }
      }
    } else {
      if (parseInt(defaultGameTime) !== 0 && defaultGameTime !== '') {
        this.setState({
          gameTimeSet: true
        }, () => {
          const { gameData, gameTimeSet } = this.state
          console.log('Started: ', gameTimeSet)
          const { user } = this.props
          if (gameData) {
            if (parseInt(defaultGameTime) !== 60) {
              updateGame(`game-${gameData.gameId}`, { gameDuration: parseInt(defaultGameTime) * 60 })
            }
            const status = {
              isPlaying: !gameData.isPlaying
            }
            
            updateGame(`game-${gameData.gameId}`, status)
            if (gameData.isPlaying) {
              clearInterval(this.timer)
            } else {
              updateGame(`game-${gameData.gameId}`, { startTime: getCurrentUTCSeconds() })
              this.startTimer()
              updateGame(`game-${gameData.gameId}`, { controlUserId: user.userInfo.id })
            }
          }
        })
      }
    }
  }

  onClickCancel() {
    const { isActivityScreen } = this.state
    this.setState({ isActivityScreen: !isActivityScreen, goalAssistRegister: 0 })
  }

  onClickShowModal = (athlete, isOtherTeam) => {
    this.setState({
      modalVisible: true,
      selectedAthlete: athlete,
      isOtherTeam
    })
    this.startConfirmTimer()
  }

  onClickModalContinue() {
    const {
      isActivityScreen,
      modalVisible,
      isOtherTeam,
      selectedAthlete,
      selectedAction,
      actionTime,
      goalAssistRegister,
      gameData,
      gameTeams
    } = this.state

    const mainAthleteStats = gameData.mainAthleteStats
    const oppAthleteStats = gameData.oppAthleteStats
    
    clearInterval(this.confirmTimer)
    if (selectedAction === "Goal & Assist") {
      const newStats = {
        athleteId: selectedAthlete.athleteId,
        name: selectedAthlete.name,
        jersey: selectedAthlete.jersey,
        action: goalAssistRegister === 0 ? "Goal" : "Assist",
        time: actionTime
      }
      if (isOtherTeam) {
        if (goalAssistRegister === 0) {
          oppAthleteStats.push(newStats)
          if (!gameTeams.oppTeam) {
            const nextStats = {
              ...newStats,
              action: 'Assist'
            }
            oppAthleteStats.push(nextStats)
          }
        } else {
          oppAthleteStats.push(newStats)
        }
      } else {
        mainAthleteStats.push(newStats)
      }

      const mainGoals = !isOtherTeam && goalAssistRegister === 0 ? gameData.mainGoals + 1 : gameData.mainGoals
      const oppGoals = isOtherTeam && goalAssistRegister === 0 ? gameData.oppGoals + 1 : gameData.oppGoals

      updateGame(`game-${gameData.gameId}`, {
        mainGoals,
        oppGoals,
        mainAthleteStats,
        oppAthleteStats
      })
  
      this.setState({
        isActivityScreen: (goalAssistRegister === 1 || isOtherTeam && !gameTeams.oppTeam) ? !isActivityScreen : isActivityScreen,
        modalVisible: !modalVisible,
        modalTiming: 3,
        goalAssistRegister: (!isOtherTeam && goalAssistRegister === 0 || isOtherTeam && gameTeams.oppTeam) ? goalAssistRegister + 1 : 0
      })
    } else {
      const newStats = {
        athleteId: selectedAthlete.athleteId,
        name: selectedAthlete.name,
        jersey: selectedAthlete.jersey,
        action: selectedAction,
        time: actionTime
      }
      if (isOtherTeam) {
        oppAthleteStats.push(newStats)
      } else {
        mainAthleteStats.push(newStats)
      }

      if (selectedAction === "PK Goal") {
        const mainGoals = !isOtherTeam ? gameData.mainGoals + 1 : gameData.mainGoals
        const oppGoals = isOtherTeam ? gameData.oppGoals + 1 : gameData.oppGoals

        updateGame(`game-${gameData.gameId}`, {
          mainGoals,
          oppGoals,
          mainAthleteStats,
          oppAthleteStats
        })
      } else if (selectedAction === "Own Goal") {
        const mainGoals = isOtherTeam ? gameData.mainGoals + 1 : gameData.mainGoals
        const oppGoals = !isOtherTeam ? gameData.oppGoals + 1 : gameData.oppGoals

        updateGame(`game-${gameData.gameId}`, {
          mainGoals,
          oppGoals,
          mainAthleteStats,
          oppAthleteStats
        })
      } else {
        updateGame(`game-${gameData.gameId}`, {
          mainAthleteStats,
          oppAthleteStats
        })
      }
    
      this.setState({
        isActivityScreen: !isActivityScreen,
        modalVisible: !modalVisible,
        modalTiming: 3
      })
    }
  }

  onClickModalCancel() {
    this.setState({
      modalVisible: false,
      modalTiming: 3,
      goalAssistRegister: 0
    })
    clearInterval(this.confirmTimer)
  }

  onClickGridItem = (action) => {
    const { isActivityScreen, gameData, gameTime } = this.state
    if (gameData.isPlaying) {
      this.setState({
        isActivityScreen: !isActivityScreen,
        selectedAction: action,
        actionTime: gameTime
      })
    }
  }

  onClickChangeStats() {
    const { statChangeVisible } = this.state
    this.setState({
      statChangeVisible: !statChangeVisible
    })
  }

  onClickSubstitution() {
    const { subsVisible } = this.state
    this.setState({
      subsVisible: !subsVisible
    })
  }

  onChangeGameTime = (e) => {
    const { value } = e.target
    this.setState({
      defaultGameTime: value
    })
  }

  onFocusGameTime = () => {
    const { gameTimeFocused } = this.state
    this.setState({
      gameTimeFocused: !gameTimeFocused
    })
  }

  render() {
    const {
      defaultGameTime,
      modalVisible,
      selectedAthlete,
      isActivityScreen,
      selectedAction,
      modalTiming,
      goalAssistRegister,
      actionTime,
      gameData,
      gameTeams,
      stopModalVisible,
      statChangeVisible,
      alertModalVisible,
      gameTimeSet,
      subsVisible,
      gameTimeFocused,
      gameTime
    } = this.state
    
    const time = gameData !== null ? formatTime(gameData.gameDuration - gameTime) : formatTime(3600)
    const { game, user } = this.props
    const { gameStatsOptions } = game

    if (gameData && !gameData.isPlaying && gameTime !== 0) {
      if (this.timer) {
        clearInterval(this.timer)
      }
    }

    let playingAthletes = []
    let playingPos = ''
    if (gameData) {
      playingAthletes = [
        ...gameData.mainAthletes.posGK,
        ...gameData.mainAthletes.posLB,
        ...gameData.mainAthletes.posCB,
        ...gameData.mainAthletes.posRB,
        ...gameData.mainAthletes.posLM,
        ...gameData.mainAthletes.posCM,
        ...gameData.mainAthletes.posRM,
        ...gameData.mainAthletes.posLF,
        ...gameData.mainAthletes.posCF,
        ...gameData.mainAthletes.posRF
      ]

      const playingCount = playingAthletes.length
      if (playingCount > 0) {
        playingAthletes.map((player, index) => {
          if (index < playingCount - 1) {
            if (player.jersey) {
              playingPos = playingPos + `${player.jersey}, `
            }
          } else {
            playingPos = playingPos + `${player.jersey}`
          }
        })

        playingAthletes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      }
    }
    
    const positiveOptions = [
      ...gameStatsOptions.poslist1,
      ...gameStatsOptions.poslist2,
      ...gameStatsOptions.poslist3
    ]
    const negativeOptions = [
      ...gameStatsOptions.neglist1,
      ...gameStatsOptions.neglist2,
      ...gameStatsOptions.neglist3
    ]

    const gameStats = gameData && gameData.mainAthleteStats && gameData.oppAthleteStats ? [
      ...gameData.mainAthleteStats,
      ...gameData.oppAthleteStats
    ] : []

    gameStats.sort((a, b) => {
      return a.time - b.time
    })

    return (
      <div style={styles.container}>
        <NavHeader />
                <span className="pt-4 w-full block"></span>
        {statChangeVisible ? (
          <ChangeStatsPage
            onClickSave={this.onClickChangeStats}
          />
        ) : (
          subsVisible ? (
            <RecordStatsReady playingGame history={this.props.history} onGoBack={() => this.setState({subsVisible: false})} />
          ) : (
            <div>
              <Spinner show={user.loading} />
              {isActivityScreen ? (
                <div>
                  <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
                    <span className="font-light w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                      Record Game Activities
                    </span>
                  </div>
                  <div className="max-w-2xl xl:max-w-6xl lg:max-w-5xl md:max-w-4xl sm:max-w-3xl mx-auto flex p-2 ">
                    <div className="w-full flex text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                    <div className="text-center " style={{width: "35%"}}>
                      <div className="w-full h-10 flex items-center justify-center font-light border-b border-blue-900"><span>Positive Action</span></div>
                      <div className="flex flex-wrap">
                        {positiveOptions.map((item, index) => (
                          <div
                            className={itemClass}
                            key={`item-${index}`}
                            onClick={() => this.onClickGridItem(item.name)}
                          >
                            <span className="font-light text-center text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md">{item.name}</span>
                          </div>
                        ))}
                        
                        <div className="w-full mx-auto flex p-2 mt-2 bg-green rounded-lg items-right justify-end">
                          <span 
                            className="h-full font-light text-right text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md cursor-pointer"
                            onClick={this.onClickChangeStats}
                          >
                            Change Stats Options (Advanced)
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="text-center " style={{width: "30%"}}>
                      <div className="w-full h-10 flex items-center justify-center font-light"><span>Live Update</span></div>
                      <div className="w-full">
                        <input
                          className={`text-center text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md`}
                          name="gameTime"
                          pattern="\d*"
                          placeholder="60 : 00"
                          maxLength="3"
                          value={gameTimeSet ? time : (gameTimeFocused ? defaultGameTime : formatTime(defaultGameTime * 60))}
                          disabled={gameTimeSet}
                          onChange={this.onChangeGameTime}
                          onFocus={this.onFocusGameTime}
                          onBlur={this.onFocusGameTime}
                        />
                        <div className="flex justify-between px-2">
                          <span className="text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md">{gameTeams !== null && gameTeams.mainTeam ? gameTeams.mainTeam.team_name : ""}</span>
                          <span className="text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md">{gameData ? gameData.mainGoals : 0}</span>
                        </div>  
                        <div className="flex justify-between px-2">
                          <span className="text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md">{gameTeams !== null && gameTeams.oppTeam ? gameTeams.oppTeam.team_name : (gameData && gameData.isScrimming ? 'Opponent (Scrimmage)' : 'Opponent')}</span>
                          <span className="text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md">{gameData ? gameData.oppGoals : 0}</span>
                        </div>
                        <div className="flex justify-between px-2 xs:px-2 sm:px-4 md:px-8 lg:px-8 xl:px-8 py-2">
                          <img 
                            src={gameData && gameData.isPlaying ? pauseIcon : playIcon}
                            className="w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6 cursor-pointer"
                            onClick={this.onClickStartAndPause} 
                          />
                          <img 
                            src={stopIcon} 
                            className="w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6 cursor-pointer" 
                            onClick={gameData && gameTime !== 0 ? this.onClickStopGame : this.onClickDeleteAndExit}
                          />
                        </div>
                        <div className={` border-dotted text-left mx-1 overflow-y-scroll ${playingAthletes.length > 0 ? 'h-32' : 'h-32'}`}>
                          {gameStats.reverse().map((item, index) => (
                            <div key={`index-${index}`} className="flex items-center  border-dotted">
                              <span className="text-xs sm:text-xs md:text-xs lg:text-sm xl:text-md">
                              {`#${item.jersey} ${item.name}, ${item.action} @ ${formatTime(item.time)}`}
                            </span>
                            </div>
                          ))}
                        </div>
                        {gameData && (
                          <div className="flex flex-wrap mt-2 mx-2 items-center">
                            {!gameData.isScrimming && (
                              <div className="text-left w-full h-full xs:w-full sm:w-1/3 md:w-1/3 lg:w-1/3">
                                <span className="font-semibold text-xs sm:text-xs md:text-xs lg:text-lg xl:text-lg">
                                  {`In Game [${playingAthletes.length}]:`} <br />
                                </span>
                                <span className="text-xs">
                                  {playingPos}
                                </span>
                              </div>
                            )}
                            <div className={!gameData.isScrimming ? 'w-full mt-2 xs:w-full sm:w-2/3 md:w-2/3 lg:w-2/3' : 'w-full mt-2 xs:w-full sm:w-full md:w-full lg:w-full'}>
                              {!gameData.isScrimming && (
                                <div
                                  className="w-full mt-2 flex items-center"
                                  onClick={this.onClickSubstitution}
                                >
                                  <span className="w-full truncate font-semibold text-center cursor-pointer  py-2 btn-link">Substitution</span>
                                </div>
                              )}
                              <div
                                className="w-full mt-2 mb-2 flex items-center"
                                onClick={gameTime === 0 ? this.onClickDeleteAndExit : gameTime === gameData.gameDuration ? this.onClickSaveAndExit : null}
                              >
                                {(gameTime === 0 || gameTime === gameData.gameDuration) && <span className={`w-full font-semibold text-center  py-2 cursor-pointer`}>{gameTime === 0 ? 'Cancel Game' : 'End Game'}</span>}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-center " style={{width: "35%"}}>
                      <div className="w-full h-10 flex items-center justify-center font-light border-b border-blue-900"><span>Neutral or Negative Action</span></div>
                      <div className="flex flex-wrap">
                      {negativeOptions.map((item, index) => (
                          <div
                            className={itemClass}
                            key={`item-${index}`}
                            onClick={() => this.onClickGridItem(item.name)}
                          >
                            <span className="font-light text-center text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md">{item.name}</span>
                          </div>
                        ))}

                        <div className="w-full mx-auto flex p-2 mt-2 bg-green rounded-lg items-right justify-end">
                          <span 
                            className="h-full font-light text-right text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md cursor-pointer"
                            onClick={this.onClickChangeStats}
                          >
                            Change Stats Options (Advanced)
                          </span>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex flex-col p-4">
                    <div className="w-full text-center mx-auto flex p-2 mt-4 bg-green rounded-lg">
                    <span className="w-full font-semibold text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                      {selectedAction === "Goal & Assist" ? (goalAssistRegister === 0 ? 'Goal: Which Athlete?' : 'Assist: Which Athlete?') : `${selectedAction}: Which Athlete?`}
                    </span>
                    </div>
                    <div className="text-center w-full border-collapse flex flex-wrap bg-green">
                      {playingAthletes.length > 0 ? playingAthletes.map((item, index) => (
                        <div
                          className="w-1/6 min-h-20 px-1 break-words bg-green-200 border border-white cursor-pointer flex flex-col justify-center  text-xxs sm:text-xxs md:text-xs lg:text-sm xl:text-md"
                          key={`key-${index}`}
                          onClick={() => this.onClickShowModal(item, false)}
                        >
                          <span>{item.name}</span>
                          <span>{item.jersey ? `#${item.jersey}` : ''}</span>
                        </div>
                      )) : gameData && gameData.mainAthletes.bench.map((item, index) => (
                        <div
                          className="w-1/6 min-h-20 px-1 break-words bg-gray-500 border border-white cursor-pointer flex flex-col justify-center text-xxs sm:text-xxs md:text-xs lg:text-sm xl:text-md"
                          key={`key-${index}`}
                          onClick={() => this.onClickShowModal(item, false)}
                        >
                          <span>{item.name}</span>
                          <span>{item.jersey ? `#${item.jersey}` : ''}</span>
                        </div>
                      ))}
                      {gameData && gameData.oppAthletes.length > 0 ? gameData.oppAthletes.map((item, index) => (
                        <div
                          className="w-1/6 min-h-20 px-1 break-words bg-red-500 border border-white cursor-pointer flex flex-col justify-center text-xxs sm:text-xxs md:text-xs lg:text-sm xl:text-md"
                          key={`key-${index}`}
                          onClick={() => this.onClickShowModal(item, true)}
                        >
                          <span>{item.name}</span>
                          <span>{item.jersey ? `#${item.jersey}` : ''}</span>
                        </div>
                      )) : (
                        <div
                          className="w-1/6 min-h-20 px-1 break-words bg-red-500 border border-white cursor-pointer flex flex-col justify-center text-xxs sm:text-xxs md:text-xs lg:text-sm xl:text-md"
                          onClick={() => this.onClickShowModal({athleteId: '', name: 'Opponent', jersey: '', id: ''}, true)}
                        >
                          <span>Other Team</span>
                        </div>
                      )}
                      {playingAthletes.length > 0 && gameData && gameData.mainAthletes.bench.map((item, index) => (
                        <div
                          className="w-1/6 min-h-20 px-1 break-words bg-gray-500 border border-white cursor-pointer flex flex-col justify-center text-xxs sm:text-xxs md:text-xs lg:text-sm xl:text-md"
                          key={`key-${index}`}
                          onClick={() => this.onClickShowModal(item, false)}
                        >
                          <span>{item.name}</span>
                          <span>{item.jersey ? `#${item.jersey}` : ''}</span>
                        </div>
                      ))}
                      {selectedAction === "Goal & Assist" && goalAssistRegister === 1 && (
                        <div
                          className="w-1/6 min-h-20 px-1 break-words bg-pink-200 border border-white cursor-pointer flex flex-col justify-center text-xxs sm:text-xxs md:text-xs lg:text-sm xl:text-md"
                          onClick={() => {
                            this.setState({
                              isActivityScreen: true,
                              goalAssistRegister: 0
                            })
                          }}
                        >
                          <span>{"Not Assisted"}</span>
                        </div>
                      )}
                    </div>
                    <div className="w-full mx-auto flex p-2 mt-4 bg-green rounded-lg">
                      <Button
                        label="Cancel"
                        width="100%"
                        backgroundColor={Colors.white}
                        margin="0.5rem"
                        onClick={this.onClickCancel}
                      />
                    </div>
                  </div>
                  <ConfirmDialog show={modalVisible} width={"500px"}>
                    <div className="block text-center w-full">
                      <span className="font-semibold">
                        {`${selectedAction} @ ${formatTime(actionTime)}`}
                        <br />
                      </span>
                      <span className="font-semibold truncate">{selectedAthlete.jersey ? `${selectedAthlete.name} #${selectedAthlete.jersey}` : `${selectedAthlete.name}`}</span>
                    </div>
                    <div className="flex mt-6 w-full">
                      <Button
                        label="Continue"
                        width="40%"
                        backgroundColor={Colors.white}
                        margin="0.25rem"
                        onClick={this.onClickModalContinue}
                      />
                      <span className="px-2" />
                      <Button
                        label="Mistake/Delete"
                        width="40%"
                        backgroundColor={Colors.white}
                        margin="0.25rem"
                        onClick={this.onClickModalCancel}
                      />
                    </div>
                    <div className="w-full flex justify-center">
                      <span className="text-xs">{`Auto continue in ${modalTiming}...`}</span>
                    </div>
                  </ConfirmDialog>
                </div>
              )}
              <ConfirmDialog show={stopModalVisible} width={"500px"}>
                <div className="block text-center w-full">
                  <span className="font-semibold">
                    Would you like to exit this match?
                  </span>
                </div>
                <div className="flex mt-6 w-full">
                  <Button
                    label="Save & Exit Match"
                    width="40%"
                    backgroundColor={Colors.white}
                    margin="0.25rem"
                    onClick={this.onClickSaveAndExit}
                  />
                  <span className="px-2" />
                  <Button
                    label="Delete & Exit Match"
                    width="40%"
                    backgroundColor={Colors.white}
                    margin="0.25rem"
                    onClick={this.onClickDeleteAndExit}
                  />
                  <span className="px-2" />
                  <Button
                    label="Resume Game"
                    width="40%"
                    backgroundColor={Colors.white}
                    margin="0.25rem"
                    onClick={this.onClickStopModalCancel}
                  />
                </div>
              </ConfirmDialog>
              <ConfirmDialog show={alertModalVisible && !gameTimeSet} width={"500px"} position="top-center">
                <div className="block text-center w-full">
                  <span className="font-semibold">
                    Set your game clock (default 60 minutes), then click the start button to begin! When game is over, hit the stop button.
                  </span>
                </div>
                <div className="flex mt-6 w-full">
                  <Button
                    label="Close"
                    width="80%"
                    backgroundColor={Colors.white}
                    margin="0.25rem"
                    onClick={() => {
                      this.setState({
                        alertModalVisible: false,
                      })
                    }}
                  />
                </div>
              </ConfirmDialog>
            </div>
          )
        )}
        <span className= "pb-24 w-full block"> </span> 
        {/* <NavFooter/> */}
      </div>
    )
  }
}

RecordActivity.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  game: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  addAthleteStats: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    addAthleteStats
  }
)(RecordActivity)