import React, { Component } from "react"
import { Draggable } from "react-beautiful-dnd"
import PropTypes from 'prop-types';

import NaturalDragAnimation from "./animation"
import Colors from "../../../../config/Colors"


const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "#fdd4e2" : '#cbe8e8',
  // styles we need to apply on draggables
  ...draggableStyle,
})

class GridItem extends Component {
  render() {
    const { item, key, index, ...props } = this.props

    return (
      <Draggable key={key} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <NaturalDragAnimation
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            snapshot={snapshot}
            {...props}
          >
            {(style) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={style}
                className="w-full flex h-20 xs:h-16 sm:h-20 md:h-20 lg:h-20 xl:h-20 px-2 justify-center items-center bg-fulled-two"
              >
                <span className="font-medium text-black">{item.name}</span>
              </div>
            )}
          </NaturalDragAnimation>
        )}
      </Draggable>
    )
  }
}

GridItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  key: PropTypes.string,
  index: PropTypes.number
}

export default GridItem
