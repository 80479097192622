import React from "react"
// import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Multiselect } from "multiselect-react-dropdown"
import { posOptions } from '../../../config/Constants'

export default class MultiDropDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  convertStringToArray = (value) => {
    const ret = []
    if (value !== '') {
      const arr = value.split(', ')
      arr.map(item => {
        const findItem = posOptions.find(pos => pos.abbr === item)
        ret.push(findItem)
      })
    }
    return ret
  }

  render() {
    const { label, className, options, placeholder, display, error, onSelect, value, index, } = this.props

    return (
      <div className={className}>
        {label && (
          <label className="block tracking-wide text-grey-darker text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-semibold mb-2">
            {label}
          </label>
        )}
        <div className="w-full bg-grey-lighter border-grey-lighter text-grey-darker text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
          <Multiselect 
            options={options}
            placeholder={placeholder}
            displayValue={display}
            closeIcon="cancel"
            selectedValues={this.convertStringToArray(value)}
            onSelect={(list) => onSelect(list, index)}
            style={{
              multiselectContainer: {
                border: '1px solid',
                padding: '0px',
                width: '40px'
              }
            }}
          />
        </div>
        {!!error && <p className="w-full block text-left text-red-500 text-xs">{error}</p>}
      </div>
    )
  }
}

MultiDropDown.defaultProps = {
  onSelect: null,
  onRemove: null,
  label: '',
  value: '',
  index: 0,
}

MultiDropDown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number,
  className: PropTypes.string,
  options: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.string,
  display: PropTypes.string,
  error: PropTypes.string,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func
}
