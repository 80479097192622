// eslint-disable-next-line no-unused-vars
import React from "react"
// eslint-disable-next-line no-unused-vars
import { Route, Switch, BrowserRouter } from "react-router-dom"
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

// Inport root app
import Root from "./containers/Root"
import { store, persistor } from '../store';

// const store = configureStore();


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} >
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Root} />
          </Switch>
        </BrowserRouter>
      </PersistGate>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore}/>
    </Provider>
  )
}

export default App
