import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest, getOtherTeams } from "../../../actions/User"

const checkedIcon = require("../../../images/icons/check.png")
const unCheckedIcon = require("../../../images/icons/square_empty.png")

class AddOtherTeam extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teams: [],
    }
    this.onClickChangeStatus = this.onClickChangeStatus.bind(this)
    this.onClickReturn = this.onClickReturn.bind(this)
    this.onClickAddTeams = this.onClickAddTeams.bind(this)
  }

  componentDidMount = async() => {
    const { user } = this.props
    const { unVisibleTeams } = user

    const teams = []
    unVisibleTeams.map(item => {
      const team = {
        team: item,
        status: this.isAddedTeam(item.team.id)
      }
      teams.push(team)
    })
    
    this.setState({
      teams
    })
  }

  isAddedTeam = (teamId) => {
    let ret = false
    const { user } = this.props
    const { addedTeams } = user
    addedTeams.map((item) => {
      if (item.team.id === teamId) {
        ret = true
      }
    })
    return ret
  }

  onClickReturn() {
    this.props.history.go(-1)
  }

  onClickAddTeams = () => {
    const { teams } = this.state
    const { getOtherTeams, history } = this.props;
    const addedTeams = []
    teams.map(team => {
      if (team.status) {
        addedTeams.push(team.team)
      }
    })
    getOtherTeams(addedTeams)
    history.goBack()
  }

  onClickChangeStatus = (index) => {
    const { teams } = this.state
    const tmpArr = []
    teams.map((team, i) => {
      let item = {}
      if (i === index) {
        item = {
          ...team,
          status: !team.status
        }
      } else {
        item = team
      }
      tmpArr.push(item)
    })
    
    this.setState({ teams: tmpArr })
  }

  render() {
    const { teams } = this.state
    const { user } = this.props
    if (!user.regToken) {
      return <Redirect to="/login" />
    }
    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            Select Another Team
          </span>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <table className="text-center w-full border-collapse bg-white">
            <thead className="bg-grey-500">
              <tr>
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900">
                  No
                </th>
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900">
                  Team Name
                </th>
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border border-blue-900">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {teams.length !== 0 &&
                teams.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="border border-blue-900 w-6">
                        <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{index + 1}</span>
                      </td>
                      <td className="border border-blue-900">
                        <span  className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{`${item.team.team.team_name} (${item.team.memberCount})`}</span>
                      </td>
                      <td className="px-2 py-2 border border-blue-900 w-6">
                        <div className="w-full h-full flex justify-center items-center">
                          <img
                            className="cursor-cursor-pointer w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-"
                            src={item.status ? checkedIcon : unCheckedIcon}
                            onClick={() => this.onClickChangeStatus(index)}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-10 bg-green rounded-lg">
          <Button
            label="Return"
            width="80%"
            backgroundColor="#fff"
            onClick={this.onClickReturn}
          />
          <span className="px-6" />
          <Button
            label="Add Teams"
            width="80%"
            backgroundColor="#fff"
            onClick={this.onClickAddTeams}
          />
        </div>
      </div>
    )
  }
}


AddOtherTeam.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  getOtherTeams: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    getOtherTeams
  }
)(AddOtherTeam)