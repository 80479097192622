import React from "react"
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { ToastsStore } from "react-toasts"
import { stateObj } from "./model"
import styles from "./styles"
import Button from "../../components/buttons/button"
import DropDown from "../../components/dropdown/dropdown"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest } from "../../../actions/User"
import { fieldEmpty, emailValidation } from '../../../config/Global'
import { ToastMessages } from "../../../config/ToastMessages"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
// const plusIcon = require("../../../images/icons/plus.png")
// const cancelIcon = require("../../../images/icons/cancel.png")

class DelegateSubs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      subManagers: [],
      anotherManagers: [],
      allTeams: []
    }

    this.onClickGoHome = this.onClickGoHome.bind(this)
    this.onClickAuthorize = this.onClickAuthorize.bind(this)

    this.onClickAddRow = this.onClickAddRow.bind(this)
    this.onClickRemoveRow = this.onClickRemoveRow.bind(this)
    this.onChangeText = this.onChangeText.bind(this)
  }

  componentDidMount = async () => {
    const { serviceRequest, user } = this.props;

    this.onClickAddRow()
    this.onClickAddRow()
    
    const body = JSON.stringify({
      userId: user.userInfo.id,
      getSubs: true
    })
    await serviceRequest('athlete/getAllTeamsAndAthletesByUserId', 'POST', body, false, this.requestSuccess.bind(this));
  }

  requestSuccess = (data) => {
    if (!data.error) {
      data.teams.sort((a, b) => {
        return new Date(b.team.updated_at) - new Date(a.team.updated_at)
      })
      
      this.setState({
        allTeams: data.teams
      })
    }
  }

  sendInviteSuccess = (data) => {
    ToastsStore.success(ToastMessages.INVITE_SUCCESS)
    console.log('-------: ', data)
  }

  sendInviteFail = () => {
    ToastsStore.error(ToastMessages.INVITE_FAIL)
  }

  onClickAuthorize = () => {
    const { serviceRequest, user } = this.props
    const { anotherManagers, allTeams, stateObj } = this.state
    const sendingArr = []
    anotherManagers.map((item) => {
      if (!fieldEmpty(item.newName) && !fieldEmpty(item.newEmail) && emailValidation(item.newEmail)) {
        const fullName = item.newName.match(/[a-zA-Z]+/g)
        const fName = fullName[0] ? fullName[0].charAt(0).toUpperCase() + fullName[0].slice(1) : ""
        const lName = fullName[1] ? fullName[1].charAt(0).toUpperCase() + fullName[1].slice(1) : ""
        const manager = {
          fullName: lName !== "" ? `${fName} ${lName}` : fName,
          email: item.newEmail
        }
        sendingArr.push(manager)
      }
    })
    if (sendingArr.length !== 0) {
      const selectedTeam = allTeams[stateObj.formControls.teamName];
      const body = JSON.stringify({
        senderId: user.userInfo.id,
        senderEmail: user.email,
        senderName: `${user.userInfo.first_name} ${user.userInfo.last_name}`,
        teamId: selectedTeam.team.id,
        teamName: selectedTeam.team.team_name,
        managers: sendingArr
      })
      
      serviceRequest("team/sendInviteToSubManagers", "POST", body, false, this.sendInviteSuccess, this.sendInviteFail)
    }
  }

  onClickGoHome() {
    this.props.history.push("/")
  }

  onClickAddRow() {
    const { anotherManagers, stateObj } = this.state
    const tempArray = anotherManagers
    const newItem = {
      newName: stateObj.rowControls.subsName,
      newEmail: stateObj.rowControls.subsEmail,
    }
    tempArray.push(newItem)
    this.setState({
      anotherManagers: tempArray,
    })
  }

  remove_item(arr, index) {
    if (arr.length === 0) return
    arr.splice(index, 1)
    return arr
  }

  onClickRemoveRow = (index) => {
    const { anotherManagers } = this.state
    this.setState({
      anotherManagers: this.remove_item(anotherManagers, index),
    })
  }

  onChangeText = (e, index) => {
    const { target } = e
    const { name, value } = target
    if (name === 'teamName') {
      const { stateObj } = this.state
      this.setState({
        stateObj: {
          ...stateObj,
          formControls: {
            [name]: value
          }
        }
      })
    } else {
      const { anotherManagers } = this.state
      anotherManagers.map((item, i) => {
        if (i === index) {
          item[`${name}`] = value
        }
      })
      this.setState({
        anotherManagers
      }, () => {
        const {anotherManagers} = this.state
        if (index === anotherManagers.length - 2 && anotherManagers[index].newName !== "" && anotherManagers[index].newEmail !== "") {
          this.onClickAddRow()
        }
      })
    }
  }

  render() {
    const { anotherManagers, stateObj, allTeams } = this.state
    const { user } = this.props
    if (!user.regToken) {
      return <Redirect to="/login" />
    }

    const subEmails = allTeams.length !== 0 && stateObj.formControls.teamName ? allTeams[stateObj.formControls.teamName].subEmails : []

    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <NavHeader/>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 pt-8 bg-green rounded-lg">
            <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                Get Help Managing Athlete Substitutions!
            </span>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-4 bg-green rounded-lg">
          <span className="italic text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
            Grant access to parents, coaches and others to help you manage game
            substitutions. Keep your athletes and families happy by managing game
            (and rest!) time appropriately for your athletes.
          </span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg mt-5">
          <DropDown
            placeholder="Select Team"
            className="w-full"
            options={allTeams.map(team => team.team.team_name)}
            name="teamName"
            selectedIndex={stateObj.formControls.teamName}
            error={stateObj.formErrors.teamName}
            onChange={this.onChangeText}
          />
        </div>

        {stateObj.formControls.teamName && (
          <div>
            <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex px-2 mt-4 bg-green rounded-lg">
              <span className="italic text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                Which coach, parent, or manager can help manage subs?
              </span>
            </div>
            <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex px-2 bg-green rounded-lg">
              <table className="text-left w-full border-collapse bg-white">
                <thead className="bg-grey-500">
                  <tr>
                    <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg text-grey-dark border border-blue-900">
                      Manager Name
                    </th>
                    <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg text-grey-dark border border-blue-900">
                      Manager Email
                    </th>
                    {/* <th className="py-4 px-1 bg-grey-lightest w-20 border border-blue-900">
                      <div className="w-full h-full flex justify-center items-center">
                        <img
                          className="cursor-pointer w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6"
                          src={plusIcon}
                          onClick={this.onClickAddRow}
                        />
                      </div>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {anotherManagers.length !== 0 &&
                    anotherManagers.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="border border-blue-900">
                            <input
                              className="w-full capitalize h-8 px-2 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg"
                              name="newName"
                              type="text"
                              value={item.newName}
                              disabled={index === anotherManagers.length - 1}
                              onChange={(e) => this.onChangeText(e, index)}
                            />
                          </td>
                          <td className="border border-blue-900">
                            <input
                              className="w-full h-8 px-2 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg"
                              name="newEmail"
                              type="email"
                              value={item.newEmail}
                              disabled={index === anotherManagers.length - 1}
                              onChange={(e) => this.onChangeText(e, index)}
                            />
                          </td>
                          {/* <td className="px-1 border border-blue-900 w-20">
                            <div className="w-full h-full flex justify-center items-center">
                              <img
                                className="cursor-pointer w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6"
                                src={cancelIcon}
                                onClick={() => this.onClickRemoveRow(index)}
                              />
                            </div>
                          </td> */}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex px-2 bg-green rounded-lg">
              <span className="font-bold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
                Existing Sub Managers:{" "}
                {subEmails.map((item, index) => {
                  if (index !== subEmails.length - 1) {
                    return <span className="font-normal" key={index}>{`${item}, `}</span>
                  } else {
                    return <span className="font-normal" key={index}>{`${item}`}</span>
                  }
                })}
              </span>
            </div>
          </div>
        )}

        {stateObj.formControls.teamName ? (
          <div className="justify-center max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg mt-6 pb-4">
          <span  className="bg-purple">
            <Button
              label="Authorize"
              width="100%"
              backgroundColor="#ffffff"
              margin={"20px"}
              onClick={this.onClickAuthorize}
            />
            </span>
            <span style={{ width: "10%" }}></span>
            <span className="bg-pink">
            <Button
              label="Go Home"
              width="100%"
              backgroundColor="#d00a00"
              margin={"20px"}
              onClick={this.onClickGoHome}
            />
            </span>
          </div>
        ) : (
          <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg mt-6 pb-4 bg-outline1 w-1/5">
            <Button
              label="Go Home"
              width="100%"
              backgroundColor="#ffffff"
              margin={"20px"}
              onClick={this.onClickGoHome}
            />
          </div>
        )}

        {/* <NavFooter/> */}
      </div>
    )
  }
}

DelegateSubs.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps,
  {
    serviceRequest
  }
)(DelegateSubs)