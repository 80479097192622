import ViewReports from "./components/ViewReports"
import ViewGames from "./components/ViewGames"
import ViewAthlete from "./components/ViewAthlete"
import SendKudos from "./components/SendKudos"
import ViewKudos from "./components/ViewKudos"

export const ViewGamesPage = ViewGames
export const ViewReportsPage = ViewReports
export const ViewAthletePage = ViewAthlete
export const SendKudosPage = SendKudos
export const ViewKudosPage = ViewKudos
