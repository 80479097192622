import GameStats from "./components/GameStats"
import RecordGame from "./components/RecordGame"
import RecordScrimmage from "./components/RecordScrimmage"
import RecordReady from "./components/RecordReady"
import RecordActivity from "./components/RecordActivity"

export const GameStatsPage = GameStats
export const RecordGamePage = RecordGame
export const RecordScrimmagePage = RecordScrimmage
export const RecordReadyPage = RecordReady
export const RecordActivityPage = RecordActivity
