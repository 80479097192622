/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from "react-dom"
import "./styles/tailwind.css"
import App from "./app/app"
import * as serviceWorker from "./serviceWorker"

// eslint-disable-next-line react/react-in-jsx-scope
ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
