import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { stateObj } from "./model"
import styles from "./styles"
import Button from "../../components/buttons/button"
import LabelInput from "../../components/labeledinput/labeledinput"
import DropDown from "../../components/dropdown/dropdown"
import MultiDropDown from "../../components/dropdown/multidropdown"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest } from '../../../actions/User'
import { fieldEmpty, emailValidation } from '../../../config/Global'
import { posOptions, homeStates } from '../../../config/Constants';

const days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
]
const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
]

const graduationOptions = [
  'Already Graduated HS',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030+',
]

const redirectUrl = "https://riserunsports.com/athlete-confirmation"

class AddAthleteInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      yearOptions: [],
      loading: false,
      teamName: "",
      teamId: "",
      managerNames: "",
      season: "",
      preferredPos: [],
      posError: "",
      submited: false,
      homeStatesOptions: []
    }

    this.getTeamSuccess = this.getTeamSuccess.bind(this)
    this.getTeamFail = this.getTeamFail.bind(this)

    this.onChangeText = this.onChangeText.bind(this)
    this.onSelectPos = this.onSelectPos.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.submitSuccess = this.submitSuccess.bind(this)
    this.submitFail = this.submitFail.bind(this)

    this.onClickGoHome = this.onClickGoHome.bind(this)
  }

  componentDidMount() {
    const homeStatesOptions = []
    homeStates.map(item => {
      homeStatesOptions.push(item.name)
    })
    const yearOptions = this.makeYearOptions(1900, 2019)
    this.setState({
      yearOptions: yearOptions.reverse(),
      homeStatesOptions,
      loading: true
    })
    const { match } = this.props;
    
    const { serviceRequest } = this.props;
    const body = JSON.stringify({
      id: match.params.id
    })
    
    serviceRequest('team/getTeamWithManagerNamesById', 'POST', body, false, this.getTeamSuccess.bind(this), this.getTeamFail.bind(this));
  }

  onClickGoHome() {
    this.props.history.push("/")
  }

  getTeamSuccess = (data) => {
    console.log(data)
    if (!data.error) {
      const managerNames = data.managers
      const subManagerNames = data.submanagers
      const allManagerNames = subManagerNames === "" ? managerNames : `${managerNames}, ${subManagerNames}`
      this.setState({
        loading: false,
        teamName: data.team.team_name,
        teamId: data.team.id,
        managerNames: allManagerNames,
        season: data.team.season
      })
    }
  }

  getTeamFail = (error) => {
    if (error) {
      this.setState({
        loading: false
      })
    }
  }

  makeYearOptions = (startRange, endRange) => {
    const ret = []
    for (let i = startRange; i <= endRange; i++) {
      ret.push(i)
    }
    return ret
  }

  onChangeText = (e) => {
    const { target } = e
    const { name, value } = target
    
    const { stateObj } = this.state
    this.setState({
      stateObj: {
        ...stateObj,
        formControls: {
          ...stateObj.formControls,
          [name]: value
        },
        formErrors: {
          ...stateObj.formErrors,
          [name]: ""
        }
      }
    })
  }

  onSelectPos = (optionsList) => {
    let strPos = "["
    const len = optionsList.length
    optionsList.map((pos, index) => {
      if (index < len - 1) {
        strPos = `${strPos}"${pos.abbr}", `
      } else {
        strPos = `${strPos}"${pos.abbr}"]`
      }
    })
    
    this.setState({
      preferredPos: strPos,
      posError: ""
    })
  }

  formValidate = () => {
    const { stateObj, preferredPos } = this.state
    const { formControls } = stateObj
    this.setState({
      stateObj: {
        ...stateObj,
        formErrors: {
          ...stateObj.formErrors,
          fname: fieldEmpty(formControls.fname) ? "This field required" : "",
          lname: fieldEmpty(formControls.lname) ? "This field required" : "",
          month: fieldEmpty(formControls.month) ? "This field required" : "",
          day: fieldEmpty(formControls.day) ? "This field required" : "",
          year: fieldEmpty(formControls.year) ? "This field required" : "",
          jerseyNumber: fieldEmpty(formControls.jerseyNumber) ? "This field required" : "",
          gender: fieldEmpty(formControls.gender) ? "This field required" : "",
          email: !emailValidation(formControls.email) || fieldEmpty(formControls.email) ? "Please input correct email" : "",
          parentEmail1: !emailValidation(formControls.parentEmail1) || fieldEmpty(formControls.parentEmail1) ? "Please input correct email" : "",
          address: fieldEmpty(formControls.address) ? "This field required" : "",
          graduationYear: fieldEmpty(formControls.graduationYear) ? "This field required" : "",
          city: fieldEmpty(formControls.city) ? "This field required" : "",
          state: fieldEmpty(formControls.state) ? "This field required" : "",
        }
      }
    })
    if (preferredPos.length === 0) {
      this.setState({
        posError: "Please select at least one position"
      })
    }
    return (preferredPos.length !== 0) && !fieldEmpty(formControls.fname) && !fieldEmpty(formControls.lname) && !fieldEmpty(formControls.month) && !fieldEmpty(formControls.day) && !fieldEmpty(formControls.year) && !fieldEmpty(formControls.jerseyNumber) && !fieldEmpty(formControls.gender) && emailValidation(formControls.parentEmail1) && emailValidation(formControls.email) && !fieldEmpty(formControls.address) && !fieldEmpty(formControls.city) && !fieldEmpty(formControls.state)
  }

  submitSuccess = () => {
    this.setState({
      loading: false,
    })
    window.location = redirectUrl;
  }

  submitFail = () => {
    this.setState({
      loading: false
    })
  }

  onSubmit = () => {
    if (this.formValidate()) {
      const { stateObj, preferredPos, yearOptions, teamId } = this.state
      const { formControls } = stateObj
      const { serviceRequest } = this.props;
      this.setState({
        loading: true
      })
      const homeState = homeStates[formControls.state]
      const body = JSON.stringify({
        athleteType: "Provided",
        firstName: formControls.fname,
        lastName: formControls.lname,
        email: formControls.email,
        birthday: `${months[formControls.month]}/${days[formControls.day]}/${yearOptions[formControls.year]}`,
        graduationYear: graduationOptions[formControls.graduationYear],
        jerseyNumber: parseInt(formControls.jerseyNumber),
        preferredPos,
        gender: formControls.gender == 0 ? "Male" : "Female",
        parentNames: formControls.parentNames,
        phoneNumber: formControls.phone,
        parentEmail1: formControls.parentEmail1,
        parentEmail2: formControls.parentEmail2,
        address: formControls.address,
        city: formControls.city,
        state: homeState.abbr,
        zipcode: formControls.zipcode,
        teamIds: teamId
      })
      serviceRequest('athlete/create', 'POST', body, true, this.submitSuccess.bind(this), this.submitFail.bind(this));
    }
  }

  render() {
    const { yearOptions, loading, stateObj, teamName, managerNames, season, posError, homeStatesOptions } = this.state
    
    return (
      <div style={styles.container}>
        <Spinner show={loading} />
        <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex justify-center mb-5">
          <span className="text-grey-darker text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl font-bold">Get Your Game Stats Tracked Correctly!</span>
        </div>

        <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex bg-green rounded-lg mb-5">
          <span className="px-3 text-xs sm:text-xs md:text-xs lg:text-sm xl:text-sm">{`${teamName}(${managerNames}) would like to enable statskeeping for you this ${season}! Please fill in the below form to ensure the team can track your stats.`}</span>
        </div>
          <div>
            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex bg-green rounded-lg mb-5">
              <LabelInput
                parent="md:w-1/2 px-3 mb-0 md:mb-0"
                label="First Name *"
                type="text"
                name="fname"
                value={stateObj.formControls.fname}
                placeholder=""
                error={stateObj.formErrors.fname}
                onChangeText={this.onChangeText}
              />
              <LabelInput
                parent="md:w-1/2 px-3"
                label="Last Name *"
                type="text"
                name="lname"
                value={stateObj.formControls.lname}
                placeholder=""
                error={stateObj.formErrors.lname}
                onChangeText={this.onChangeText}
              />
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex flex-wrap rounded-lg mb-5">
              <label className="w-full flex text-grey-darker text-base sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold px-3">
                Date of Birth *
              </label>
              <div className="w-full flex">
                <DropDown
                  className="w-1/3 px-3"
                  options={months}
                  name="month"
                  placeholder="Month"
                  selectedIndex={stateObj.formControls.month}
                  error={stateObj.formErrors.month}
                  onChange={this.onChangeText}
                />
                <DropDown
                  className="w-1/3 px-3"
                  options={days}
                  name="day"
                  placeholder="Day"
                  selectedIndex={stateObj.formControls.day}
                  error={stateObj.formErrors.day}
                  onChange={this.onChangeText}
                />
                <DropDown
                  className="w-1/3 px-3"
                  options={yearOptions}
                  name="year"
                  placeholder="Year"
                  selectedIndex={stateObj.formControls.year}
                  error={stateObj.formErrors.year}
                  onChange={this.onChangeText}
                />
              </div>
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex flex-wrap rounded-lg mb-5">
              <div className="w-full flex">
                <DropDown
                  label="High School Graduation Year *"
                  className="w-1/2 px-3"
                  options={graduationOptions}
                  name="graduationYear"
                  placeholder="Graduation Year"
                  selectedIndex={stateObj.formControls.graduationYear}
                  error={stateObj.formErrors.graduationYear} 
                  onChange={this.onChangeText}
                />
                <DropDown
                  label="Jersey Number *"
                  className="w-1/2 px-3"
                  options={Array.from({length: 100}, (v, k) => k)}
                  name="jerseyNumber"
                  placeholder="Jersey Number"
                  selectedIndex={stateObj.formControls.jerseyNumber}
                  error={stateObj.formErrors.jerseyNumber}
                  onChange={this.onChangeText}
                />
              </div>
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex rounded-lg mb-3">
              <MultiDropDown
                label="Preferred Position *"
                className="w-full px-3"
                options={posOptions}
                display="name"
                placeholder="Select your postions"
                error={posError}
                onSelect={this.onSelectPos}
              />
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex flex-wrap rounded-lg mb-3">
              <div className="w-full flex">
                <DropDown
                  label="Athlete Gender *"
                  className="w-1/2 px-3"
                  options={["Male", "Female"]}
                  name="gender"
                  placeholder="Gender"
                  selectedIndex={stateObj.formControls.gender}
                  error={stateObj.formErrors.gender}
                  onChange={this.onChangeText}
                />
                <LabelInput
                  parent="w-1/2 px-3"
                  label="Parent Names"
                  labelmargin="mb-2"
                  type="text"
                  name="parentNames"
                  value={stateObj.formControls.parentNames}
                  placeholder=""
                  onChangeText={this.onChangeText}
                />
              </div>
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex bg-green rounded-lg mb-3">
              <LabelInput
                parent="w-1/2 px-3"
                label="Email *"
                type="text"
                name="email"
                value={stateObj.formControls.email}
                error={stateObj.formErrors.email}
                placeholder="Email"
                onChangeText={this.onChangeText}
              />
              <LabelInput
                parent="w-1/2 px-3"
                label="Phone Number"
                type="text"
                name="phone"
                pattern="\d*"
                maxLength="12"
                value={stateObj.formControls.phone}
                placeholder="(555) 555-1212"
                error={stateObj.formErrors.cellphone}
                onChangeText={this.onChangeText}
              />
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex bg-green rounded-lg mb-3">
              <LabelInput
                parent="w-1/2 px-3"
                label="Parent Email 1 *"
                type="text"
                name="parentEmail1"
                value={stateObj.formControls.parentEmail1}
                error={stateObj.formErrors.parentEmail1}
                placeholder="Parent Email 1"
                onChangeText={this.onChangeText}
              />
              <LabelInput
                parent="w-1/2 px-3"
                label="Parent Email 2"
                type="text"
                name="parentEmail2"
                value={stateObj.formControls.parentEmail2}
                placeholder="Parent Email 2"
                onChangeText={this.onChangeText}
              />
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex bg-green rounded-lg mb-3">
              <LabelInput
                parent="w-1/2 px-3"
                label="Home Address *"
                type="text"
                name="address"
                value={stateObj.formControls.address}
                error={stateObj.formErrors.address}
                placeholder="Address"
                onChangeText={this.onChangeText}
              />
              <LabelInput
                parent="w-1/2 px-3"
                label="Home City *"
                type="text"
                name="city"
                value={stateObj.formControls.city}
                placeholder="City"
                error={stateObj.formErrors.city}
                onChangeText={this.onChangeText}
              />
            </div>


            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex flex-wrap rounded-lg mb-3">
              <div className="w-full flex">
                <DropDown
                  label="Home State *"
                  className="w-1/2 px-3"
                  options={homeStatesOptions}
                  name="state"
                  placeholder="State"
                  selectedIndex={stateObj.formControls.state}
                  error={stateObj.formErrors.state}
                  onChange={this.onChangeText}
                />
                <LabelInput
                  parent="w-1/2 px-3"
                  label="Zip Code"
                  labelmargin="mb-2"
                  type="text"
                  pattern="\d*"
                  maxLength="5"
                  name="zipcode"
                  value={stateObj.formControls.zipcode}
                  placeholder="Zip Code"
                  onChangeText={this.onChangeText}
                />
              </div>
            </div>
          </div>
        <div
          className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg"
          style={{ marginTop: 20 }}
        >
          <Button
            label="Submit"
            width="40%"
            backgroundColor="#ffffff"
            margin={"20px"}
            onClick={this.onSubmit}
          />
        </div>
      </div>
    )
  }
}

AddAthleteInfo.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.instanceOf(Object).isRequired
}

const mapStateToProps = () => {
  return {
    
  };
};

export default connect(mapStateToProps, { serviceRequest })(AddAthleteInfo)
