import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { stateObj } from "./model"
import styles from "./styles"
import "./submitDetail.css"
import Button from "../../components/buttons/button"
import LabelInput from "../../components/labeledinput/labeledinput"
import DropDown from "../../components/dropdown/dropdown"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest, currentUser } from '../../../actions/User'
import { fieldEmpty } from '../../../config/Global'

const days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
]
const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
]

const userTypes = ['Coach', 'Parent', 'Athlete'];
const athleteGenders = ['Men/Boys', 'Women/Girls'];
const teamsTypes = [
  'Elementary Youth(ages 5 ~ 11)', 
  'Middle Youth(ages 12 ~ 14)', 
  'High School Youth(ages 15 ~ 19)', 
  'College(ages 19 ~ 23)', 
  'Adult(ages = 18+)',
  'Pro(ages 18+)'
]

class SubmitDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      yearOptions: [],
      step: 1,
      userType: [],
      athleteGender: [],
      teamsType: [],
      userTypeError: "",
      teamsTypeError: "",
      athleteGenderError: ""
    }

    this.onChangeText = this.onChangeText.bind(this)
    this.onClickToMain = this.onClickToMain.bind(this)
    this.onClickUserTypes = this.onClickUserTypes.bind(this)
    this.onClickAthleteGender = this.onClickAthleteGender.bind(this)
    this.onClickTeamsTypes = this.onClickTeamsTypes.bind(this)
    this.submitSuccess = this.submitSuccess.bind(this)
    this.submitFail = this.submitFail.bind(this)
  }

  componentDidMount() {
    const yearOptions = this.makeYearOptions(1900, 2019)
    this.setState({
      yearOptions: yearOptions.reverse(),
    })
    this.initOptionsButtons();
    const { stateObj } = this.state
    const { user } = this.props
    const { userInfo } = user;
    this.setState({
      stateObj: {
        ...stateObj,
        formControls: {
          ...stateObj.formControls,
          fname: userInfo.first_name,
          lname: userInfo.last_name
        }
      }
    })
  }

  makeYearOptions = (startRange, endRange) => {
    const ret = []
    for (let i = startRange; i <= endRange; i++) {
      ret.push(i)
    }
    return ret
  }

  initOptionsButtons = () => {
    const userType = []
    const athleteGender = [];
    const teamsType = []
    userTypes.map((item) => {
      const button = {
        label: item,
        selected: false
      }
      userType.push(button);
    })
    
    athleteGenders.map((item) => {
      const button = {
        label: item,
        selected: false
      }
      athleteGender.push(button)
    })
    
    teamsTypes.map((item) => {
      const button = {
        label: item,
        selected: false
      }
      teamsType.push(button)
    })

    this.setState({
      userType,
      athleteGender,
      teamsType
    })
  }

  onClickUserTypes = (index) => {
    const { userType } = this.state
    const tmp = [];
    userType.map((item, i) => {
      if (index === i) {
        item.selected = !item.selected
      }
      tmp.push(item)
    })
    this.setState({ userType: tmp, userTypeError: "" })
  }

  onClickAthleteGender = (index) => {
    const { athleteGender } = this.state
    const tmp = []
    athleteGender.map((item, i) => {
      if (index === i) {
        item.selected = !item.selected
      }
      tmp.push(item)
    })
    this.setState({ athleteGender: tmp, athleteGenderError: "" })
  }

  onClickTeamsTypes = (index) => {
    const { teamsType } = this.state
    const tmp = []
    teamsType.map((item, i) => {
      if (index === i) {
        item.selected = !item.selected
      }
      tmp.push(item)
    })
    this.setState({ teamsType: tmp, teamsTypeError: "" })
  }

  onChangeText = (event) => {
    const { target } = event
    const { name, value } = target
    this.setState((prevState) => ({
      stateObj: {
        formControls: {
          ...prevState.stateObj.formControls,
          [name]: value
        },
        formErrors: {
          ...prevState.stateObj.formErrors,
          [name]: ""
        }
      },
    }))
  }

  submitSuccess = (data) => {
    const { step } = this.state
    const { currentUser } = this.props
    currentUser(data)
    console.log('******: ', data)
    if (step === 1) {
      this.setState({ step: step + 1 })
    } else {
      this.props.history.push('/');
    }
  }

  submitFail = (error) => {
    console.log('---------: ', error)
  }

  onClickToMain() {
    const { stateObj, userType, athleteGender, teamsType, step, yearOptions } = this.state
    const { formControls } = stateObj
    const { user, serviceRequest } = this.props;
    let usertype = []
    let athletegender = []
    let teamstype = []
    if (step === 1) {
      if (fieldEmpty(formControls.fname)) {
        this.setState((prevState) => ({
          stateObj: {
            formControls: {
              ...prevState.stateObj.formControls
            },
            formErrors: {
              ...prevState.stateObj.formErrors,
              fname: "This field is required"
            }
          },
        }))
        return;
      }
      if (fieldEmpty(formControls.lname)) {
        this.setState((prevState) => ({
          stateObj: {
            formControls: {
              ...prevState.stateObj.formControls
            },
            formErrors: {
              ...prevState.stateObj.formErrors,
              lname: "This field is required"
            }
          },
        }))
        return;
      }
      // if (fieldEmpty(formControls.phone)) {
      //   this.setState((prevState) => ({
      //     stateObj: {
      //       formControls: {
      //         ...prevState.stateObj.formControls
      //       },
      //       formErrors: {
      //         ...prevState.stateObj.formErrors,
      //         phone: "This field is required"
      //       }
      //     },
      //   }))
      //   return;
      // }
      // if (fieldEmpty(formControls.month)) {
      //   this.setState((prevState) => ({
      //     stateObj: {
      //       formControls: {
      //         ...prevState.stateObj.formControls
      //       },
      //       formErrors: {
      //         ...prevState.stateObj.formErrors,
      //         month: "This field is required"
      //       }
      //     },
      //   }))
      //   return;
      // }
      // if (fieldEmpty(formControls.day)) {
      //   this.setState((prevState) => ({
      //     stateObj: {
      //       formControls: {
      //         ...prevState.stateObj.formControls
      //       },
      //       formErrors: {
      //         ...prevState.stateObj.formErrors,
      //         day: "This field is required"
      //       }
      //     },
      //   }))
      //   return;
      // }
      // if (fieldEmpty(formControls.year)) {
      //   this.setState((prevState) => ({
      //     stateObj: {
      //       formControls: {
      //         ...prevState.stateObj.formControls
      //       },
      //       formErrors: {
      //         ...prevState.stateObj.formErrors,
      //         year: "This field is required"
      //       }
      //     },
      //   }))
      //   return;
      // }
      // if (fieldEmpty(formControls.gender)) {
      //   this.setState((prevState) => ({
      //     stateObj: {
      //       formControls: {
      //         ...prevState.stateObj.formControls
      //       },
      //       formErrors: {
      //         ...prevState.stateObj.formErrors,
      //         gender: "This field is required"
      //       }
      //     },
      //   }))
      //   return;
      // }
    } else {
      userType.map((item) => {
        if (item.selected) {
          usertype.push(item.label)
        }
      })
      if (usertype.length === 0) {
        this.setState({
          userTypeError: "Required to select at least one"
        })
        return;
      }
      teamsType.map((item) => {
        if (item.selected) {
          teamstype.push(item.label)
        }
      })
      if (teamstype.length === 0) {
        this.setState({
          teamsTypeError: "Required to select at least one"
        })
        return;
      }
      athleteGender.map((item) => {
        if (item.selected) {
          athletegender.push(item.label)
        }
      })
      if (athletegender.length === 0) {
        this.setState({
          athleteGenderError: "Required to select at least one"
        })
        return;
      }
    }
    
    const body = JSON.stringify({
      firstName: user.userInfo && user.userInfo.first_name ? user.userInfo.first_name : formControls.fname,
      lastName: user.userInfo && user.userInfo.last_name ? user.userInfo.last_name : formControls.lname,
      cellphone: formControls.phone !== '' ? formControls.phone : '',
      birthday: formControls.day !== '' && formControls.month !== '' && formControls.year !== '' ? `${days[formControls.day]}/${months[formControls.month]}/${yearOptions[formControls.year]}` : '',
      gender: formControls.gender !== '' ? (parseInt(formControls.gender) === 0 ? "Male" : "Female") : '',
      userType: step === 2 ? usertype : "",
      athleteGender: step === 2 ? athletegender : "",
      teamsType: step === 2 ? teamstype : "",
      appType: 'Stats',
      email: user.email
    })
    
    serviceRequest('user/update', 'POST', body, false, this.submitSuccess.bind(this), this.submitFail.bind(this));
  }

  render() {
    const { yearOptions, step, userType, athleteGender, teamsType,  stateObj, userTypeError, teamsTypeError, athleteGenderError} = this.state
    const { user } = this.props;
    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        {step === 1 ? (
          <div>
            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
              <LabelInput
                parent="md:w-1/2 px-3 mb-0 md:mb-0"
                label="First Name *"
                type="text"
                name="fname"
                value={stateObj.formControls.fname}
                placeholder=""
                error={stateObj.formErrors.fname}
                onChangeText={this.onChangeText}
              />
              <LabelInput
                parent="md:w-1/2 px-3"
                label="Last Name *"
                type="text"
                name="lname"
                value={stateObj.formControls.lname}
                placeholder=""
                error={stateObj.formErrors.lname}
                onChangeText={this.onChangeText}
              />
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
              <LabelInput
                parent="w-full px-3"
                label="Email"
                type="text"
                name="email"
                value={user.email}
                disabled
                placeholder=""
              />
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
              <LabelInput
                parent="w-full px-3 mb-3 md:mb-0"
                label="Phone Number"
                type="numeric"
                name="phone"
                value={stateObj.formControls.phone}
                placeholder=""
                onChangeText={this.onChangeText}
              />
            </div>

            <div className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex flex-wrap rounded-lg">
              <label className="w-full text-grey-darker text-base sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold px-5">
                Date of birthday
              </label>
              <div className="w-full flex">
                <DropDown
                  className="w-1/3 px-5"
                  options={months}
                  name="month"
                  disabled={user.loading}
                  placeholder="Month"
                  selectedIndex={stateObj.formControls.month}
                  onChange={this.onChangeText}
                />
                <DropDown
                  className="w-1/3 px-5"
                  options={days}
                  name="day"
                  disabled={user.loading}
                  placeholder="Date"
                  selectedIndex={stateObj.formControls.day}
                  onChange={this.onChangeText}
                />
                <DropDown
                  className="w-1/3 px-5"
                  options={yearOptions}
                  name="year"
                  disabled={user.loading}
                  placeholder="Year"
                  selectedIndex={stateObj.formControls.year}
                  onChange={this.onChangeText}
                />
              </div>
            </div>

            <div
              className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg"
              style={{ marginTop: 10 }}
            >
              <DropDown
                label="Gender"
                className="w-full px-3"
                options={["Male", "Female"]}
                name="gender"
                placeholder="Gender"
                selectedIndex={stateObj.formControls.gender}
                onChange={this.onChangeText}
              />
            </div>
          </div>
        ) : (
          <div>
            <div
              className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex flex-wrap p-2 bg-green rounded-lg"
              style={{ marginTop: 10 }}
            >
              <p className="w-full block text-left text-sm sm:text-md md:text-base lg:text-lg xl:text-xl">I am a:</p>
              {userType.map((item, index) => (
                <div className="w-1/3 h-full" key={`index-${index}`}>
                  <Button
                  className=""
                    label={item.label}
                    width="100%"
                    selected={item.selected}
                    margin="1rem"
                    activeColor="#E53E3E"
                    noRounded
                    onClick={() => this.onClickUserTypes(index)}
                  />
                </div>
              ))}
              {userTypeError && <p className="w-full block text-left text-red-500 text-xs">{userTypeError}</p>}
            </div>

            <div
              className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex flex-wrap p-2 bg-green rounded-lg"
              style={{ marginTop: 10, marginBottom: 16 }}
            >
              <p className="w-full block text-left text-sm sm:text-md md:text-base lg:text-lg xl:text-xl">What best describes the team(s) you’ll track stats?</p>
              {teamsType.map((item, index) => (
                <div className="w-1/2 h-full" key={`index-${index}`}>
                  <Button
                    label={item.label}
                    width="100%"
                    height="5rem"
                    selected={item.selected}
                    activeColor="#E53E3E"
                    noRounded
                    onClick={() => this.onClickTeamsTypes(index)}
                  />
                </div>
              ))}
              {teamsTypeError && <p className="w-full block text-left text-red-500 text-xs">{teamsTypeError}</p>}
            </div>

            <div
              className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex flex-wrap p-2 bg-green rounded-lg"
              style={{ marginTop: 10 }}
            >
              <p className="w-full block text-left text-sm sm:text-md md:text-base lg:text-lg xl:text-xl">Gender of the athletes</p>
              {athleteGender.map((item, index) => (
                <div className="w-1/2 h-full" key={`index-${index}`}>
                  <Button
                    label={item.label}
                    width="100%"
                    height="4rem"
                    key={`index-${index}`}
                    selected={item.selected}
                    margin="1rem"
                    activeColor="#E53E3E"
                    noRounded
                    onClick={() => this.onClickAthleteGender(index)}
                  />
                </div>
              ))}
              {athleteGenderError && <p className="w-full block text-left text-red-500 text-xs">{athleteGenderError}</p>}
            </div>
          </div>
        )}

        <div
          className="max-w-xs xl:max-w-3xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg bg-outline1"
          style={{ marginTop: 40 }}
        >
          <Button
            label="Submit"
            width="40%"
            backgroundColor="#ffffff"
            borderColor= "#d00a00"
            margin={"20px"}
            onClick={this.onClickToMain}
          />
        </div>
      </div>
    )
  }
}

SubmitDetails.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  currentUser: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, {serviceRequest, currentUser})(SubmitDetails)
