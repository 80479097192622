import * as ActionTypes from '../config/ActionTypes'

export const selectGameTeams = (teams) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.GAME_TEAMS,
            payload: teams
        })
    }
}

export const changeStatsOptions = (options) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.GAME_STATS,
            payload: options
        })
    }
}

export const addAthleteStats = (stat) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.ADD_STATS,
            payload: stat
        })
    }
}

export const currentGame = (game) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.REPORT_GAME,
            payload: game
        })
    }
}

export const reportAthlete = (athlete) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.REPORT_ATHLETE,
            payload: athlete
        })
    }
}

export const reportGameKudos = (kudos) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.REPORT_GAME_KUDOS,
            payload: kudos
        })
    }
}
