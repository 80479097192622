import React, { Component } from "react"
import PropTypes from "prop-types"
import { Droppable } from "react-beautiful-dnd"

import GridItem from "./gridItem"



const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#e2e1ff" : "#cbe8e8",
})

class GridColumn extends Component {
  static propTypes = {
    droppableId: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }

  render() {
    const { droppableId, data, ...props } = this.props
    return (
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div
            className="w-full h-full xs:w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3"
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {data.map((item, index) => {
              return <GridItem item={item} index={index} key={item.id} {...props} />
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }
}

export default GridColumn
