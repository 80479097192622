import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  registerToken,
  userEmail,
  currentUser,
  selectTeam,
  getAllTeams,
  getOtherTeams
} from "../../../actions/User"
import {
  selectGameTeams
} from "../../../actions/Game"
const Logo = require("../../../images/logo.png")
const Humbermenu = require("../../../images/humbermenu.png")
class NavHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuToggle: false
    }
    this.onClickMenu = this.onClickMenu.bind(this)
    this.onClickSignOut = this.onClickSignOut.bind(this)
  }

  onClickMenu = () => {
    const { menuToggle } = this.state
    this.setState({ menuToggle: !menuToggle })
  }

  onClickSignOut = () => {
    const {
      registerToken,
      userEmail,
      currentUser,
      selectTeam,
      getAllTeams,
      getOtherTeams,
      selectGameTeams
    } = this.props
    registerToken("")
    userEmail("")
    currentUser(null)
    selectTeam(null)

    getAllTeams([])
    getOtherTeams([])
    selectGameTeams(null)
    // eslint-disable-next-line no-undef
    window.href="/login"
  }

  render() {
    const { menuToggle } = this.state
    return (
      <nav
        className="w-full header-nav fixed flex items-center justify-between flex-wrap bg-white py-2 px-4 top-0 z-10"
      >
        
        <div className="block lg:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white Humbermenu"
            onClick={this.onClickMenu}
          >
           <img src={Humbermenu} alt="Humbermenu"/>
          </button>
        </div>
        <div className="flex items-center flex-no-shrink text-white mr-6 logo">
         <img src={Logo} alt="Logo"/>
        </div>
        <div
          className={
            menuToggle
              ? "block w-full flex-grow lg:flex lg:items-center lg:w-auto"
              : "hidden w-full flex-grow lg:flex lg:items-center lg:w-auto"
          }
        >
          <div className="text-sm lg:flex-grow">
            {/* <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-white mr-4"
            >
              About Us
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-white mr-4"
            >
              Examples
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter hover:text-white"
            >
              Blog
            </a> */}
          </div>
          <div>
            <span
              // href="#/login"
              className="btn-link inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal hover:bg-green mt-4 lg:mt-0 cursor-pointer"
              onClick={this.onClickSignOut}
            >
              Sign out
            </span>
          </div>
        </div>
      </nav>
    )
  }
}

NavHeader.propTypes = {
  registerToken: PropTypes.func.isRequired,
  currentUser: PropTypes.func.isRequired,
  userEmail: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  getOtherTeams: PropTypes.func.isRequired,
  selectGameTeams: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  {
    registerToken,
    currentUser,
    userEmail,
    selectTeam,
    getAllTeams,
    getOtherTeams,
    selectGameTeams
  }
)(NavHeader)
