const styles = {
  container: {
    width: "100%",
    paddingTop: "5vh",
    minHeight: "100vh",
    height: "auto !important",
  },
}

export default styles
