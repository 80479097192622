import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import { stateObj } from "./model"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest, setUnVisibleTeams } from "../../../actions/User"
import { selectGameTeams, currentGame } from "../../../actions/Game"
import Colors from "../../../config/Colors"

class RecordGameStats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateObj,
      selectedMainTeamIndex: -1,
      selectedOppTeamIndex: -1,
      allTeams: [],
      progressTeams: [],
    }
    this.onClickSelectMainTeam = this.onClickSelectMainTeam.bind(this)
    this.onClickSelectOppTeam = this.onClickSelectOppTeam.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onClickAddTeam = this.onClickAddTeam.bind(this)
    this.onClickGoHome = this.onClickGoHome.bind(this)

    this.requestSuccess = this.requestSuccess.bind(this)

  }

  componentDidMount = async() => {
    const { serviceRequest, user } = this.props;
    
    const body = JSON.stringify({
      userId: user.userInfo.id,
      getSubs: false
    })
    await serviceRequest('athlete/getAllTeamsAndAthletesByUserId', 'POST', body, false, this.requestSuccess.bind(this));
  }

  requestSuccess = (data) => {
    console.log(data)
    if (!data.error) {
      data.teams.sort((a, b) => {
        return new Date(b.team.updated_at) - new Date(a.team.updated_at)
      })

      const progressTeams = []
      const unProgressTeams = []
      data.teams.map(item => {
        if (item.progressGame) {
          progressTeams.push(item)
        } else {
          unProgressTeams.push(item)
        }
      })
      this.setState({
        allTeams: unProgressTeams,
        progressTeams,
      })
    }
  }

  onClickSelectMainTeam = (index) => {
    const { selectedMainTeamIndex } = this.state
    if (index === selectedMainTeamIndex) {
      this.setState({ selectedMainTeamIndex: -1 })
    } else {
      this.setState({ selectedMainTeamIndex: index })
    }
  }

  onClickSelectOppTeam = (index) => {
    const { selectedOppTeamIndex } = this.state
    if (index === selectedOppTeamIndex) {
      this.setState({ selectedOppTeamIndex: -1 })
    } else {
      this.setState({ selectedOppTeamIndex: index })
    }
  }

  onClickSubmit() {
    const { allTeams, selectedMainTeamIndex, selectedOppTeamIndex } = this.state
    const { selectGameTeams, history } = this.props
    
    const gameTeamIds = {
      mainTeam: allTeams[selectedMainTeamIndex].team,
      oppTeam: selectedOppTeamIndex > 0 ? allTeams[selectedOppTeamIndex - 1].team : null,
    }
    
    selectGameTeams(gameTeamIds)
    history.push("record-ready")
  }

  onClickGoHome() {
    this.props.history.push("/")
  }

  onClickAddTeam() {
    const { history } = this.props
    history.push("/create-team")
  }

  onClickProgressGame = (item) => {
    const { currentGame } = this.props
    currentGame({game: item.progressGame})
    this.props.history.push("record-activity")
  }

  render() {
    const { selectedMainTeamIndex, selectedOppTeamIndex, allTeams, progressTeams } = this.state
    const { user } = this.props

    if (!user.regToken) {
      return <Redirect to="/login" />
    }
    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <NavHeader/>
        <div className="max-w-sm xl:max-w-2xl pt-4 lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <table className="text-left w-full border-collapse main-bg-color">
            <tbody className="text-center">
              {progressTeams.map((item, index) => (
                <tr key={index} className="w-full row-multi-color">
                  <td
                    className="w-full flex flex-col justify-center font-bold py-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                  >
                    <div className="w-1/2 flex flex-col justify-center items-center justify-center rounded-fulled-two  text-white py-2">
                      <span>{`${item.team.team_name} (${item.memberCount})`}</span>
                   
                      <span className="text-white text-xs cursor-pointer" onClick={() => this.onClickProgressGame(item)}>Game In Progress</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">Select Two Teams</span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <table className="text-left w-full border-collapse main-bg-color">
            <tbody className="text-center">
              <tr>
                <td
                  className="bg-black font-bold text-white py-3 w-1/2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                >
                  (1) Main Team
                </td>
                <span className="px-8" />
                <td
                  className="bg-black font-bold text-white py-3 w-1/2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                >
                  (2) Opponent Team
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <div style={{ width: "50%" }}>
            {allTeams.map((item, index) => (
              <Button
                label={`${item.team.team_name} (${item.memberCount})`}
                width="100%"
                key={`index-${index}`}
                margin="1rem"
                activeColor="#5a67d8"
                selected={index === selectedMainTeamIndex}
                disabled={index === selectedOppTeamIndex - 1}
                noRounded
                onClick={() => this.onClickSelectMainTeam(index)}
              />
            ))}
            <span className="bg-purple">
            <Button
              label="Add Another Team"
              width="100%"
              backgroundColor={Colors.white}
              margin="1rem"
              noRounded
              onClick={this.onClickAddTeam}
            />
            </span>
          </div>
          <span className="px-8"></span>
          <div style={{ width: "50%" }}>
            <Button
              label="Easy Mode (recommeded)"
              width="100%"
              margin="1rem"
              activeColor="#5a67d8"
              selected={selectedOppTeamIndex === 0}
              noRounded
              onClick={() => this.onClickSelectOppTeam(0)}
            />
            {allTeams.map((item, index) => (
              <Button
                label={`${item.team.team_name} (${item.memberCount})`}
                width="100%"
                margin="1rem"
                key={`index-${index}`}
                activeColor="#5a67d8"
                selected={index + 1 === selectedOppTeamIndex}
                disabled={index === selectedMainTeamIndex}
                noRounded
                onClick={() => this.onClickSelectOppTeam(index + 1)}
              />
            ))}
          </div>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex flex-col justify-center p-2 mt-10 bg-green rounded-lg">
          <div className="w-1/2 bg-outline1 mx-auto text-center">
            <Button
              label="Return"
              width="100%"
              backgroundColor="#fff"
              margin="1rem"
              onClick={this.onClickGoHome}
            />
          </div>
        
          <div className="w-1/2 bg-pink  mx-auto text-center">
            {selectedMainTeamIndex !== -1 && selectedOppTeamIndex !== -1 && (
              <Button
                label="Submit"
                width="100%"
                backgroundColor="#fff"
                margin="1rem"
                onClick={this.onClickSubmit}
              />
            )}
          </div>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 pb-24 mt-10 bg-green rounded-lg">
          <span className="italic text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
            Easy Mode (Recommended) = select this to record opposing players as a single group (easy option!). This is best when you only want your own team’s statistics and not the opponents.
          </span>
        </div>
        {/* <NavFooter/> */}
      </div>
    )
  }
}

RecordGameStats.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  selectGameTeams: PropTypes.func.isRequired,
  setUnVisibleTeams: PropTypes.func.isRequired,
  currentGame: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    selectGameTeams,
    setUnVisibleTeams,
    currentGame
  }
)(RecordGameStats)