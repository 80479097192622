const styles = {
  container: {
    width: "100%",
    height: "100vh",
    textAlign: "center",
  },
  bgPrimary:{
    backgroundColor: "#d00a00"
},
  bgOutline:{
    border: "1px solid #d00a00"
}
}

export default styles
