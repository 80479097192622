import React, { Component } from "react"
import PropTypes from 'prop-types';
import { DragDropContext } from "react-beautiful-dnd"
import GridColumn from "./gridColumn"

const upDownIcon = require("../../../../images/icons/updown_arrow.png")

class Grid extends Component {
  render() {
    const { state, onPositiveDragEnd, onNegativeDragEnd } = this.props
    return (
      <div className="block w-full p-1  ">
        <div className="flex w-full">
          <div className="w-1/2 p-2  text-center">
            <div className="h-16 flex items-center justify-center">
              <span className="font-light text-xl">Positive Action</span>
            </div>

            <DragDropContext
              class="w-full h-full"
              onDragEnd={(res) => onPositiveDragEnd(res)}
            >
              <div className="w-full flex flex-wrap mt-4 mb-2 h-36 xs:h-36 sm:h-48 md:h-48 lg:h-48 xl:h-48 overflow-hidden">
                <GridColumn
                  droppableId="poslist1"
                  data={state.poslist1}
                  {...this.props}
                />
                <GridColumn
                  droppableId="poslist2"
                  data={state.poslist2}
                  {...this.props}
                />
                <GridColumn
                  droppableId="poslist3"
                  data={state.poslist3}
                  {...this.props}
                />
              </div>
              <div className="w-full flex  justify-center">
                <img src={upDownIcon} style={{ width: 32, height: 28 }} />
              </div>
              <div
                className="w-full flex flex-wrap mt-2 h-48 xs:h-48 sm:h-64 md:h-64 lg:h-64 xl:h-64 overflow-hidden"
              >
                <GridColumn
                  droppableId="poslist4"
                  data={state.poslist4}
                  {...this.props}
                />
                <GridColumn
                  droppableId="poslist5"
                  data={state.poslist5}
                  {...this.props}
                />
                <GridColumn
                  droppableId="poslist6"
                  data={state.poslist6}
                  {...this.props}
                />
              </div>
            </DragDropContext>
          </div>
          <div className="w-1/2 p-2  text-center">
            <div className="h-16 flex items-center justify-center">
              <span className="font-light text-xl">Neutral or Negative Action</span>
            </div>
            <DragDropContext
              class="w-full h-full"
              onDragEnd={(res) => onNegativeDragEnd(res)}
            >
              <div className="w-full flex flex-wrap mt-4 mb-2 h-36 xs:h-36 sm:h-48 md:h-48 lg:h-48 xl:h-48 overflow-hidden">
                <GridColumn
                  droppableId="neglist1"
                  data={state.neglist1}
                  {...this.props}
                />
                <GridColumn
                  droppableId="neglist2"
                  data={state.neglist2}
                  {...this.props}
                />
                <GridColumn
                  droppableId="neglist3"
                  data={state.neglist3}
                  {...this.props}
                />
              </div>
              <div className="w-full flex justify-center">
                <img src={upDownIcon} style={{ width: 32, height: 28 }} />
              </div>
              <div
                className="w-full flex flex-wrap mt-2 h-48 xs:h-48 sm:h-64 md:h-64 lg:h-64 xl:h-64 overflow-hidden"
              >
                <GridColumn
                  droppableId="neglist4"
                  data={state.neglist4}
                  {...this.props}
                />
                <GridColumn
                  droppableId="neglist5"
                  data={state.neglist5}
                  {...this.props}
                />
                <GridColumn
                  droppableId="neglist6"
                  data={state.neglist6}
                  {...this.props}
                />
              </div>
            </DragDropContext>
          </div>
        </div>
      </div>
    )
  }
}

Grid.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onPositiveDragEnd: PropTypes.func.isRequired,
  onNegativeDragEnd: PropTypes.func.isRequired
}

export default Grid
