/* eslint-disable no-undef */
import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { ToastsStore } from "react-toasts"
import styles from "./styles"
import Button from "../../components/buttons/button"
import { serviceRequest, setUnVisibleTeams } from "../../../actions/User"
import NavHeader from "../../components/navheader/navheader"
import NavFooter from "../../components/navfooter/navFooter"
import { currentGame } from "../../../actions/Game"
import { saveGame } from "../../utils/FirestoreUtils"
import Spinner from "../../components/spinner/spinner"
import { isMobileDevice } from "../../../config/Global"
import Colors from "../../../config/Colors"
import { ToastMessages } from "../../../config/ToastMessages"

class RecordScrimmageData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedMainTeamIndex: -1,
      allTeams: [],
      selectedTeamId: 0,
      bench: [],
      progressTeams: []
    }
    this.onClickSelectMainTeam = this.onClickSelectMainTeam.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onClickAddTeam = this.onClickAddTeam.bind(this)
  }

  componentDidMount = async () => {
    const { serviceRequest, user } = this.props;
    
    const body = JSON.stringify({
      userId: user.userInfo.id,
      getSubs: false
    })
    await serviceRequest('athlete/getAllTeamsAndAthletesByUserId', 'POST', body, false, this.requestSuccess.bind(this));
  }

  requestSuccess = (data) => {
    if (!data.error) {
      if (data.teams) {
        data.teams.sort((a, b) => {
          return new Date(b.team.updated_at) - new Date(a.team.updated_at)
        })
        
        const progressTeams = []
        const unProgressTeams = []
        data.teams.map(item => {
          if (item.progressGame) {
            progressTeams.push(item)
          } else {
            unProgressTeams.push(item)
          }
        })
        
        this.setState({
          allTeams: unProgressTeams,
          progressTeams,
        })
      } else {
        const { serviceRequest } = this.props
        const athletes = []
        data.map((player, index) => {
          if (!player.hidden) {
            const positions = player.preferred_positions ? JSON.parse(player.preferred_positions) : []
            const prefer_pos = positions ? positions.join(", ") : ''
            const athlete = {
              athleteId: player.id, 
              name: player.last_name ? `${player.first_name} ${player.last_name}` : player.first_name,
              jersey: player.jersey_number ? player.jersey_number : '',
              pos: prefer_pos,
              id: `item-${index}`,
              joinTime: 0,
              outTime: -1,
              ready: true
            }
            athletes.push(athlete)
          }
        })
        
        this.setState({
          bench: athletes
        })
        const { selectedTeamId } = this.state
        const body = JSON.stringify({
          mainTeamId: selectedTeamId,
          oppTeamId: '',
          isScrimming: true,
        })
        
        serviceRequest("game/create", "POST", body, false, this.createGameSuccess.bind(this), this.crateGameFail)
      }
    }
  }

  goFull = () => {
    var el = document.documentElement;

    // Supports most browsers and their versions.
    var requestMethod = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen;

    if (requestMethod) {

      // Native full screen.
      requestMethod.call(el);

    }

    window.screen.orientation.lock('landscape');
  }

  createGameSuccess = ( createdGame ) => {
    if (!createdGame.error) {
      const { user, history, currentGame } = this.props
      const { bench } = this.state
      const benchAthletes = bench.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      let storingMainAthletes = null
      storingMainAthletes = {
        bench: benchAthletes,
        posGK: [],
        posLB: [],
        posRB: [],
        posCB: [],
        posLM: [],
        posCM: [],
        posRM: [],
        posLF: [],
        posCF: [],
        posRF: []
      }

      const storingGame = {
        gameDuration: 3600,
        startTime: 0,
        pauseTime: 0,
        oppAthletes: [],
        mainAthletes: storingMainAthletes,
        mainAthleteStats: [],
        oppAthleteStats: [],
        controlUserId: user.userInfo.id,
        isScrimming: true,
        gameId: createdGame.id,
        isPlaying: false,
        mainGoals: 0,
        oppGoals: 0,
        subsManagers: []
      }
      saveGame(`game-${createdGame.id}`, storingGame)
      currentGame({
        game: createdGame
      })
      if (isMobileDevice())
        this.goFull()
      history.push("record-activity")
    }
  }

  createGameFail = () => {
    ToastsStore.error(ToastMessages.CREATE_GAME_FAIL)
  }

  onClickSelectMainTeam = (index) => {
    const {selectedMainTeamIndex} = this.state;
    if (index === selectedMainTeamIndex) {
      this.setState({selectedMainTeamIndex: -1})
    } else {
      this.setState({selectedMainTeamIndex: index})
    }
  }

  onClickSubmit() {
    const { allTeams, selectedMainTeamIndex } = this.state
    const { serviceRequest } = this.props
    const selectedTeam = allTeams[selectedMainTeamIndex]
    let body = JSON.stringify({
      teamId: selectedTeam.team.id
    })

    this.setState({
      selectedTeamId: selectedTeam.team.id
    }, () => {
      serviceRequest('athlete/getAthletesByTeamId', 'POST', body, false, this.requestSuccess.bind(this));
    })
  }

  onClickAddTeam() {
    const { history } = this.props
    history.push("/create-team")
  }

  onClickProgressGame = (item) => {
    const { currentGame } = this.props
    currentGame({game: item.progressGame})
    this.props.history.push("record-activity")
  }

  render() {
    const { user } = this.props

    if (!user.regToken) {
      return <Redirect to="/login" />
    }
    const { selectedMainTeamIndex, allTeams, progressTeams } = this.state

    return (
      <div style={styles.container}>
        <NavHeader/>
        <Spinner show={user.loading} />
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <table className="text-left w-full border-collapse main-bg-color">
            <tbody className="text-center">
              {progressTeams.map((item, index) => (
                <tr key={index} className="w-full  row-multi-color">
                  <td
                    className="w-full flex font-bold py-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                  >
                    <div className="w-1/2 flex flex-col justify-center items-center justify-center rounded-fulled-two  text-white py-2">
                      <span>{`${item.team.team_name} (${item.memberCount})`}</span>
                    
                      <span className="text-white text-xs cursor-pointer" onClick={() => this.onClickProgressGame(item)}>Game In Progress</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            Select Team that is Practicing / Scrimmaging
          </span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex flex-wrap p-2 bg-green rounded-lg">
          {allTeams.map((item, index) => {
            if (index % 2 === 0) {
              return (
                <div className="w-1/2 flex" key={`key-${index}`}>
                  <Button
                    label={`${item.team.team_name} (${item.memberCount})`}
                    width="100%"
                    margin="1rem"
                    activeColor="#5a67d8"
                    selected={index === selectedMainTeamIndex}
                    noRounded
                    onClick={() => this.onClickSelectMainTeam(index)}
                  />
                  <span className="px-4"></span>
                </div>
              )
            } else {
              return (
                <div className="w-1/2 flex" key={`key-${index}`}>
                  <span className="px-4"></span>
                  <Button
                    label={`${item.team.team_name} (${item.memberCount})`}
                    width="100%"
                    margin="1rem"
                    activeColor="#5a67d8"
                    selected={index === selectedMainTeamIndex}
                    noRounded
                    onClick={() => this.onClickSelectMainTeam(index)}
                  />
                </div>
              )
            }
          })}
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <div className="w-2/4 bg-purple">
            <Button
              label="Add Another Team"
              width="100%"
              backgroundColor={Colors.white}
              margin="1rem"
              noRounded
              onClick={this.onClickAddTeam}
            />
          </div>
          <span className="px-8"></span>
          <div className="w-2/4">
            
          </div>
        </div>

        {selectedMainTeamIndex !== -1 && (
          <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-pink mt-10 bg-green rounded-lg">
            <Button
              label="Submit"
              width="80%"
              backgroundColor="#fff"
              onClick={this.onClickSubmit}
            />
          </div>
        )}
        <span className="block pb-24"></span>
        {/* <NavFooter/> */}
      </div>
    )
  }
}

RecordScrimmageData.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  game: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  currentGame: PropTypes.func.isRequired,
  setUnVisibleTeams: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    currentGame,
    setUnVisibleTeams
  }
)(RecordScrimmageData)