import React, { Component } from "react"
import PropTypes from 'prop-types';
import { DragDropContext } from "react-beautiful-dnd"
import Column from "./column"
import Button from '../../buttons/button';

class List extends Component {
  render() {
    const { state, onDragEnd, onClickAddNewPlayer } = this.props
    return (
        <DragDropContext onDragEnd={(res) => onDragEnd(res)}>
          <div className="flex w-full h-full text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl">
            <div className="w-1/2">
              <div className="bg-black font-bold text-white py-3 flex items-center justify-center"><span>Bench</span></div>
              <Column droppableId="bench" data={state.bench} {...this.props} />
              <span className="bg-pink">
              <Button
                label="Add New Teammate"
                width="100%"
                backgroundColor="#22aa02"
                noRounded
                onClick={onClickAddNewPlayer}
              />
              </span>
            </div>
            <span className="px-2" />
            <div className="w-1/2">
              <div className="w-full">
                <div className="bg-black font-bold text-white py-3 flex items-center justify-center">
                  <span>{`Forwards (${state.posLF.length + state.posCF.length + state.posRF.length})`}</span>
                </div>
                <Column droppableId="posLF" data={state.posLF} {...this.props} />
                <Column droppableId="posCF" data={state.posCF} {...this.props} />
                <Column droppableId="posRF" data={state.posRF} {...this.props} />
              </div>
              <div className="w-full">
                <div className="bg-black font-bold text-white py-3 flex items-center justify-center">
                  <span>{`Midfielder (${state.posLM.length + state.posCM.length + state.posRM.length})`}</span>
                </div>
                <Column droppableId="posLM" data={state.posLM} {...this.props} />
                <Column droppableId="posCM" data={state.posCM} {...this.props} />
                <Column droppableId="posRM" data={state.posRM} {...this.props} />
              </div>
              <div className="w-full">
                <div className="bg-black font-bold text-white py-3 flex items-center justify-center">
                  <span>{`Defender (${state.posLB.length + state.posCB.length + state.posRB.length})`}</span>
                </div>
                <Column droppableId="posLB" data={state.posLB} {...this.props} />
                <Column droppableId="posCB" data={state.posCB} {...this.props} />
                <Column droppableId="posRB" data={state.posRB} {...this.props} />
              </div>
              <div className="w-full">
                <div className="bg-black font-bold text-white py-3 flex items-center justify-center">
                  <span>{`Keeper (${state.posGK.length})`}</span>
                </div>
                <Column droppableId="posGK" data={state.posGK} {...this.props} />
              </div>
            </div>
          </div>
        </DragDropContext>
    )
  }
}

List.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onClickAddNewPlayer: PropTypes.func.isRequired
}

export default List
